import { isLight, lighten } from "@wessberg/color";
import classnames from "classnames";
import { forwardRef } from "react";
import styled from "styled-components";
import { spinner } from "~/styles/mixins";
import { rem } from "~/styles/theme";

const Button = styled.button`
  font-family: "AIAIAI Extended";
  position: relative;
  display: inline-block;
  /* font-size: ${(p) => p.fontSize || rem("13px")}; */
  font-size: ${(p) => p.fontSize || rem("12px")};
  font-weight: bold;
  border: 0;
  appearance: none;
  border-radius: 0;
  background-color: ${(p) => p.backgroundColor || p.theme.colors.dark};
  color: ${(p) => p.color || p.theme.colors.light};
  padding: 0.6em 0.6em;
  text-align: center;
  width: ${rem("216px")};
  height: ${rem("56px")};
  cursor: pointer;
  opacity: ${(p) => (p.disabled || p.working ? "0.75" : "1")};
  outline: none;

  &:hover {
    /* background-color: ${(p) =>
      lighten(p.backgroundColor || p.theme.colors.dark, -5)}; */

    background-color: ${(p) =>
      isLight(p.backgroundColor || p.theme.colors.dark)
        ? lighten(p.backgroundColor || p.theme.colors.dark, -5)
        : lighten(p.backgroundColor || p.theme.colors.dark, 5)};
  }

  &.working {
    > span {
      display: none;
    }
    ${spinner};
  }
`;

function ButtonSquare(props, ref) {
  const {
    children,
    fontSize,
    id,
    disabled = false,
    working,
    onClick = () => {},
    backgroundColor,
    color,
    ...rest
  } = props;

  // console.log('DISABLED', disabled);

  return (
    <Button
      ref={ref}
      id={id}
      fontSize={fontSize}
      disabled={disabled}
      working={working}
      onClick={onClick}
      className={classnames({ working })}
      backgroundColor={backgroundColor}
      color={color}
      {...rest}
    >
      {!working && <span>{children}</span>}
    </Button>
  );
}

export default forwardRef(ButtonSquare);
