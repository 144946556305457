import fetch from "isomorphic-unfetch";
import getConfig from "next/config";
import { getProducts } from "~/external/prismic";
import {
  CREATE_MY_CART,
  GET_CATEGORY,
  GET_CHANNELS,
  GET_INVENTORY_ENTRIES,
  GET_INVENTORY_ENTRY,
  GET_MY_ACTIVE_CART,
  GET_MY_CART,
  GET_PRODUCTS_INFO,
  GET_PRODUCTS_INFO_EXTENDED,
  GET_PRODUCT_AVAILABILITY,
  GET_PRODUCT_TYPE,
  GET_SHIPPING_METHODS,
  GET_SHIPPING_METHODS_BY_CART,
  UPDATE_MY_CART,
} from "~/gql/commercetools";
import resolveProduct from "~/lib/ctp/resolveProduct";
import resolveTma2 from "~/lib/ctp/resolveTma2";
import { getTokenInfo } from "~/lib/ctpTokenInfo";

const { publicRuntimeConfig } = getConfig();

/**
 * Rest wrapper
 * for now we provide access_token in options...
 */
export async function rest(options) {
  // const access_token = getAccessTokenFromCookies();
  const authHeader = `Bearer ${options.access_token}`;

  if (options.url === undefined) {
    throw new Error("url not provided");
  }

  let { url } = options;

  // console.log('COMMERCETOOLS REST =>', url);

  // removed slash if first letter...
  if (url.charAt(0) === "/") {
    url = url.substr(1);
  }

  // add endpoint
  // url = `https://api.sphere.io/aiaiai-demo-97/${url}`;
  // url = `${process.env.COMMERCETOOLS_REST_ENDPOINT}/${url}`;
  url = `${publicRuntimeConfig.COMMERCETOOLS_REST_ENDPOINT}/${url}`;

  // do the fetch
  const result = await fetch(url, {
    method: options.method || "get",
    headers: {
      // 'Access-Control-Allow-Origin': '*',
      "Content-Type": "application/json",
      "Authorization": authHeader,
    },
    body:
      typeof options.body === "string" ? options.body : JSON.stringify(options.body),
  }); // .then(res => res.json());

  return result;
}

// a raw graphQL wrapper
export async function graphQl(body, access_token) {
  const authHeader = `Bearer ${access_token}`;

  // console.log('COMMERCETOOLS GRAPHQL =>', body);

  const result = await fetch(publicRuntimeConfig.COMMERCETOOLS_GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      // 'Access-Control-Allow-Origin': '*',
      "Content-Type": "application/json",
      "Authorization": authHeader,
    },
    body: JSON.stringify(body),
  });

  return result;
}

// --------------------------
//  CART
// --------------------------

/**
 * Get's a cart that already exists
 * @param  {ApolloClient} client    the apollo client
 * @param  {String} cartId          cart id
 * @return {Object}                 the cart
 */
export async function getMyCart(ctx, cartId, countryData) {
  // USE RAW GRAPHQL CALL TO GET OUT OF CACHE!

  //
  // console.log('cartTest', cartTest);

  // console.log(GET_MY_CART.loc.source.body);

  // console.log('ctx', ctx);
  try {
    /*
    // THIS WILL ACTUALLY FETCH THE RIGHT CART, BUT IT CANNOT BE USED SINCE REST OF THE
    // CACHE IS NOT UP TO DATE :(
    const result = await graphQl(
      {
        query: GET_MY_CART.loc.source.body,
        variables: JSON.stringify({ id: cartId })
      },
      ctx.tokenInfo.access_token
    ).then(res => res.json());
    */

    /* console.log(
      'TOKEN EXPIRED?',
      Date.now(),
      ctx.tokenInfo.expires_in,
      ctx.tokenInfo.expires_at
    ); */

    const result = await ctx.client.query({
      query: GET_MY_CART,
      variables: {
        id: cartId,
      },
      fetchPolicy: "no-cache",
    });

    const { cart } = result.data.me;

    if (cart === null) {
      return null;
    }

    // const s = Date.now();
    // make cartItems...
    cart.cartItems = await getCartItems(ctx, cart, countryData); // <-- ERRPR!
    // console.log(`createMyCart: Got cart items in ${Date.now() - s}ms`);

    return cart;
  } catch (err) {
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    return null;
  }
}

/**
 * Get an active cart based on user's token
 * @param ctx
 * !IMPORTANT: this one has an issue.
 * clear cookies, then refresh. it cannot get the active cart. refresh again, works...
 * problem with the apolloClient?
 */
export async function getMyActiveCart(ctx) {
  try {
    // we need to find out what token is used here???
    const result = await ctx.client.query({
      query: GET_MY_ACTIVE_CART,
      fetchPolicy: "no-cache",
    });
    // console.log('getMyActiveCart', result);
    const cart = result.data.me.activeCart;
    // console.log('RESULT', result.data.me);
    return cart;
  } catch (err) {
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    return null;
  }
}

export async function getActiveMyCartRest(ctx, countryData) {
  // problem with this is that locales are not resolved :/

  const tokenInfo = await getTokenInfo(ctx);
  const { access_token } = tokenInfo;
  const result = await rest({
    url: `/me/carts`,
    method: "get",
    access_token,
  });

  if (result.status !== 200) {
    const text = await result.text();
    throw new Error(text);
  }
  const json = await result.json();

  if (json.results.length === 0) return null;

  let carts = json.results;

  // only "Active" carts
  carts = carts.filter((c) => c.cartState === "Active");

  // console.log('ACTIVE CARTS:', carts);

  // find the newest one!
  carts
    .sort((a, b) => {
      const d1 = new Date(a.createdAt).getTime();
      const d2 = new Date(b.createdAt).getTime();
      // latest last
      return d1 > d2 ? 1 : -1;
    })
    .reverse();

  // console.log('ACTIVE CARTS - SORTED:', carts);

  // console.log('🛒🛒🛒');

  // // DEBUG: show the carts...
  // console.log(`Found ${carts.length} carts!`);
  // carts.forEach(c => {
  //   console.log(`🛒 ${c.id} (${c.country}) ${c.createdAt}`);
  // });

  // check country?

  // take the first one!
  // const cart = carts.shift();

  // find the first cart with same country as countryData...
  const cartIndex = carts.findIndex((c) => c.country === countryData.code);
  if (cartIndex === -1) {
    console.log("NO ACTIVE CART WITH SAME COUNTRY CODE:", countryData.code);
    return null;
  }
  // take it
  const cart = carts[cartIndex];

  // console.log('ACTIVE CART FOR COUNTRY CODE:', countryData.code, cart);

  // delete all the carts, that was not the selected one!
  await Promise.all(
    carts.map(async (c, i) => {
      if (i === cartIndex) return Promise.resolve();
      // console.log(`delete cart ${c.id} (${c.country}) v${c.version}`);
      console.warn("DELETING ACTIVE CART:", c);
      return rest({
        url: `/me/carts/${c.id}?version=${c.version}`,
        method: "delete",
        access_token,
      });
      // const text = await deleteCart.text();
      // console.log(deleteCart.status, text);
      // return;
    })
  );
  // console.log('🛒🛒🛒', cart.id);

  return cart;
}

/**
 * Create a new cart from country and currency
 * @param  {ApolloClient} client    the apollo client
 * @param  {String} country         eg. "DK"
 * @param  {String} currency        eg. "DKK"
 * @return {Object}                 The new cart
 */
export async function createMyCart(ctx, countryData) {
  try {
    // cretae a draft for the cart
    const draft = {
      currency: countryData.currency,
      country: countryData.code,
      shippingAddress: {
        country: countryData.code, // <-- this sets tax!
      },
      // inventoryMode: 'None' // "ReserveOnOrder", "TrackOnly", "None"
      inventoryMode: "TrackOnly", // "ReserveOnOrder", "TrackOnly", "None"
    };

    // if we ahve a store in settings, add it to the cart!
    /*
    if (publicRuntimeConfig.COMMERCETOOLS_STORE) {
      console.log('🔑 ', publicRuntimeConfig.COMMERCETOOLS_STORE);
      // draft.store = {
      //   typeId: 'store',
      //   key: publicRuntimeConfig.COMMERCETOOLS_STORE
      // };
    }
    */

    const storeKey = publicRuntimeConfig.COMMERCETOOLS_STORE;

    const result = await ctx.client.mutate({
      // mutation: CREATE_CART,
      mutation: CREATE_MY_CART,
      variables: { draft, storeKey },
      fetchPolicy: "no-cache",
    });

    // console.log('res', result);
    const cart = result.data.createMyCart;

    // console.log('createMyCart', cart);

    // const s = Date.now();
    // make cartItems...
    cart.cartItems = await getCartItems(ctx, cart, countryData);
    // console.log(`createMyCart: Got cart items in ${Date.now() - s}ms`);

    // console.log('myCart', cart)

    return cart;
  } catch (err) {
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    throw err;
    // return null;
  }
}

/**
 * Update a MyCart
 * @param ctx
 * @param cart
 * @param actions
 * @param countryData
 */
export async function updateMyCart(ctx, cart, actions, countryData) {
  /* console.log(
    'TOKEN EXPIRED?',
    Date.now(),
    ctx.tokenInfo.expires_in,
    ctx.tokenInfo.expires_at
  ); */
  try {
    const result = await ctx.client.mutate({
      mutation: UPDATE_MY_CART,
      variables: {
        actions,
        id: cart.id,
        version: cart.version,
      },
      fetchPolicy: "no-cache",
    });
    // console.log(result);
    // return result.data.updateMyCart;
    const newCart = result.data.updateMyCart;
    // console.log('my cart updated', JSON.stringify(newCart, null, 2));
    // console.log(newCart);

    // const s = Date.now();
    // make cartItems...
    newCart.cartItems = await getCartItems(ctx, newCart, countryData);
    // console.log(`updateMyCart: Got cart items in ${Date.now() - s}ms`);

    return newCart;
  } catch (err) {
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    throw err;
  }
}

export async function cloneMyCart(ctx, cart, countryData) {
  /**
   * countryData can be different than the original cart
   *
   * When cloning a cart, we want to keep:
   * - items
   * - billingAddress
   * - shippingAddress
   * - ??
   *
   * We add "withErrors" array to the cart, if any errors occur
   * If the new country does not use state, make sure the state is removed...
   */
  try {
    const withErrors = [];

    // create new cart
    let newCart = await createMyCart(ctx, countryData);
    console.log("newCart", newCart);
    // new cart will only have shippingAddress = { country: 'XX' }

    // get cart items (with the NEW countryData)
    const cartItems = await getCartItems(ctx, cart, countryData);
    // console.log('cartItems', cartItems);

    // add products
    for (const cartItem of cartItems) {
      // const variant = cartItem.variants[cartItem.masterVariantIndex];
      const { variant } = cartItem;
      try {
        // add to cart, if avaiable
        if (variant.availability.availableQuantity > 0) {
          // NOTE: we have to do await's inside the for loop, as newCart has to be up to date always...
          newCart = await addVariantToCart(
            ctx,
            newCart,
            variant,
            countryData,
            cartItem.quantity
          );
        } else {
          // add an error if not avaiable
          withErrors.push({
            message: `${variant.name} unavailable in ${countryData.name}. Removed from cart`,
          });
        }
        // console.log('added variant to cart', newCart);
      } catch (err) {
        // if we get an error, add it to the error messages....
        console.log("error adding product", err.toString());
        withErrors.push({
          message: `${variant.name} unavailable in ${countryData.name}. Removed from cart`,
        });
        // withErrors.push({ message: err.toString() });
      }
    }

    newCart.withErrors = withErrors;
    // console.log('withErrors', withErrors);

    return newCart;
  } catch (err) {
    return null;
  }
}

// returns expand customLineItems and items
export async function getCartItems(ctx, cart, countryData) {
  // return [];

  try {
    // helper to check if customLineItem has a BundleProduct...
    function getBundleProductId(customLineItem) {
      if (cart.type === "Order") {
        if (!customLineItem.custom || !customLineItem.custom.fields.BundleProduct) {
          return null;
        }
        return customLineItem.custom.fields.BundleProduct.id;
      }
      if (customLineItem.custom && customLineItem.custom.customFieldsRaw) {
        const bundleProduct = customLineItem.custom.customFieldsRaw.find(
          (field) => field.name == "BundleProduct"
        );
        if (bundleProduct) {
          return bundleProduct.value.id;
        }
      }
      return null;
    }

    // helper to check if lineItem is in a bundle
    function getBundleId(lineItem) {
      if (cart.type === "Order") {
        if (!lineItem.custom || !lineItem.custom.fields.BundleId) {
          return null;
        }
        return cart.customLineItems.find(
          (bundle) => bundle.custom.fields.BundleId === lineItem.custom.fields.BundleId
        );
      }
      if (lineItem.custom && lineItem.custom.customFieldsRaw) {
        const bundleId = lineItem.custom.customFieldsRaw.find(
          (field) => field.name == "BundleId"
        );
        if (bundleId) {
          const inBundle = cart.customLineItems.find(
            (b) =>
              b.custom &&
              b.custom.customFieldsRaw.find((field) => field.name == "BundleId")
                .value === bundleId.value
          );
          if (inBundle) return bundleId.value;
        }
      }
      return null;
    }

    // get bundles
    // filter out all customLineItems that does not have a BundleProductID (they are not bundles)
    let bundles = cart.customLineItems.filter((customLineItem) =>
      getBundleProductId(customLineItem)
    );
    // go through all the newlty filtered bundles, and add their BundleProductIDs
    // then we add productId to the bundles
    bundles = bundles.map((bundle) => ({
      ...bundle,
      productId: getBundleProductId(bundle),
      // add prices - we will calc prices from parts further down
      // totalPrice: { centAmount: 0, fractionDigits: 2, currencyCode: 'DKK' },
      // price: { centAmount: 0, fractionDigits: 2, currencyCode: 'DKK' }
    }));
    // console.log('✅ bundles', bundles);

    const lineItems = [];
    cart.lineItems.forEach((lineItem) => {
      const bundleId = getBundleId(lineItem);
      delete lineItem.taxedPrice; // remove taxedPrice for consistency with bundles...
      // delete lineItem.variant;

      // variant.

      // console.log('bundleId', bundleId);

      if (bundleId) {
        // if we have a bundle, we need to add the price to the bundle's price
        const bundle = bundles.find(
          // b.customFields.BundleId.value
          (b) => getBundleId(b) === bundleId
        );
        // console.log('bundle?', bundle);

        if (bundle) {
          const { price, totalPrice } = lineItem;
          // price.value = { } price.discounted = ??
          // console.log(price.value.centAmount, totalPrice.centAmount);

          bundle.price = {
            value: {
              centAmount:
                (bundle.price ? bundle.price.value.centAmount : 0) +
                price.value.centAmount,
              fractionDigits: price.value.fractionDigits,
              currencyCode: price.value.currencyCode,
            },
            discounted: null, // we need to fix this?
          };

          bundle.totalPrice = {
            centAmount: bundle.totalPrice.centAmount + totalPrice.centAmount,
            fractionDigits: totalPrice.fractionDigits,
            currencyCode: totalPrice.currencyCode,
          };

          // console.log(bundle.price, bundle.totalPrice);
        }
      } else {
        // else it's just a normal line item
        lineItems.push(lineItem);
      }
    });

    // console.log(bundles, lineItems);

    // combine into one array
    let items = [...bundles, ...lineItems];

    // console.log(items);

    // console.log(items);

    /*
    items = await Promise.all(
      items.map(async item => {
        // check if we have a bundle
        // let bundleType;
        // if (item.customFields) {
        //   if (item.customFields.BundleType) {
        //     bundleType = item.customFields.BundleType.value;
        //   }
        // }

        // fetch the whole product, so we know we have all data?
        const itemProduct = await getProductById(
          ctx,
          item.productId,
          countryData
        );

        let itemProductResolved;

        if (itemProduct.key === 'tma-2-configuration') {
          // TMA-2 configuration
          const partMatches = /^TMA-2 (S[0-9]{2})-(H[0-9]{2})-(E[0-9]{2})-(C[0-9]{2})$/g.exec(
            item.name
          );

          // simulates data from prismic...
          const productEntries = [
            { data: { key: partMatches[1].toLowerCase() } },
            { data: { key: partMatches[2].toLowerCase() } },
            { data: { key: partMatches[3].toLowerCase() } },
            { data: { key: partMatches[4].toLowerCase() } }
          ];

          console.log('productEntries', productEntries);

          // fetch skus?
          // use prismic function...
          const products = await getProducts(productEntries, ctx, countryData);

          // console.log('products', products);

          itemProductResolved = await resolveTma2(
            ctx,
            {
              name: item.name,
              key: item.slug.split('_')[0],
              productType: { key: 'tma-2-configuration' }
            },
            countryData,
            products
          );
        } else {
          // TMA-2 bundle or normal product
          itemProductResolved = await resolveProduct(
            ctx,
            itemProduct,
            countryData
          );
        }

        console.log('itemProductResolved NAME', itemProductResolved.name);

        console.log(
          'itemProductResolved PRICE',
          itemProductResolved.variants[0].price
        );
        return itemProductResolved;
      })
    );
    */

    // expand variant on all items

    items = await Promise.all(
      items.map(async (item) => {
        // configuration

        if (
          item.custom &&
          item.custom.customFieldsRaw &&
          item.custom.customFieldsRaw.some(
            (field) => field.name == "BundleType" && field.value === "configuration"
          )
        ) {
          // get get part uris from serial??
          // TODO: Improve MK (MARK) matching
          const regex =
            /^TMA-2 (S[0-9]{2})(MKII|MKIII)?-(H[0-9]{2})(MKII|MKIII)?(-?X[0-9]{2})?-(E[0-9]{2})(MKII|MKIII)?-?(C[0-9]{2})?(MKII|MKIII)?$/g;
          const partMatches = new RegExp(regex).exec(item.name);

          let [
            realSerial,
            speakerUnitsKey,
            speakerUnitsMarkKey = null,
            headbandKey,
            headbandMarkKey = null,
            headbandBundlePart = null,
            earpadsKey,
            earpadsMarkKey = null,
            cableKey = null,
            cableMarkKey = null,
          ] = partMatches;

          // If MKII or MKIII exists for part, append it
          if (speakerUnitsMarkKey) {
            speakerUnitsKey += speakerUnitsMarkKey;
          }
          if (headbandMarkKey) {
            headbandKey += headbandMarkKey;
          }
          if (headbandBundlePart) {
            if (!headbandBundlePart.startsWith("-")) {
              headbandKey += "-";
            }
            headbandKey += headbandBundlePart;
          }
          if (earpadsMarkKey) {
            earpadsKey += earpadsMarkKey;
          }
          if (cableKey && cableMarkKey) {
            cableKey += cableMarkKey;
          }

          // simulates data from prismic...
          const productEntries = [
            { data: { key: speakerUnitsKey.toLowerCase() } },
            { data: { key: headbandKey.toLowerCase() } },
            { data: { key: earpadsKey.toLowerCase() } },
            { data: { key: cableKey ? cableKey.toLowerCase() : null } },
          ];

          // console.log('productEntries', productEntries);

          // fetch skus?
          // use prismic function...
          const products = await getProducts(productEntries, ctx, countryData);

          // console.log('products', products);

          const configuration = await resolveTma2(
            ctx,
            {
              name: item.name,
              key: item.slug.split("_")[0],
              productType: { key: "tma-2-configuration" },
            },
            countryData,
            products
          );

          // const configuration = await resolveConfiguration(
          //   ctx,
          //   { name: item.name, key: item.slug.split('_')[0] },
          //   products,
          //   countryData
          // );

          // console.log('configuration', configuration);

          return {
            ...item,
            ...configuration,
          };
        }

        // this fucks up??

        // console.log('item', item.id);

        // preset or product
        let pr = await getProductById(ctx, item.productId, countryData);
        if (pr) {
          pr = await resolveProduct(ctx, pr, countryData);
          return {
            ...item,
            ...pr,
            lineItemId: item.id,
          };
        }

        // if (pr) item.variant = pr.masterData.current.masterVariant;
        return {
          ...item,
          lineItemId: item.id,
        };
      })
    );

    // console.log('items', items);

    // make sure the item has the seleted variant!!
    items = items.map((item) => {
      // if the items does not have a variant
      if (!item.variant) {
        // the first one.. only applicable for bundles anyways
        item.variant = item.variants[0];
      } else {
        item.variant = item.variants.find((v) => v.sku === item.variant.sku);
      }

      // if the product can have different variants, it is adviced to show the variant key in the checkout...
      item.hasMultipleVariants = item.variants.length > 1;

      // delete the possibility that there is more than one variant.. never the case for the cart
      delete item.variants;
      delete item.masterVariantIndex;

      return item;
    });

    // go through each bundle and calc price from all parts...?

    // console.log('items', items);

    return items;
  } catch (err) {
    // TypeError: Cannot set property 'cartItems' of null
    console.log("getCartItems"); // , err.toString());
    console.log(err);
    return [];
  }
}

// just so it makes sense :)
export const getOrderItems = getCartItems;

/**
 * Get discount price from a cart or order (obj)
 * Note this only works on relative and absolute prices and assume that there is only ONE cartDiscount
 */
export function getDiscountPrice(obj) {
  // console.log('getDiscountPrice', obj);
  const discountPrice = obj.discountCodes.reduce(
    (price, discountCode) => {
      discountCode = discountCode.discountCode;
      let centAmount = 0;
      const { cartDiscounts } = discountCode.obj; // Arr
      cartDiscounts.forEach((cartDiscount) => {
        const { type } = cartDiscount.obj.value; // 'relative' or 'fixed
        switch (type) {
          case "relative":
            // percentages
            const percent = cartDiscount.obj.value.permyriad / 10000;
            const orderOriginalCentAmount = obj.totalPrice.centAmount / (1 - percent);
            const discountCentAmount = orderOriginalCentAmount * percent;
            centAmount += discountCentAmount;
            break;
          case "absolute":
            // fixed price
            const absoluteDiscount = cartDiscount.obj.value.money.find(
              (money) => money.currencyCode === obj.totalPrice.currencyCode
            );
            centAmount += absoluteDiscount.centAmount;
            break;
        }
      });
      price.centAmount += centAmount;
      return price;
    },
    {
      centAmount: 0,
      currencyCode: obj.totalPrice.currencyCode,
      centPrecision: 2,
    }
  );

  return discountPrice;
}

// sets the custom attribute "addBundle" on the cart and returns the new cart
export async function addVariantToCart(ctx, cart, variant, countryData, quantity = 1) {
  const { client } = ctx;

  // console.log('variant', variant);

  /* console.log(
    'TOKEN EXPIRED?',
    Date.now(),
    ctx.tokenInfo.expires_in,
    ctx.tokenInfo.expires_at
  ); */

  if (variant.bundle === true) {
    /**
     * Bundle
     */

    /*
      const domain = 'http://localhost:3000';

      await fetch(`${domain}/api/carts/${cart.id}/add-bundle`, {
        method: 'post',
        body: JSON.stringify(variant)
      })

      return;
      */

    // we pass the bundledproducts as a json object (to be parsed by the extension)
    // but strings needs to be wrapped in qoutes ("") in CTP, so we need to escape the JSOn qoutes...
    const BundleProducts = variant.bundledProductsSkus.map((sku) => {
      const pr = {
        sku,
        quantity: 1,
      };
      // add the channel, if it exists in countryData.
      // important for inventory mode track only...
      if (typeof countryData.channel !== "undefined") {
        pr.channelId = countryData.channel;
      }

      return pr;
    });

    // console.log('BundleProducts', BundleProducts);

    const BundleProductsString = JSON.stringify(BundleProducts).replace(/"/g, '\\"');

    const BundleProductSting = JSON.stringify({
      typeId: "product",
      id: variant.productId,
    });

    const BundleName = {
      "en-us": variant.name,
    };
    const BundleNameString = JSON.stringify(BundleName).replace(/"/g, '\\"');

    const fields = [
      {
        name: "BundleType", // REQUIRED
        value: `"${variant.bundleType || "configuration"}"`, // STRING: "configuration" or "preset"
      },
      {
        name: "BundleSlug", // REQUIRED
        value: `"${variant.key}"`, // the newly created id for the customLineItem will be appended to the slug "tma-2-dj_XXX"
      },
      {
        name: "BundleName",
        // value: `"${variant.name}"`
        value: `"${BundleNameString}"`,
      },
      {
        name: "BundleProducts",
        value: `"${BundleProductsString}"`, // JSON string of products...
      },
      {
        name: "BundleProduct",
        value: `${BundleProductSting}`,
      },
    ];

    // console.log('FIELDS', fields);

    /*
        "{\"id\", \"b911b62d-353a-4388-93ee-8d488d9af962\", \"typeId\", \"product\"}"
      */

    // console.log(fields);

    // run the actions against the current cart
    // const result = await updateCart(client, cart, actions);
    const result = await updateMyCart(
      ctx,
      cart,
      [
        {
          setCustomType: {
            typeKey: "AddBundle",
            fields,
          },
        },
      ],
      countryData
    );

    // console.log('added bundle to cart!', result);

    return result;
  }
  /**
   * Product
   */

  const result = await updateMyCart(
    ctx,
    cart,
    [
      {
        addLineItem: {
          sku: variant.sku,
          quantity,
          distributionChannel: {
            typeId: "channel",
            id: countryData.channel,
          },
          supplyChannel: {
            typeId: "channel",
            id: countryData.channel,
          },
        },
      },
    ],
    countryData
  );

  // return the new cart!
  return result;

  // return the new cart!
}

/**
 * Add multiple variants (items/products) to the cart in one request.
 *
 * - TODO: Add quantity to the list of variants.
 *
 * @param {*} ctx Commercetools context
 * @param {*} cart Cart object
 * @param {*} variants List of variants (items/products)
 * @param {*} countryData Data of the country
 * @param {*} quantity Quantity
 * @returns
 */
export async function addVariantsToCart(
  ctx,
  cart,
  variants,
  countryData,
  quantity = 1
) {
  const result = await updateMyCart(
    ctx,
    cart,
    variants.map((variant) => {
      if (variant.bundle === true) {
        const partsBundleContainerSkus = variant.bundledProducts
          .filter((mv) => mv.typeKey === "parts-bundle")
          .map((bp) => bp.variants[bp.masterVariantIndex])
          .map((mv) => mv.sku);

        console.log("partsBundleContainerSkus", partsBundleContainerSkus);

        let skus = variant.bundledProductsSkus.filter(
          (sku) => !partsBundleContainerSkus.includes(sku)
        );

        const partsBundles = variant.bundle
          ? [].concat.apply(
              [],
              variant.bundledProducts
                .map((bp) => bp.variants[bp.masterVariantIndex].parts)
                .filter((parts) => parts.length > 0)
            )
          : [];

        console.log(partsBundles);

        const partsBundleSkus = partsBundles.map(
          (part) => part.variants[part.masterVariantIndex].sku
        );

        console.log(partsBundleSkus);

        skus = skus.concat(partsBundleSkus);

        // we pass the bundledproducts as a json object (to be parsed by the extension)
        // but strings needs to be wrapped in qoutes ("") in CTP, so we need to escape the JSOn qoutes...
        // console.log('variant.bundledProductsSkus', variant.bundledProductsSkus);
        const BundleProducts = skus.map((sku) => {
          const pr = {
            sku,
            quantity: 1,
          };
          // add the channel, if it exists in countryData.
          // important for inventory mode track only...
          if (typeof countryData.channel !== "undefined") {
            pr.channelId = countryData.channel;
          }

          return pr;
        });

        // console.log('BundleProducts', BundleProducts);

        const BundleProductsString = JSON.stringify(BundleProducts).replace(
          /"/g,
          '\\"'
        );

        console.log("BundleProductsString", BundleProductsString);

        const BundleProductSting = JSON.stringify({
          typeId: "product",
          id: variant.productId,
        });

        const BundleName = {
          "en-us": variant.name,
        };
        const BundleNameString = JSON.stringify(BundleName).replace(/"/g, '\\"');

        const fields = [
          {
            name: "BundleType", // REQUIRED
            value: `"${variant.bundleType || "configuration"}"`, // STRING: "configuration" or "preset"
          },
          {
            name: "BundleSlug", // REQUIRED
            value: `"${variant.key}"`, // the newly created id for the customLineItem will be appended to the slug "tma-2-dj_XXX"
          },
          {
            name: "BundleName",
            // value: `"${variant.name}"`
            value: `"${BundleNameString}"`,
          },
          {
            name: "BundleProducts",
            value: `"${BundleProductsString}"`, // JSON string of products...
          },
          {
            name: "BundleProduct",
            value: `${BundleProductSting}`,
          },
        ];

        console.log("FIELDS", fields);

        return {
          setCustomType: {
            typeKey: "AddBundle",
            fields,
          },
        };
      }
      return {
        addLineItem: {
          sku: variant.sku,
          quantity,
          distributionChannel: {
            typeId: "channel",
            id: countryData.channel,
          },
          supplyChannel: {
            typeId: "channel",
            id: countryData.channel,
          },
        },
      };
    }),
    countryData
  );

  // return the new cart!
  return result;
}

/**
 * removes a bundle and all it's parts from a cart
 * @param client
 * @param cart
 * @param bundleId
 */
export async function removeBundleFromCart(ctx, cart, bundleId, countryData) {
  const fields = [
    {
      name: "BundleId",
      value: `"${bundleId}"`, // STRING: "configuration" or "preset"
    },
  ];

  // run the actions against the current cart
  // const result = await updateCart(client, cart, actions);
  const result = await updateMyCart(
    ctx,
    cart,
    [
      {
        setCustomType: {
          typeKey: "RemoveBundle",
          fields,
        },
      },
    ],
    countryData
  );

  return result;
}

/**
 * // DEPRIACTED USE getCartItems
 * Truncates bundles and returns cart items, as we want them in the cart
 * this function also fetches images attached to the bundled products
 * which is needed to show thumbs...
 * @param ctx
 * @param cart
 * @param countryData
 */
export async function getCartItemsAsync(ctx, cart, countryData) {
  console.log("⚠️ getCartItemsAsync DEPRAICTED");
  return {};
  try {
    // dont use map, since some items should not be added...
    const bundles = [];
    const items = [];

    // 1) add custom lines
    cart.customLineItems.forEach((customLineItem) => {
      // make place to add bundles lineItems
      customLineItem.items = [];
      bundles.push(customLineItem);
    });

    // 2) add line items, but if "inBundle" add to bundle?
    cart.lineItems.forEach((lineItem) => {
      // here we assume that the ONLY custom field is lineItem.customFields.CustomLineId.value...
      // add it to items
      if (!lineItem.customFields) {
        items.push(lineItem);
      } else if (!lineItem.customFields.BundleId) {
        items.push(lineItem);
      } else {
        // else add it to the bundle!
        // find an item that matches the BundleId
        const bundleItem = bundles.find((item) => {
          if (!item.customFields) return false;
          if (!item.customFields.BundleId) return false;
          return (
            item.customFields.BundleId.value === lineItem.customFields.BundleId.value
          );
        });
        // make sure the item is there...
        if (bundleItem) {
          // add it to the bundle, instead of items.
          bundleItem.items.push(lineItem);
        }
      }
    });

    // Calculate the price for each bundle...
    bundles.forEach((bundle) => {
      // reduce the price from all the prices
      bundle.totalPrice.centAmount = bundle.items.reduce(
        (centAmount, item) => centAmount + item.totalPrice.centAmount,
        0
      );
      const firstItemPrice = bundle.items[0].price.value;
      bundle.price = {
        value: {
          currencyCode: firstItemPrice.currencyCode,
          fractionDigits: firstItemPrice.fractionDigits,
        },
      };
      bundle.price.value.centAmount = bundle.items.reduce(
        (centAmount, item) => centAmount + item.price.value.centAmount,
        0
      );
    });

    // fetch BundleProduct for each bundle?
    const fetchBundleProducts = [];
    for (const bundle of bundles) {
      fetchBundleProducts.push(
        new Promise((resolve) => {
          // get the BundleProduct by its ID
          getProductsByIds(
            ctx,
            [bundle.customFields.BundleProduct.id],
            countryData
          ).then((products) => {
            if (products.length === 0) resolve();
            // take the first product
            const pr = products[0];
            // find the bundle in question
            const bundleMatch = bundles.find((b) => b.id === bundle.id);
            // if found, add the variant!
            if (bundleMatch) {
              bundleMatch.variant = pr.masterData.current.masterVariant;
            }
            resolve();
          });
        })
      );
    }

    await Promise.all(fetchBundleProducts);

    const bundlesAndItems = [...bundles, ...items];
    // console.log(bundlesAndItems);

    return bundlesAndItems;
  } catch (err) {
    throw err;
  }
}

// --------------------------
//  PRODUCTS AND CATEGORIES
// --------------------------

/**
 * Get's a product's category by the ID
 * @param client
 * @param key
 * @param locale
 */
export async function getProductCategoryByKey(ctx, key, locale = "en-US") {
  try {
    const result = await ctx.client.query({
      query: GET_CATEGORY,
      variables: {
        where: `key="${key}"`,
        locale,
      },
    });

    if (result.data.categories.results.length === 0) return null;

    return result.data.categories.results[0];
  } catch (err) {
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    throw err;
  }
}

export async function getProductCategoriesByKeys(ctx, keys, locale = "en-US") {
  try {
    const result = await ctx.client.query({
      query: GET_CATEGORY,
      variables: {
        where: `key in (${keys.map((key) => `"${key}"`).join(", ")})`,
        locale,
      },
    });
    return result.data.categories.results;
  } catch (err) {
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    throw err;
  }
}

export async function getProductTypeIdByKey(ctx, key) {
  // support commercetoolsContext instead of client

  try {
    const result = await ctx.client.query({
      query: GET_PRODUCT_TYPE,
      variables: {
        where: `key="${key}"`,
      },
    });

    const productTypes = result.data.productTypes.results;

    // check if we have only product type
    if (productTypes.length > 1) {
      throw new Error(`More than one product type with key "${key}"`);
    }

    // return the first one
    return productTypes[0].id;
  } catch (err) {
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    throw err;
  }
}

/**
 * get's products based on ids...
 * @param client
 * @param ids
 * @param countryData
 */
export async function getProductsByIds(ctx, ids = [], countryData) {
  if (ids.length === 0) return [];

  // create a where clause to fetch all the ids
  const where = `id in (${ids.map((id) => `"${id}"`).join(", ")})`;
  try {
    const result = await ctx.client.query({
      query: GET_PRODUCTS_INFO,
      variables: {
        where,
        currency: countryData.currency,
        channel: countryData.channel,
      },
    });
    // extract the results and return them
    const products = result.data.products.results;
    return products;
  } catch (err) {
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    throw err;
  }
}

// get a single one..
export async function getProductById(ctx, id, countryData) {
  const products = await getProductsByIds(ctx, [id], countryData);
  if (products.length == 0) {
    return null;
  }
  return products[0];
}

/**
 * Get products from a certain category (ID)
 * @param ctx
 * @param id
 * @param countryData
 */
export async function getProductsByCategoryId(ctx, id, countryData) {
  try {
    const result = await ctx.client.query({
      query: GET_PRODUCTS_INFO,
      variables: {
        where: `masterData(current(categories(id="${id}")))`,
        currency: countryData.currency,
        channel: countryData.channel,
      },
    });

    // extract the results and return them
    const products = result.data.products.results;
    return products;
  } catch (err) {
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    throw err;
  }
}

export async function getProductsByCategoryIds(ctx, ids, countryData) {
  const whereProduct = `masterData(current(categories(id in (${ids
    .map((id) => `"${id}"`)
    .join(", ")}))))`;
  try {
    const result = await ctx.client.query({
      query: GET_PRODUCTS_INFO_EXTENDED,
      variables: {
        whereProduct,
        // whereInventory: whereInventory,
        currency: countryData.currency,
        channel: countryData.channel,
      },
    });

    // extract the results and return them
    const products = result.data.products.results;
    return products;
  } catch (err) {
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    throw err;
  }
}

/**
 * Get products based on SKU
 * @param ctx
 * @param skus
 * @param countryData
 */
export async function getProductsBySkus(ctx, skus, countryData) {
  try {
    const result = await ctx.client.query({
      query: GET_PRODUCTS_INFO,
      variables: {
        skus,
        currency: countryData.currency,
        channel: countryData.channel,
      },
    });

    // extract the results and return them
    const products = result.data.products.results;
    return products;
  } catch (err) {
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    throw err;
  }
}

// get a single one..
export async function getProductBySku(ctx, sku, countryData) {
  const products = await getProductsBySkus(ctx, [sku], countryData);
  if (products.length == 0) {
    return null;
  }
  return products[0];
}

/**
 * Get produxts based on their key
 * @param ctx
 * @param keys
 * @param countryData
 */
export async function getProductsByKeys(ctx, keys, countryData) {
  // console.log(keys.map(k => `key="${k}"`).join(' or '));

  try {
    const result = await ctx.client.query({
      query: GET_PRODUCTS_INFO,
      variables: {
        where: `key in (${keys.map((key) => `"${key}"`).join(", ")})`,
        currency: countryData.currency,
        channel: countryData.channel,
      },
    });

    // console.log('products??', result.data);

    // extract the results and return them
    const products = result.data.products.results;
    return products;
  } catch (err) {
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    throw err;
  }
}
// a wrapper to fetch a single product...
export async function getProductByKey(ctx, key, countryData) {
  const products = await getProductsByKeys(ctx, [key], countryData);
  if (products.length == 0) {
    return null;
  }
  return products[0];
}

/**
 * Will return the smallest availalbility of all skus!
 * @param ctx
 * @param skus
 * @param countryData
 */
export async function getProductsAvailabilityBySkus(ctx, skus, countryData) {
  try {
    // console.log('countryData', countryData);
    // console.log(skus);

    const result = await ctx.client.query({
      query: GET_PRODUCT_AVAILABILITY,
      variables: {
        skus,
        channels: [countryData.channel],
      },
      fetchPolicy: "network-only", // 'cache-and-network'
    });

    // console.log(
    //   'RESULTS',
    //   result.data.products.results[0].masterData.current.variants[0]
    //     .availability.channels.results
    // );

    const products = result.data.products.results;

    console.log("products", products);

    const variantAvailability = [];

    products.forEach((pr) => {
      const variants = pr.masterData.current.allVariants;
      variants.forEach((v) => {
        const { availableQuantity = 0 } =
          v.availability.channels.results[0].availability;
        variantAvailability.push(availableQuantity);
      });
    });

    const availability = Math.min(...variantAvailability);

    /*

    // get the lowest number of availableQuantity from all the resutls
    const availability = result.data.products.results.reduce((n, result) => {
      const {
        availableQuantity = 0
      } = result.masterData.current.masterVariant.availability.channels.results[0].availability;
      return Math.min(n, availableQuantity);
    }, 99999);

    */

    // console.log();

    return availability;

    // extract the results and return them
    // const availability =
    //   result.data.products.results[0].masterData.current.masterVariant
    //     .availability.channels.results[0].availability.availableQuantity;
    // return availability;
  } catch (err) {
    console.log("getProductsAvailabilityBySkus", err.toString());
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    return 0;
  }
}

export async function getProductAvailability(ctx, clause = {}, countryData) {
  try {
    const result = await ctx.client.query({
      query: GET_PRODUCT_AVAILABILITY,
      variables: {
        ...clause,
        channel: countryData.channel,
      },
      fetchPolicy: "network-only", // 'cache-and-network'
    });

    // console.log(result);

    // extract the results and return them
    const availability =
      result.data.products.results[0].masterData.current.masterVariant.availability
        .channels.results[0].availability.availableQuantity;
    return availability;
  } catch (err) {
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    throw err;
  }
}

export const getProductAvailabilityByKey = async (ctx, key, countryData) =>
  getProductAvailability(
    ctx,
    {
      where: `key="${key}"`,
    },
    countryData
  );

export const getProductAvailabilityBySku = async (ctx, sku, countryData) =>
  getProductAvailability(
    ctx,
    {
      skus: [sku],
    },
    countryData
  );

export const getProductAvailabilityById = async (ctx, id, countryData) =>
  getProductAvailability(
    ctx,
    {
      where: `id="${id}"`,
    },
    countryData
  );

// --------------------------
//  SHIPPING RATES
// --------------------------

/**
 * Get shipping rates based on zone
 * @param ctx
 * @param zoneName
 */
export async function getShippingRatesByZone(ctx, zoneName) {
  try {
    const result = await ctx.client.query({
      query: GET_SHIPPING_METHODS,
    });

    const shippingRates = result.data.shippingMethods.results.filter((method) => {
      // chekc if this method has the zone requested...
      for (const zoneRate of method.zoneRates) {
        if (zoneRate.zone.name === zoneName) return true;
      }
      return false;
    });

    return shippingRates;
  } catch (err) {
    // IMPORTANT catcht the error, or else SSR will fail...
    // throw new Error(err);
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    return null;
  }
}

/**
 * Get's shipping rates based on a cart
 * @param ctx
 * @param cartId
 */
export async function getShippingMethodsByCart(ctx, cartId) {
  // support commercetoolsContext instead of client

  // ctx.client;

  try {
    const result = await ctx.client.query({
      query: GET_SHIPPING_METHODS_BY_CART,
      variables: {
        id: cartId,
      },
      fetchPolicy: "network-only", // 'cache-and-network'
    });

    // console.log(result);

    return result.data.shippingMethodsByCart;
  } catch (err) {
    // IMPORTANT catcht the error, or else SSR will fail...
    // throw new Error(err);
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    return null;
  }
}

// --------------------------
//  PAYMENTS
// --------------------------

/**
 * Get a user's payment by ID
 * @param ctx
 * @param id
 */
export async function getMyPaymentById(ctx, id) {
  const tokenInfo = await getTokenInfo(ctx);
  const { access_token } = tokenInfo;

  const result = await rest({
    url: `/me/payments/${id}`,
    access_token,
  });

  if (result.status !== 200) {
    const text = await result.text();
    throw new Error(text);
  }

  return result.json();
}

/**
 * Create a payment for a user
 * @param ctx
 * @param payment
 */
export async function createMyPayment(ctx, payment) {
  const tokenInfo = await getTokenInfo(ctx);
  const { access_token } = tokenInfo;

  const result = await rest({
    url: "/me/payments",
    method: "post",
    body: payment,
    access_token,
  }); // .then(res => res.json());

  if (result.status !== 201) {
    const text = await result.text();
    throw new Error(text);
  }

  return result.json();
}

/**
 * Updates a user's payment
 * @param ctx
 * @param payment
 * @param actions
 */
export async function updateMyPayment(ctx, payment, actions) {
  // WE NEED TO DO REST!

  const tokenInfo = await getTokenInfo(ctx);
  const { access_token } = tokenInfo;

  const result = await rest({
    url: `/me/payments/${payment.id}`,
    method: "post",
    body: {
      version: payment.version,
      actions,
    },
    access_token,
  });

  if (result.status !== 200) {
    const text = await result.text();
    throw new Error(text);
  }

  return result.json();
}

export function getImageOfSize(url, size) {
  return imageUrl(url, size);
  /*
  // if (size === ProductImageSize.original) {
  if (size === 'original') {
    return url;
  }
  const suffix = `-${size}`;
  // const suffix = `-${ProductImageSize[size]}`;
  const uri = new URL(url);
  const position = uri.pathname.lastIndexOf('.');
  if (position === -1) {
    uri.pathname += suffix;
    return uri.toString();
  }
  const filePrefix = uri.pathname.substring(0, position);
  const extension = uri.pathname.substring(position);
  uri.pathname = `${filePrefix}${suffix}${extension}`;
  return uri.toString();
  */
}

// export enum ProductImageSize {
//   original,
//   thumb,
//   small,
//   medium,
//   large,
//   zoom
// }

// --------------------------
//  CHANNELS
// --------------------------

/**
 * Get's channels
 * @param ctx
 */
export async function getChannelsByKey(ctx) {
  // console.log('GET_CHANNELS', ctx);
  // GET_CHANNELS

  try {
    const result = await ctx.client.query({
      query: GET_CHANNELS,
    });

    const channelIdsByKey = result.data.channels.results.reduce((obj, channel) => {
      obj[channel.key] = channel.id;
      return obj;
    }, {});

    return channelIdsByKey;
  } catch (err) {
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    throw err;
  }

  // console.log(result);
  // return result.data.channels.results;
}

// --------------------------
//  ORDERS
// --------------------------

/**
 * Gets a user's order
 * @param ctx
 * @param id
 */
export async function getMyOrder(ctx, id) {
  const { ctpCtx, countryData } = ctx;

  const tokenInfo = await getTokenInfo(ctx);
  const { access_token } = tokenInfo;

  const result = await rest({
    url: `/me/orders/${id}?expand=shippingInfo.shippingMethod&expand=paymentInfo.payments[*]&expand=discountCodes[*].discountCode.cartDiscounts[*].cartDiscount`,
    method: "get",
    access_token,
  });

  if (result.status !== 200 && result.status !== 201) {
    // const error = (await result.json()) as Error;
    const error = await result.json();
    throw error;
  }

  const order = await result.json();

  order.orderItems = await getOrderItems(ctpCtx, order, countryData);

  return order;
}
//--------------------------
//  Product and variant Helpers
//--------------------------

/*
type VariantType = {
  sku?: string;
  id?: string;
  productId?: string; // a reference to the parent product
  name?: string; // from product
  description?: string; // from product
  tagline?: string; // an arttribute...
  key?: string; // mostly for bundle...
  productKey?: string; // mostly for bundle...
  attributesRaw?: any;
  images?: any;
  availability?: {
    availableQuantity?: number;
    restockableInDays?: number;
    expectedDelivery?: number;
  };
  price?: any;
  bundle?: boolean;
  bundleType?: string;
  bundledProductsSkus?: any; // [ProductType?];
  bundledProducts?: any;
  bundleIsHero?: boolean;
};

type ProductType = {
  id?: string;
  key?: string;
  typeKey?: string;
  name?: string;
  description?: string;
  variants?: [VariantType?];
  masterVariantIndex?: number;
};
*/

// resolveOriginals

export function imageUrl(url, size = "xlarge") {
  // small = "-medium"
  // main = "-large"
  // large = "-zoom"
  // xlarge = original

  if (size === "xlarge") return url;

  // add suffix to filename
  const urlParts = url.split(".");
  const ext = urlParts.pop();

  switch (size) {
    case "small":
      return `${urlParts.join(".")}-medium.${ext}`;
    case "main":
      return `${urlParts.join(".")}-large.${ext}`;
    case "large":
      return `${urlParts.join(".")}-zoom.${ext}`;
    default:
      return url;
  }
}

export async function getExpectedDelivery(ctx, sku, channelId) {
  try {
    const result = await ctx.client.query({
      query: GET_INVENTORY_ENTRY,
      variables: {
        where: `sku="${sku}" and supplyChannel(id="${channelId}")`,
      },
      fetchPolicy: "cache-only", // 'network-only' // 'cache-and-network'
    });

    // No data from cache
    if (Object.keys(result.data).length === 0 && result.data.constructor === Object) {
      return null;
    }

    const expectedDeliveryDate =
      result.data.inventoryEntries.results[0].expectedDelivery;

    const expectedDeliveryTime = Date.parse(expectedDeliveryDate);

    return expectedDeliveryTime;
  } catch (err) {
    console.log("getExpectedDelivery", err.toString());
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    return null;
  }
}

export async function getExpectedDeliveries(
  ctx,
  skus,
  channelId,
  fetchPolicy = "cache-first"
) {
  try {
    const result = await ctx.client.query({
      query: GET_INVENTORY_ENTRIES,
      variables: {
        where: `sku in (${skus
          .map((sku) => `"${sku}"`)
          .join(", ")}) and supplyChannel(id = "${channelId}")`,
      },
      fetchPolicy, // 'network-only' // 'cache-and-network'
    });

    const results = result.data.inventoryEntries.results.map((result) => ({
      ...result,
      expectedDelivery:
        result.expectedDelivery !== null ? Date.parse(result.expectedDelivery) : null,
    }));

    return results;
  } catch (err) {
    console.log("getExpectedDeliveries", err.toString());
    console.error(
      err,
      JSON.stringify(err.graphQLErrors || []),
      JSON.stringify(
        (err.networkError &&
          err.networkError.result &&
          err.networkError.result.errors) ||
          []
      )
    );
    return [];
  }
}
