/**
 * actionTypes
 */

export const actionTypes = {
  SET_CONFIGURATION: "SET_CONFIGURATION",
  SET_SOUND_PROFILE: "SET_SOUND_PROFILE",
};

/**
 * Reducer
 */

const reducer = (
  state = {
    configuration: null,
    soundProfile: null,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SET_CONFIGURATION:
      // return action.payload.configuration;
      return { ...state, configuration: action.payload.configuration };
    case actionTypes.SET_SOUND_PROFILE:
      return { ...state, soundProfile: action.payload.soundProfile };
    default:
      return state;
  }
};

export default reducer;

/**
 * Action creators
 */

export function setConfiguration(configuration) {
  return {
    type: actionTypes.SET_CONFIGURATION,
    payload: { configuration },
  };
}

export function setSoundProfile(soundProfile) {
  return {
    type: actionTypes.SET_SOUND_PROFILE,
    payload: { soundProfile },
  };
}
