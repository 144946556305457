import moment from "moment";
import "moment-timezone";
import { useEffect, useState } from "react";
import styled from "styled-components";
import ButtonOutline from "~/components/ButtonOutline";
import LinkButton from "~/components/LinkButton";
import { getRichText } from "~/external/prismic";
import { biggerThan, smallerThan } from "~/styles/theme";

const RibbonWrapper = styled.div<any>`
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4.675rem;
  background-color: ${(p) => p.backgroundColor};
  text-align: left;
  color: ${(p) => p.textColor};
  display: flex;
  padding: 0 1.25rem;
  cursor: pointer;

  a {
    text-decoration: none;
    display: flex;
  }

  p {
    margin: 0;
    align-self: center;
  }

  > * {
    color: inherit;
    height: 100%;
  }

  ${(p) => smallerThan(p.theme.breakpoints.ipadLandscape)} {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-left: 0.875rem;
    padding-right: 0.875rem;

    a {
      text-align: center;
    }

    p {
      font-size: 14px;
    }
  }
`;

const RibbonRight = styled.div<any>`
  ${(p) => biggerThan(p.theme.breakpoints.ipadLandscape)} {
    display: flex;
    margin-left: auto;
  }
`;

const Countdown = styled.div<any>`
  align-self: center;

  div {
    display: flex;
  }

  span {
    padding-top: 9px;
    font-size: 12px;
  }

  em {
    font-size: 34px;
    font-weight: 600;
    font-style: normal !important;

    padding: 0 4px 0 20px;

    :first-child {
      padding-left: 0;
    }
  }

  ${(p) => smallerThan(p.theme.breakpoints.ipadLandscape)} {
    text-align: center;

    em {
      font-size: 30px;
    }

    div {
      display: block;
    }
  }
`;

function Ribbon(props) {
  const { ribbon } = props;

  if (!ribbon || !ribbon.active) return null;

  const [showCountdown, setShowCountdown] = useState(false);

  useEffect(() => {
    if (ribbon.countdown) {
      // Set the target date and time in CET (Central European Time)
      const targetDate = moment.tz(ribbon.countdown, "CET").toDate().getTime();
      setShowCountdown(true);

      // Update the countdown every second
      const countdownInterval = setInterval(function () {
        const currentDate = new Date().getTime();
        const timeRemaining = targetDate - currentDate;

        if (timeRemaining <= 0) {
          // Countdown has ended
          clearInterval(countdownInterval);
          setShowCountdown(false);
        } else {
          // Calculate days, hours, minutes, and seconds
          const days = String(
            Math.floor(timeRemaining / (1000 * 60 * 60 * 24))
          ).padStart(2, "0");
          const hours = String(
            Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
          ).padStart(2, "0");
          const minutes = String(
            Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))
          ).padStart(2, "0");
          const seconds = String(
            Math.floor((timeRemaining % (1000 * 60)) / 1000)
          ).padStart(2, "0");

          // Update the HTML elements within the countdown section
          document.getElementById("days").textContent = days;
          document.getElementById("hours").textContent = hours;
          document.getElementById("minutes").textContent = minutes;
          document.getElementById("seconds").textContent = seconds;
        }
      }, 1000);

      // Clean up the interval when the component unmounts
      return () => clearInterval(countdownInterval);
    }
  }, [ribbon.countdown]);

  return (
    <RibbonWrapper
      backgroundColor={ribbon.background.color}
      textColor={ribbon.text.color}
      onClick={() => (window.location.href = ribbon.link.url)}
    >
      <a href={ribbon.link.url}>
        {ribbon.text.value && getRichText(ribbon.text.value)}
      </a>

      <RibbonRight>
        {showCountdown && (
          <Countdown>
            <div>
              <em id="days"></em>
              <span>D</span>
              <em id="hours"></em>
              <span>H</span>
              <em id="minutes"></em>
              <span>M</span>
              <em id="seconds"></em>
              <span>S</span>
            </div>
          </Countdown>
        )}

        {ribbon.button_text && !showCountdown && (
          <LinkButton
            buttonComponent={ButtonOutline}
            href={ribbon.link.url}
            buttonText={ribbon.button_text}
            style={{ alignSelf: "center", minWidth: "12rem" }}
          />
        )}
      </RibbonRight>
    </RibbonWrapper>
  );
}

export default Ribbon;
