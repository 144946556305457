import styled from "styled-components";
import useKeyPress from "~/hooks/useKeyPress";

const DebugWrapper = styled.div`
  /* pointer-events: none; */
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  font-size: 1rem;
  background-color: tomato;
  color: white;
  z-index: 99999999;
  padding: 0.5rem;
  display: ${(p) => (p.visible ? "block" : "none")};
`;

function Debugger(props) {
  const {
    isServer = false,
    debugAccessToken = "N/A",
    debugExpiresAt = "N/A",
    debugCartId = "N/A",
  } = props;
  const altPressed = useKeyPress("Alt");
  return (
    <DebugWrapper visible={altPressed}>
      <strong>{isServer ? "[SERVER RENDER]" : "[CLIENT RENDER]"}</strong>
      <br />
      access_token: {debugAccessToken}
      <br />
      expires_at: {debugExpiresAt}
      <br />
      cartID: {debugCartId}
    </DebugWrapper>
  );
}

export default Debugger;
