import { useEffect, useState } from "react";
import { Event } from "~/lib/ga/enhancedEcommerce";

export function useDataLayer(dataLayerName = "dataLayer") {
  const [dataLayerEvent, setDataLayerEvent] = useState<Event>(null);

  function push(event: Event) {
    if (dataLayerEvent) {
      return;
    }
    setDataLayerEvent(event);
  }

  useEffect(() => {
    // @ts-ignore
    window[dataLayerName] = window[dataLayerName] || [];
    window[dataLayerName].push(dataLayerEvent);
  }, [dataLayerEvent]);

  // useLayoutEffect(() => {
  /* useEffect(() => {
    console.log(dataLayer.length)
    console.log('dataLayer =>', dataLayer)
    if (dataLayer.length === 0) {
      return;
    }

    console.log('dataLayer =>', dataLayer)
    //setDataLayer([]);
    //setDidPushData(true);
  }, [dataLayer]); */

  return {
    push,
  };
}
