import { getProductByKey, getProductsByIds } from "~/external/commercetools";
import resolveProduct, { resolveProducts } from "~/lib/ctp/resolveProduct";

export async function resolveTma2s(context, products, countryData, parts = []) {
  // Check if configuration - NOT SUPPORTED YET:
  if ([].some((product) => product.productType.key === "tma-2-configuration")) {
    throw new Error("Resolving TMA-2 configurations is not supported yet");
  }

  /* const configurations = await Promise.all(products.map(async product => {
    if (product.productType.key === 'tma-2-configuration') {
      return await getProductByKey(context, 'tma-2-configuration', countryData);
    }
    return null;
  })); */

  let allParts = products.map((product) => {
    let addParts = [];

    // parts
    const parts = product.masterData.current.masterVariant.attributesRaw.find(
      (attr) => attr.name === "Parts"
    );
    if (parts) {
      addParts = [...addParts, ...parts.value];
    }

    // additional parts
    const additionalParts = product.masterData.current.masterVariant.attributesRaw.find(
      (attr) => attr.name === "AdditionalParts"
    );
    if (additionalParts) {
      addParts = [...addParts, ...additionalParts.value];
    }

    const topBox = product.masterData.current.masterVariant.attributesRaw.find(
      (attr) => attr.name === "TopBox"
    );
    if (topBox) addParts.push(topBox.value);

    // get bottomBox
    const bottomBox = product.masterData.current.masterVariant.attributesRaw.find(
      (attr) => attr.name === "BottomBox"
    );
    if (bottomBox) addParts.push(bottomBox.value);

    return addParts;
  });

  allParts = [].concat.apply([], allParts);
  const allPartsIds = allParts.map((part) => part.id);

  // console.log('allParts', allParts);
  // console.log('allPartsIds', allPartsIds);

  const partsProducts = await getProductsByIds(context, allPartsIds, countryData);

  const partsProductsResolved = await resolveProducts(
    context,
    partsProducts,
    countryData
  );

  /* const type = product.productType.key;
  if (type === 'tma-2-configuration') {

  } else if (type === 'tma-2-bundle') {

  } */

  const resolvedProducts = [];
  for (const product of products) {
    let pr;
    let configuration;
    const fetchPartIds = [];
    let bundleIsHero = false;
    let name;
    let description;

    const productTypeKey = product.productType.key;

    // bundle:
    // we need to fetch the parts!
    if (productTypeKey === "tma-2-bundle") {
      pr = product;
      name = pr.masterData.current.name;
      description = pr.masterData.current.description;
    }

    // check if hero
    const isHeroPreset = pr.masterData.current.masterVariant.attributesRaw.find(
      (attr) => attr.name === "IsHeroPreset"
    );
    if (isHeroPreset) bundleIsHero = isHeroPreset.value;

    // TODO; if it's a configuration, don't forget to add the parts!
    /* if (productTypeKey === 'tma-2-configuration') {
      partsProductsResolved = [
        ...configurationPartsProducts,
        ...partsProductsResolved
      ];
    } */

    // console.log(productTypeKey);

    // Filter the parts relevant for this product
    let currentParts = partsProductsResolved.filter((p) =>
      pr.masterData.current.masterVariant.attributesRaw.find(
        (attr) =>
          ["Parts", "AdditionalParts", "TopBox", "BottomBox"].indexOf(attr.name) !==
            -1 &&
          ((Array.isArray(attr.value) &&
            attr.value.some((value) => value.id == p.id)) ||
            attr.value.id === p.id)
      )
    );

    currentParts = [].concat.apply([], currentParts);

    // availableQuantity is the part with the lowest stock..
    // we assume that ALL parts in a bundle only has 1 variant [0]
    const availableQuantity = Math.min(
      ...currentParts
        .filter((p) =>
          pr.masterData.current.masterVariant.attributesRaw.find(
            (attr) => attr.value === p.id
          )
        )
        .map((p) => p.variants[0].availability.availableQuantity)
    );

    // get the variant with the HIGHEST expected delivery
    const expectedDelivery = Math.max(
      ...currentParts
        .filter((p) =>
          pr.masterData.current.masterVariant.attributesRaw.find(
            (attr) => attr.value === p.id
          )
        )
        .map((p) => p.variants[0].availability.expectedDelivery || 0)
    );

    // if (isNaN(expectedDelivery)) expectedDelivery = null;

    const availability = {
      availableQuantity,
      expectedDelivery: expectedDelivery === 0 ? null : expectedDelivery,
    };

    // NEW
    /*
    // create an empty price array and fill it with info from the parts!
    const price = {
      value: null,
      discounted: null // keep if there is no discount
    };
    */

    // console.log('countryData', countryData);

    const price = {
      value: {
        centAmount: 0,
        currencyCode: countryData.currency,
        fractionDigits: 0,
      },
      discounted: {
        value: {
          centAmount: 0,
          currencyCode: countryData.currency,
          fractionDigits: 0,
        },
      },
    };

    /* console.log(
      'partsProductsResolvedPrices',
      partsProductsResolved.map(p => p.variants[0].price)
    ); */

    // console.log('---');

    // go through each part and get it's price (value and discounted both)
    currentParts.forEach((pr) => {
      // console.log('PR', pr.variants.map(v => v.price));
      const v = pr.variants[pr.masterVariantIndex];
      // normal price
      if (v.price.value) {
        price.value.centAmount += v.price.value.centAmount;
        price.value.fractionDigits = v.price.value.fractionDigits;
      }
      // discounted price
      if (v.price.discounted) {
        price.discounted.value.centAmount += v.price.discounted.value.centAmount;
        price.discounted.value.fractionDigits = v.price.discounted.value.fractionDigits;
      } else if (v.price.value) {
        price.discounted.value.centAmount += v.price.value.centAmount;
        price.discounted.value.fractionDigits = v.price.value.fractionDigits;
      }
    });

    // console.log('PRICE', price);

    if (price.discounted.value.centAmount === price.value.centAmount) {
      price.discounted = null;
    }

    // console.log('---');

    const partsProductSkus = currentParts.map(
      (p) => p.variants[p.masterVariantIndex].sku
    );

    resolvedProducts.push({
      key: pr.key,
      id: pr.id,
      typeKey: productTypeKey,
      name,
      description,
      attributesRaw: pr.masterData.current.allVariants[0].attributesRaw,
      // we just have 1 variant
      // "fake" it
      variants: [
        {
          name,
          description,
          // tagline: ''
          productId: pr.id,
          productKey: pr.key,
          productTypeKey,
          key: pr.key, // what to do here?
          sku: pr.masterData.current.masterVariant.sku,
          availability,
          price,
          bundle: true,
          bundleType: productTypeKey === "tma-2-bundle" ? "preset" : "configuration",
          bundledProductsSkus: partsProductSkus,
          bundledProducts: currentParts,
          bundleIsHero,
          images: pr.masterData.current.masterVariant.images,
        },
      ],
      masterVariantIndex: 0,
    });
  }

  return resolvedProducts;
}

async function resolveTma2(
  ctpCtx,
  productOrConfiguration,
  countryData,
  configurationPartsProducts = []
) {
  try {
    // pr.productType.key: 'tma-2-bundle'
    // - has predefined products (parts);

    //  pr.productType.key: 'tma-2-configuration'
    // - will get an array of products

    // Bundle
    // does not have stuck or price - we need to fetch the parts!

    // let partProducts = [];
    let pr;
    let configuration;
    let fetchPartIds = [];
    let bundleIsHero = false;
    let name;
    let description;

    const productTypeKey = productOrConfiguration.productType.key;

    // console.log('productTypeKey', productTypeKey, productOrConfiguration);

    // configuration:
    // we need to _have_ the parts passed (partProducts)
    if (productTypeKey === "tma-2-configuration") {
      // if (partProducts.length === 0) {
      //   throw new Error(`resolveTma2: no partProducts was provided for TMA-2`);
      // }

      // switch the pr
      configuration = productOrConfiguration;
      pr = await getProductByKey(ctpCtx, "tma-2-configuration", countryData);

      name = configuration.name;
      description = configuration.description || pr.masterData.current.description;
    }

    // bundle:
    // we need to fetch the parts!
    if (productTypeKey === "tma-2-bundle") {
      pr = productOrConfiguration;

      name = pr.masterData.current.name;
      description = pr.masterData.current.description;
    }

    // parts
    const parts = pr.masterData.current.masterVariant.attributesRaw.find(
      (attr) => attr.name === "Parts"
    );
    // FYI returns an array...
    if (parts) {
      fetchPartIds = [...fetchPartIds, ...parts.value];
    }

    // additional parts
    const additionalParts = pr.masterData.current.masterVariant.attributesRaw.find(
      (attr) => attr.name === "AdditionalParts"
    );
    // FYI returns an array...
    if (additionalParts) {
      fetchPartIds = [...fetchPartIds, ...additionalParts.value];
    }

    // get top and bottom box for both!
    // get topBox
    const topBox = pr.masterData.current.masterVariant.attributesRaw.find(
      (attr) => attr.name === "TopBox"
    );
    if (topBox) fetchPartIds.push(topBox.value);

    // get bottomBox
    const bottomBox = pr.masterData.current.masterVariant.attributesRaw.find(
      (attr) => attr.name === "BottomBox"
    );
    if (bottomBox) fetchPartIds.push(bottomBox.value);

    // check if hero
    const isHeroPreset = pr.masterData.current.masterVariant.attributesRaw.find(
      (attr) => attr.name === "IsHeroPreset"
    );
    if (isHeroPreset) bundleIsHero = isHeroPreset.value;

    // console.log('fetchPartIds', fetchPartIds);

    // right now the array if filled with objects with an id field. extract them
    fetchPartIds = fetchPartIds.map((pid) => pid.id);

    // console.log('fetchPartIds', fetchPartIds);

    // we need the products to calculate price + avail
    // fetch the products
    const partsProducts = await getProductsByIds(ctpCtx, fetchPartIds, countryData);

    // console.log('partsProducts', partsProducts);

    // resolve the products, so we have the info we need from them!
    let partsProductsResolved = await Promise.all(
      partsProducts.map(async (partProduct) => {
        // console.log('1', JSON.stringify(partProduct));
        const partsProductResolved = await resolveProduct(
          ctpCtx,
          partProduct,
          countryData
        );
        // console.log('2', JSON.stringify(partsProductResolved));
        /*
        if (partProduct.key === 'c02') {
          console.log(
            '🙃 1',
            '',
            partProduct.masterData.current.masterVariant.price
          );
          console.log('🙃 2', '', partsProductResolved.variants[0].price);
        }
        */

        return partsProductResolved;
      })
    );

    // if it's a configuration, don't forget to add the parts!
    if (productTypeKey === "tma-2-configuration") {
      partsProductsResolved = [...configurationPartsProducts, ...partsProductsResolved];
    }

    // console.log(productTypeKey);

    // availableQuantity is the part with the lowest stock..
    // we assume that ALL parts in a bundle only has 1 variant [0]
    const availableQuantity = Math.min(
      ...partsProductsResolved.map((p) => p.variants[0].availability.availableQuantity)
    );

    // get the variant with the HIGHEST expected delivery
    const expectedDelivery = Math.max(
      ...partsProductsResolved.map(
        (p) => p.variants[0].availability.expectedDelivery || 0
      )
    );

    // if (isNaN(expectedDelivery)) expectedDelivery = null;

    const availability = {
      availableQuantity,
      expectedDelivery: expectedDelivery === 0 ? null : expectedDelivery,
    };

    // NEW
    /*
    // create an empty price array and fill it with info from the parts!
    const price = {
      value: null,
      discounted: null // keep if there is no discount
    };
    */

    // console.log('countryData', countryData);

    const price = {
      value: {
        centAmount: 0,
        currencyCode: countryData.currency,
        fractionDigits: 0,
      },
      discounted: {
        value: {
          centAmount: 0,
          currencyCode: countryData.currency,
          fractionDigits: 0,
        },
      },
    };

    // console.log(
    //   'partsProductsResolvedPrices',
    //   partsProductsResolved.map(p => p.variants[0].price)
    // );

    // console.log('---');

    // go through each part and get it's price (value and discounted both)
    partsProductsResolved.forEach((pr) => {
      // console.log('PR', pr);
      const v = pr.variants[pr.masterVariantIndex];
      // normal price
      if (v.price.value) {
        price.value.centAmount += v.price.value.centAmount;
        price.value.fractionDigits = v.price.value.fractionDigits;
      }
      // discounted price
      if (v.price.discounted) {
        price.discounted.value.centAmount += v.price.discounted.value.centAmount;
        price.discounted.value.fractionDigits = v.price.discounted.value.fractionDigits;
      } else if (v.price.value) {
        // Add the regular price to discounted value
        price.discounted.value.centAmount += v.price.value.centAmount;
        price.discounted.value.fractionDigits = v.price.value.fractionDigits;
      }
    });

    // console.log('PRIZE', price);

    if (price.discounted.value.centAmount === price.value.centAmount) {
      price.discounted = null;
    }

    // console.log('---');

    const partsProductSkus = partsProductsResolved.map(
      (p) => p.variants[p.masterVariantIndex].sku
    );

    const product = {
      key: pr.key,
      id: pr.id,
      typeKey: productTypeKey,
      name,
      description,
      // we just have 1 variant
      // "fake" it
      variants: [
        {
          name,
          description,
          // tagline: ''
          productId: pr.id,
          productKey: pr.key,
          productTypeKey,
          key: pr.key, // what to do here?
          sku: pr.masterData.current.masterVariant.sku,
          availability,
          price,
          bundle: true,
          bundleType: productTypeKey === "tma-2-bundle" ? "preset" : "configuration",
          /* bundleType: (() => {
            switch (productTypeKey) {
              case 'tma-2-bundle':
                return 'preset';
              case 'parts-bundle':
                return 'parts';
              case 'tma-2-configuration':
                return 'configuration';
              default:
                'unsupported';
            }
          })(), */
          bundledProductsSkus: partsProductSkus,
          bundledProducts: partsProductsResolved,
          bundleIsHero,
          images: pr.masterData.current.masterVariant.images,
        },
      ],
      masterVariantIndex: 0,
    };

    // DEBUG
    // if (productTypeKey === 'tma-2-configuration') {
    //   console.log(
    //     '🚀',
    //     productTypeKey,
    //     productOrConfiguration,
    //     configurationPartsProducts
    //   );
    //   // console.log('👌 GOT partsProductsResolved', partsProductsResolved);
    //   console.log('🎠', product);
    // }

    return product;
  } catch (err) {
    console.log("resolveProduct", err.toString());
    console.error(err);
    throw err;
  }
}

export default resolveTma2;
