import { AnimatePresence, motion } from "framer-motion";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import ButtonOutline from "~/components/ButtonOutline";
import LinkButton from "~/components/LinkButton";
import PriceLine from "~/components/PriceLine";
import ProductGrid from "~/components/ProductGrid";
import { getProductsByKeys, imageUrl } from "~/external/commercetools";
import { CtpCtx } from "~/lib/ctpApollo";
import { removeToast } from "~/store/toasts";
import { paragraph, vSpacing } from "~/styles/mixins";
import theme, { smallerThan } from "~/styles/theme";

const ToastsWrapper = styled.div`
  position: fixed;
  /* top: ${(p) => p.theme.sizes.vSpacing * 2}rem; */
  right: ${(p) => p.theme.sizes.hSpacing * 2}rem;
  padding-top: ${(p) => p.theme.sizes.topBarHeight}rem;
  z-index: ${(p) => p.theme.z.toasts};
  width: 100%;
  max-width: 320px;
`;

const ToastWrapper = styled(motion.div)`
  position: ${(p) => (p.fullScreen ? "fixed" : "relative")};
  ${(p) =>
    p.fullScreen &&
    `
    overscroll-behavior: contain;
    left: 0;
    right: 0;
    margin-top: ${(p) => p.theme.sizes.topBarHeight}rem;
    > div {
      height: 90%;
      overflow: scroll;
    }
  `}

  ${(p) => smallerThan(p.theme.breakpoints.small)} {
    ${(p) =>
      p.fullScreen &&
      `
      margin-top: ${(p) => p.theme.sizes.topBarHeightSmall}rem;
    `}
  }

  /* padding-bottom: ${(p) => p.theme.sizes.vSpacing * 2}rem; */

  /* border: 1px solid black; */
  /* padding: 0.6rem; */
  /* margin-top: ${(p) => p.theme.sizes.vSpacing * 2}rem; */
  /* &:nth-child(0) {
    margin-top: 0;
  } */
  overflow: hidden;
  img {
    display: block;
    max-width: 100%;
    background-color: ${(p) => p.theme.colors.light};
  }

  /* background-color: ${(p) => p.backgroundColor || p.theme.colors.dark};
  color: ${(p) => p.color || p.theme.colors.light};
  > div {
    padding: ${(p) => p.theme.sizes.vSpacing}rem ${(p) =>
    p.theme.sizes.hSpacing * 2}rem;

  } */
`;

const ToastInner = styled.div`
  padding: ${(p) => p.theme.sizes.vSpacing}rem ${(p) => p.theme.sizes.hSpacing * 2}rem;
  background-color: ${(p) => p.backgroundColor || p.theme.colors.dark};
  color: ${(p) => p.color || p.theme.colors.light};
  margin-bottom: ${(p) => p.theme.sizes.vSpacing * 2}rem;
  hr {
    border: 0;
    height: 1px;
    /*background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));*/
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const ToastButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${(p) => p.theme.sizes.hSpacing * 2}rem;
  ${vSpacing};
`;

const ToastDismiss = styled.div`
  &:after {
    content: "✕";
    ${vSpacing};
    font-size: 1rem;
    position: absolute;
    top: ${(p) => p.theme.sizes.vSpacing * 0.75}rem;
    right: ${(p) => p.theme.sizes.hSpacing * 2}rem;
    cursor: pointer;
  }
`;

const ToastTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  ${vSpacing};
`;
const ToastText = styled.div`
  ${paragraph};
`;

const ToastProduct = styled.div`
  ${vSpacing};
  display: flex;
  ${(p) =>
    p.crossSells &&
    `
  display: grid;
  grid-template-columns: auto 0.7fr 1.5fr;
  grid-gap: 1em 1em; /*${(p) => p.theme.sizes.vSpacing * 2.5}rem
    ${(p) => p.theme.sizes.hSpacing * 2}rem;*/
  margin: 0 8%;
  align-items: center;`}

  ${(p) => smallerThan(p.theme.breakpoints.small)} {
    ${(p) =>
      p.crossSells &&
      `
      grid-template-columns: 1fr 1fr;
      margin: 0;
      & > div.product-desc, & > img {
        justify-self: center;
      }
      & > :last-child {
        grid-column-start: span 2;
      }
    `}
  }

  img {
    width: ${(p) => (p.crossSells ? "240px" : "80px")};
    height: ${(p) => (p.crossSells ? "240px" : "80px")};
    margin-right: ${(p) => p.theme.sizes.hSpacing * 2}rem;
    background-color: ${(p) => (p.crossSells ? "transparent" : p.theme.colors.light)};
  }
  > div {
    flex: 1;
    h4 {
      padding: 0;
      margin: 0;
      font-weight: bold;
    }
    p {
      padding: 0;
      margin: 0;
    }
  }

  ${(p) => smallerThan(p.theme.breakpoints.small)} {
    ${(p) =>
      p.crossSells &&
      `
      grid-template-columns: 1fr 1fr;
    `}
    img {
      width: 120px;
      height: 120px;
    }
    & > :last-child {
      grid-column-start: span 2;
    }
  }
`;

const ToastCrossSells = styled.div`
  display: flex;
  margin: ${(p) => p.theme.sizes.vSpacing * 0.75}rem 0;
`;

const ToastCrossSellsProduct = styled.div`
  flex: 1;
  img {
    width: 180px;
    height: 180px;
    background-color: transparent;
  }
  > div {
    h4 {
      /*margin-bottom: ${(p) => p.theme.sizes.vSpacing * 0.25}rem;*/
    }
  }
`;

const CROSSSELL_ITEMS = ["a01", "a02", "c60", "c14"];

function Toast(props) {
  const { toast = {} } = props;
  // const { id, message, type, unMountMs } = toast;

  // console.log('🍞', toast);

  // console.log(theme.sizes.topBarHeightSmall);

  let {
    id = Date.now(),
    title,
    text,
    product,
    variant,
    extras = [],
    unMountMs = 3000,
    secondaryButton,
    primaryButton,
    dismissButton = true,
    color = theme.colors.dark,
    backgroundColor = theme.colors.grey,
    fullScreen = false,
    crossSells = false,
    optimize,
  } = toast;

  if (optimize) {
    // TODO: Detect configuration
    fullScreen = true;
    crossSells = true;
    backgroundColor = "white";
    unMountMs = null;
  }

  const ctpCtx = useContext(CtpCtx);
  const countryData = useSelector((state) => state.countryData);

  // use reverse colors of topbar!
  const ui = useSelector((state) => state.ui);
  /*
  if (ui.topBarBackgroundColor) backgroundColor = ui.topBarColor;
  if (ui.topBarColor) color = ui.topBarBackgroundColor;
  */

  // if (ui.topBarBackgroundColor) backgroundColor = ui.topBarBackgroundColor;
  // if (ui.topBarColor) color = ui.topBarColor;

  // console.log(color, backgroundColor);

  const dispatch = useDispatch();
  const timeoutRef = useRef();

  function handleRemoveToast() {
    dispatch(removeToast(toast));
    if (typeof document !== "undefined" && fullScreen) {
      document.body.classList.remove("fullscreen-toast");
    }
  }

  useEffect(() => {
    if (fullScreen) {
      document.body.classList.add("fullscreen-toast");
    }
    if (!unMountMs) return;
    timeoutRef.current = setTimeout(() => {
      handleRemoveToast();
    }, unMountMs || 2000);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  if (!variant && product) {
    variant = product.variants[product.masterVariantIndex];
  }

  // console.log('variant', variant);

  const buttons = (
    <ToastButtons>
      {secondaryButton && (
        <LinkButton
          href={secondaryButton.href}
          as={secondaryButton.as}
          buttonComponent={ButtonOutline}
          callback={handleRemoveToast}
          buttonProps={{
            color,
            low: true,
          }}
        >
          {secondaryButton.text}
        </LinkButton>
      )}
      {primaryButton && (
        <LinkButton
          href={primaryButton.href}
          as={primaryButton.as}
          buttonComponent={ButtonOutline}
          callback={handleRemoveToast}
          buttonProps={{
            color: backgroundColor,
            backgroundColor: color,
            low: true,
            filled: true,
          }}
        >
          {primaryButton.text}
        </LinkButton>
      )}
    </ToastButtons>
  );

  const [crossSellItems, setCrossSellItems] = useState([]);

  useEffect(() => {
    if (!crossSells) return;

    const getItems = async () => {
      const products = await getProductsByKeys(ctpCtx, CROSSSELL_ITEMS, countryData);
      setCrossSellItems(
        products.map((product) => ({
          product: { ...product, ...product.masterData.current },
        }))
      );
    };

    getItems();
  }, []);

  return (
    <ToastWrapper
      id={id}
      initial={{ height: 0 }}
      animate={{ height: "100%" }}
      exit={{ height: 0 }}
      fullScreen={fullScreen}
    >
      <ToastInner backgroundColor={backgroundColor} color={color}>
        {dismissButton && <ToastDismiss onClick={handleRemoveToast} />}
        {title && <ToastTitle>{title}</ToastTitle>}
        {text && <ToastText>{text}</ToastText>}
        {variant && (
          <ToastProduct crossSells={crossSells}>
            {variant.images.length > 0 && (
              <img src={imageUrl(variant.images[0].url, "small")} />
            )}

            <div className="product-desc">
              <h4>{variant.name}</h4>
              <p>
                {/* <Price
                  currencyCode={variant.price.currencyCode}
                  centAmount={variant.price.centAmount}
                  // TODO: fractionDigits={variant.price.fractionDigits}
                  fractionDigits={2}
                /> */}
                <PriceLine
                  price={variant.price}
                  extras={extras.map(
                    (extra) => extra.variants[extra.masterVariantIndex].price
                  )}
                />
              </p>
            </div>
            {crossSells && buttons}
          </ToastProduct>
        )}
        {variant && crossSells && (
          <>
            <hr />
            <ToastTitle crossSells={crossSells}>Customers also bought</ToastTitle>
            <ProductGrid
              cards={crossSellItems}
              columnsOnMobile={2}
              skipCrossSells
              animateIn
              backgroundColor={backgroundColor}
            />
            {/* <ToastCrossSells>
              {[1, 2].map((product, i) => {
                return (
                  <ToastCrossSellsProduct key={i}>
                    {variant.images.length > 0 && (
                      <img src={imageUrl(variant.images[0].url, 'small')} />
                    )}
                    <div>
                      <h4>{variant.name}</h4>
                      <p>
                        {variant.description}
                      </p>
                      <BuyButton
                        variant={variant}
                        buttonComponent={ButtonSquare}
                        includePrice={true}
                        skipCrossSells={true}
                        //customOutOfStockText={customOutOfStockText}
                        //id="buy_button"
                        highlightColor={theme.colors.primary}
                      />
                    </div>
                  </ToastCrossSellsProduct>
                );
              })}
            </ToastCrossSells> */}
          </>
        )}
        {!crossSells && buttons}
      </ToastInner>
    </ToastWrapper>
  );
}

function Toasts(props) {
  const dispatch = useDispatch();
  const toasts = useSelector((s) => s.toasts);

  return (
    <ToastsWrapper>
      <AnimatePresence>
        {toasts.map((toast) => (
          <Toast key={toast.id} toast={toast} />
        ))}
      </AnimatePresence>
    </ToastsWrapper>
  );
}

export default Toasts;
