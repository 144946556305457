// State

import { CountryData, defaultCountry } from "~/data/shippingCountries";

const initialState: CountryData = defaultCountry;

// Actions

type SetCountryDataAction = {
  type: "SET_COUNTRYDATA";
  payload: any;
};

type CountryDataAction = SetCountryDataAction;

export const setCountryData = (countryData: any): SetCountryDataAction => ({
  type: "SET_COUNTRYDATA",
  payload: { countryData },
});

// Reducer

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: Readonly<CountryData> | undefined = initialState,
  action: CountryDataAction
) => {
  switch (action.type) {
    case "SET_COUNTRYDATA":
      return action.payload.countryData;
    default:
      return state;
  }
};

export default reducer;
