import styled from "styled-components";
import { rem, smallerThan } from "~/styles/theme";
// import { vSpacing, vScroll } from 'styles/mixins';

// import { getSoundProfile } from 'external/prismic';

const SoundProfileWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;

  /* ${(p) => smallerThan(p.theme.breakpoints.configuratorSmall)} {
    bottom: ${(p) => p.theme.sizes.vSpacingSmall * 2}rem;
  } */

  li {
    display: flex;
    font-size: ${(p) => rem(p.small ? "9px" : "11px")};
    /* font-family: 'RelativeFaux'; */
    align-items: center;
    height: ${(p) => p.theme.sizes.vSpacing * (p.small ? 1.5 : 2)}rem;
    > span {
      flex: 1;
      padding-right: ${(p) => p.theme.sizes.hSpacing}rem;
      color: ${(p) => p.theme.colors.dark};
    }
  }
`;
const SoundBar = styled.div`
  position: relative;
  /* width: ${rem("50px")};
  height: ${rem("4px")}; */
  width: ${(p) => rem(p.small ? "36px" : "50px")};
  height: ${(p) => rem(p.small ? "3px" : "4px")};
  background-color: ${(p) => p.theme.colors.grey};
  &:after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0;
    bottom: 0;
    min-width: 2px;
    width: ${(p) => p.value}%;
    background-color: ${(p) => p.theme.colors.dark};
    transition: width 1s;
  }
`;

function SoundProfile(props) {
  const { soundProfile, small = false } = props;
  if (!soundProfile) return null;

  // console.log('SoundProfile', soundProfile);

  const { frequency_high, frequency_mid, frequency_low } = soundProfile;

  // console.log(frequency_high, frequency_mid, frequency_low);

  if (
    frequency_high === undefined ||
    frequency_mid === undefined ||
    frequency_low === undefined
  )
    return null;

  // CHECJ

  // console.log(JSON.stringify(soundProfile));

  return (
    <SoundProfileWrapper small={small}>
      <li>
        <span>Treble</span>{" "}
        <SoundBar value={((frequency_high + 1) / 2) * 100} small={small} />
      </li>
      <li>
        <span>Mids</span>{" "}
        <SoundBar value={((frequency_mid + 1) / 2) * 100} small={small} />
      </li>
      <li>
        <span>Bass</span>{" "}
        <SoundBar value={((frequency_low + 1) / 2) * 100} small={small} />
      </li>
    </SoundProfileWrapper>
  );
}

export default SoundProfile;
