function useCartItemsCount(cart) {
  if (!cart) {
    return 0;
  }

  const bundles = [];
  const items = [];

  // 1) add custom lines
  cart.customLineItems.forEach((customLineItem) => {
    // make place to add bundles lineItems
    customLineItem.items = [];
    bundles.push(customLineItem);
  });

  // 2) add line items, but if "inBundle" add to bundle?
  cart.lineItems.forEach((lineItem) => {
    // here we assume that the ONLY custom field is lineItem.customFields.CustomLineId.value...
    if (
      cart.slim
        ? lineItem.custom && lineItem.custom.fields && lineItem.custom.fields.BundleId
        : lineItem.custom &&
          lineItem.custom.customFieldsRaw &&
          lineItem.custom.customFieldsRaw.some((field) => field.name == "BundleId")
    ) {
      // add it to the bundle!
      // find an item that matches the BundleId
      const bundleItem = bundles.find((item) =>
        cart.slim
          ? item.custom &&
            item.custom.fields &&
            item.custom.fields.BundleId &&
            item.custom.fields.BundleId === lineItem.custom.fields.BundleId
          : item.custom &&
            item.custom.customFieldsRaw &&
            item.custom.customFieldsRaw.some(
              (field) =>
                field.name == "BundleId" &&
                field.value ==
                  lineItem.custom.customFieldsRaw.find(
                    (field) => field.name == "BundleId"
                  ).value
            )
      );
      // make sure the item is there...
      if (bundleItem) {
        // console.log('bundleItem =>', JSON.stringify(bundleItem, null, 2));
        // add it to the bundle, instead of items.
        bundleItem.items.push(lineItem);
      }
    } else {
      // add it to items
      items.push(lineItem);
    }
  });

  /*

  // Calculate the price for each bundle...
  bundles.forEach(bundle => {
    // reduce the price from all the prices
    bundle.totalPrice.centAmount = bundle.items.reduce((centAmount, item) => {
      return centAmount + item.totalPrice.centAmount;
    }, 0);
    const firstItemPrice = bundle.items[0].price.value;
    bundle.price = {
      value: {
        currencyCode: firstItemPrice.currencyCode,
        fractionDigits: firstItemPrice.fractionDigits
      }
    };
    bundle.price.value.centAmount = bundle.items.reduce((centAmount, item) => {
      return centAmount + item.price.value.centAmount;
    }, 0);
  });

  */

  return bundles.length + items.reduce((n, i) => (n += i.quantity), 0);

  // return [...bundles, ...items];
}

export default useCartItemsCount;
