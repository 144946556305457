import classnames from "classnames";
import styled from "styled-components";
import Input from "~/styles/elements/InputV2";
import Label from "~/styles/elements/LabelV2";
import theme from "~/styles/theme";

const Wrapper = styled.div`
  position: relative;
  display: ${(p) => (p.inline ? "inline-block" : "block")};
  border: 0;
  padding: 0;
`;

const ScrollToTarget = styled.div`
  position: absolute;
  top: -120px;
  width: 10px;
  height: 10px;
  pointer-events: none;
  /* background-color: red; */
`;

const LabelStyled = styled(Label)`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  transform: translateY(1.2em);
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.1s, transform 0.1s, font-size 0.1s;
  font-size: ${(p) => p.fontSize};
  /* padding-bottom: 0.35em; */
`;

const InputStyled = styled(Input)`
  font-size: ${(p) => p.fontSize};
  &:focus ~ ${LabelStyled}, &:not(:placeholder-shown) ~ ${LabelStyled} {
    transform: translateY(0);
    font-size: 0.7em;
    opacity: 0.5;
  }
  &:disabled ~ ${LabelStyled} {
    opacity: 0.5;
  }
  /* &.invalid.dirty ~ ${LabelStyled} { */
  &.invalid ~ ${LabelStyled} {
    color: ${(p) => p.theme.colors.error};
  }
`;

function FormInputText(props) {
  const {
    value = "",
    onChange = (e) => {},
    label,
    name = "",
    backgroundColor = "transparent",
    color = theme.colors.light,
    dirty = false,
    valid = false,
    submitted = null, // wether "some form" has been submitted
    fontSize = "1rem",
  } = props;

  //  const invalid = !valid && submitted !== true;
  const invalid = (!valid && dirty) || (!valid && submitted);

  return (
    <Wrapper>
      <ScrollToTarget className="scrollToTarget" />
      <InputStyled
        className={classnames({ dirty, valid, invalid })}
        placeholder=" "
        fontSize={fontSize}
        {...props}
      />
      {label && (
        <LabelStyled
          className={classnames({ dirty, valid, invalid })}
          htmlFor={name}
          color={color}
          fontSize={fontSize}
        >
          {label}
        </LabelStyled>
      )}
    </Wrapper>
  );
}

export default FormInputText;
