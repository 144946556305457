import { forwardRef } from "react";
import styled from "styled-components";
import { spinner, vSpacing } from "~/styles/mixins";
import ArrowGfx from "./arrow.svg";
import CheckGfx from "./check.svg";

const Arrow = styled(ArrowGfx)`
  width: 100px;

  stroke-width: 4;
  stroke-miterlimit: 10;
  transition: transform 0.2s;
  margin-left: ${(p) => p.theme.sizes.hSpacing * 2}rem;
`;

const Check = styled(CheckGfx)`
  width: 24px;
  stroke-width: 2;
  stroke-miterlimit: 10;
  transition: transform 0.2s;
  margin-left: ${(p) => p.theme.sizes.hSpacing * 1}rem;
`;

const Spinner = styled.div`
  ${spinner};
  &:after {
    left: auto;
    top: auto;
    position: relative;
    margin: 0;
    display: inline-block;
    margin-left: 1rem;
  }
`;

const ArrowButtonWrapper = styled.div`
  font-family: "AIAIAI Extended";
  height: 1rem;
  ${vSpacing};
  color: ${(p) => p.color || p.theme.colors.dark};
  line-height: 0.75; /* truncate whitespace top and bottom to make dimensions fit with spacing. */
  font-size: 14px;
  font-weight: bold;
  color: ${(p) => p.color || p.theme.colors.dark};
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  /*white-space: nowrap;*/

  ${Arrow} {
    stroke: ${(p) => p.color || p.theme.colors.dark};
    fill: ${(p) => p.color || p.theme.colors.dark};
  }
  &:hover {
    ${Arrow} {
      transform: translateX(${(p) => p.theme.sizes.hSpacing * 2}rem);
    }
  }
`;

function ButtonArrow(props, ref) {
  // const { children, color } = props;
  const {
    children,
    fontSize,
    id,
    disabled = false,
    working,
    done = false,
    onClick = () => {},
    color,
    arrow = true,
  } = props;

  return (
    <ArrowButtonWrapper
      color={color}
      ref={ref}
      id={id}
      fontSize={fontSize}
      disabled={disabled || working}
      working={working}
      onClick={onClick}
      tabIndex={0}
      onKeyPress={(e) => e.which === 13 && onClick(e)}
      arrow={arrow}
    >
      <span>{children}</span>
      {working && <Spinner />}
      {done && <Check />}
      {arrow && !working && !done && <Arrow />}
    </ArrowButtonWrapper>
  );
}

export default forwardRef(ButtonArrow);

/*


function ButtonSquare(props, ref) {
  const {
    children,
    fontSize,
    id,
    disabled,
    working,
    onClick = () => {}
  } = props;
  return (
    <Button
      ref={ref}
      id={id}
      fontSize={fontSize}
      disabled={disabled}
      working={working}
      onClick={onClick}
    >
      {working ? <>...</> : children}
    </Button>
  );
}

export default forwardRef(ButtonSquare);


*/
