import styled from "styled-components";
import { hSpacing } from "~/styles/mixins";
import { rem } from "~/styles/theme";

const DropdownMenuTitle = styled.li`
  ${hSpacing};
  color: ${(p) => p.theme.colors.dark};
  font-size: ${rem("18px")};
  font-weight: bold;
  padding-top: 1em;
  padding-bottom: 0.5em;
`;

export default DropdownMenuTitle;
