import { motion } from "framer-motion";
import { useContext } from "react";
import { useInView } from "react-intersection-observer";
import Block, { BlockContent, BlockGrid } from "~/components/Block";
import Card from "~/components/Card";
import { ThemeContext } from "~/lib/ThemeContext";

const cardVariants = {
  out: { opacity: 0, y: "100%" },
  in: {
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      ease: [0.23, 1, 0.32, 1],
      duration: 2,
    },
  },
};

function ProductGrid(props) {
  const currentTheme = useContext(ThemeContext);

  const {
    spacingTop = 0,
    spacingBottom = 6,

    backgroundColor = currentTheme.background,
    color = currentTheme.foreground,

    cards = [],
    columnsOnDesktop = 4,
    columnsOnMobile = 1,
    type = "a",
    buttonType = "outlined",
    buttonAction = "buy",
    animateIn = false,
    skipCrossSells = false,
    showText = true,
    showPrice = true,
  } = props;

  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  let animate = inView ? "in" : "out";
  if (!animateIn) {
    animate = "in";
  }

  return (
    <Block
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      backgroundColor={backgroundColor}
      color={color}
    >
      <BlockContent ref={ref}>
        <motion.div variants={cardVariants} animate={animate}>
          <BlockGrid columnsOnMobile={columnsOnMobile} cols={columnsOnDesktop}>
            {cards.map((card, i) => (
              <Card
                key={i}
                color={color}
                showProductPrice={showPrice}
                type={type}
                buttonType={buttonType}
                buttonAction={buttonAction}
                allClickable
                buttonSpacing={4}
                skipCrossSells={skipCrossSells}
                showText={showText}
                {...card}
              />
            ))}
          </BlockGrid>
        </motion.div>
      </BlockContent>
    </Block>
  );
}

export default ProductGrid;
