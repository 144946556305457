import styled from "styled-components";
import useChildrenWithProps from "~/hooks/useChildrenWithProps";
import { hSpacing } from "~/styles/mixins";
import { smallerThan } from "~/styles/theme";

export const TopBarWrapper = styled.div`
  ${hSpacing};
  z-index: ${(p) => p.theme.z.topBar};
  top: ${(p) => p.top || 0};
  left: 0%;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.backgroundColor || "transparent"};
  backface-visibility: hidden;
  /* transition: transform 0.5s, background-color 0.2s; */
  transition: transform 0.5s, background-color 0.2s;
  transition-delay: 0.2s; /* should match close animation */
  height: ${(p) => p.theme.sizes.topBarHeight}rem;
  ${(p) => smallerThan(p.theme.breakpoints.ipadLandscape)} {
    height: ${(p) => p.theme.sizes.topBarMobileHeight}rem;
  }

  /* .route-change & {
    background-color: transparent !important;
  } */
`;

function TopBar(props) {
  const { color, backgroundColor, top } = props;

  // add props to children
  const children = useChildrenWithProps(props.children, {
    top,
    color,
    backgroundColor,
  });

  return (
    <TopBarWrapper top={top} color={color} backgroundColor={backgroundColor}>
      {children}
    </TopBarWrapper>
  );
}

export default TopBar;
