/**
 * Action types
 */

export const actionTypes = {
  SET_CART: "SET_CART",
  CLEAR_CART: "CLEAR_CART",
};

/**
 * Reducer
 */

const reducer = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_CART:
      return action.payload.cart;
    case actionTypes.CLEAR_CART:
      return null;
    default:
      return state;
  }
};

export default reducer;

/**
 * Actions
 */

export function setCart(cart) {
  return {
    type: actionTypes.SET_CART,
    payload: { cart },
  };
}

export function clearCart() {
  return {
    type: actionTypes.CLEAR_CART,
  };
}
