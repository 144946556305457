/**
 * actionTypes
 */

export const actionTypes = {
  SET_LANGUAGE: "SET_LANGUAGE",
};

/**
 * Reducer
 */

const reducer = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_LANGUAGE:
      return action.payload.language;
    default:
      return state;
  }
};

export default reducer;

/**
 * Action creators
 */

export function setLanguage(language) {
  return {
    type: actionTypes.SET_LANGUAGE,
    payload: { language },
  };
}
