import { createMyCart, getActiveMyCartRest, getMyCart } from "~/external/commercetools";

// gets active cart for a user. provice ctx from getInitialProps
async function resolveCart(ctx = {}, full = false) {
  // ctx should have countryData
  if (!ctx.countryData) {
    throw new Error("resolveCart ctx missing countryData");
  }

  const { countryData, ctpCtx } = ctx;

  // only do cart lookup if we have a supported country!
  if (!countryData.shipping.enabled) {
    return null;
  }

  // get the users active carts by rest - this way we can pick the latest first
  const activeCart = await getActiveMyCartRest(ctx, countryData);

  // TESTING:
  let cart = activeCart;

  // if we have a active cart, get it via graphql
  if (activeCart && full) {
    // console.log('Trying to get full active cart from GraphQL', activeCart);
    // console.log(countryData);
    cart = await getMyCart(ctpCtx, activeCart.id, countryData);
    // console.log(cart);
  }

  if (cart !== null) {
    // do some extra checks to make sure we have a cart...
    try {
      const { cartState } = cart;
    } catch (err) {
      console.error("Cart was invalid, setting as null...");
      cart = null;
    }
  }

  // check if cart is __actually__ active?
  if (cart) {
    // make sure to double check if cart is still active...
    if (cart.cartState !== "Active") {
      cart = null;
    }
  }

  // if we still dont have a cart, createa a new one!
  if (!cart) {
    cart = await createMyCart(ctpCtx, countryData);
    console.log(
      `🛒 created new cart ${cart.id} for '${ctx.ctpTokenInfo.access_token}'`
    );
  } else {
    console.log(
      `🛒 ✅ got active cart '${cart.id}' for '${ctx.ctpTokenInfo.access_token} (country: ${cart.shippingAddress.country})'`
    );
  }

  if (cart) {
    cart.slim = !full;
  }

  return cart;
}

export default resolveCart;
