/**
 * Can get a domain with protocol both serverside and client
 * used in getIntialProps
 */

function getDomain(ctx = {}) {
  if (ctx.req) {
    // server
    // assume it is http, unless X-Forwarded-Proto is set

    if (ctx.req.headers.host.includes("localhost")) {
      return `http://${ctx.req.headers.host}`;
    }
    return `https://${ctx.req.headers.host}`;

    // let protocol = ctx.req.headers['x-forwarded-proto'] || 'http';
    // return protocol + '://' + ctx.req.headers.host;
  }
  // browser
  return `${window.location.protocol}//${window.location.host}`;
}

export default getDomain;
