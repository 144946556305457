import { UserProvider } from "@auth0/nextjs-auth0/client";
import { CaptureConsole, ExtraErrorData, RewriteFrames } from "@sentry/integrations";
import * as Sentry from "@sentry/node";
import App, { AppContext, AppProps } from "next/app";
import getConfig from "next/config";
import Head from "next/head";
import { ThemeProvider } from "styled-components";
import Debugger from "~/components/Debugger";
import Layout from "~/components/Layout";
import { getShippingMethodsByCart } from "~/external/commercetools";
import { getNavigation } from "~/external/prismic";
import { withCtpApollo } from "~/lib/ctpApollo";
import { withCtpTokenInfo } from "~/lib/ctpTokenInfo";
import resolveCart from "~/lib/resolveCart";
import resolveCountryCode from "~/lib/resolveCountryCode";
import resolveCountryData from "~/lib/resolveCountryData";
import { wrapper } from "~/store";
import { setCart } from "~/store/cart";
import { setCountryData } from "~/store/countryData";
import { setNavigation } from "~/store/navigation";
import { setShippingMethods } from "~/store/shippingMethods";
import { setViewOnlyFalse } from "~/store/viewOnly";
import theme from "~/styles/theme";

const SHOW_DEBUG = false;

// setup custom stuff
require("../lib/setupLogging");
require("../lib/setupRouteEvents");

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
      new CaptureConsole({
        levels: ["error", "warn", "info"],
      }),
      new ExtraErrorData({ depth: 4 }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.SENTRY_ENV,
  });
}

function MyApp({ Component, pageProps, ...rest }: AppProps) {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Head>
          <title>AIAIAI Audio</title>
          <meta key="robots" name="robots" content="index, follow" />
          <link
            key="preload-fontface-style"
            rel="preload"
            href="/static/styles/fontface-aiaiai.css"
            as="style"
          />
          <link
            key="preload-fontface-v4-normal"
            rel="preload"
            href="/static/aiaiai-v4-webfonts/AIAIAIv4-Normal.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            key="preload-fontface-v4-bold"
            rel="preload"
            href="/static/aiaiai-v4-webfonts/AIAIAIv4-Bold.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            key="preload-fontface-v4-black"
            rel="preload"
            href="/static/aiaiai-v4-webfonts/AIAIAIv4-Black.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            key="preload-fontface-v4-bold-extended"
            rel="preload"
            href="/static/aiaiai-v4-webfonts/AIAIAIv4-BoldExtended.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            key="dns-prefetch-prismic-images"
            rel="dns-prefetch"
            href="https://images.prismic.io"
          />
          <link
            key="dns-prefetch-gtm"
            rel="dns-prefetch"
            href="https://www.googletagmanager.com"
          />
          <link
            key="preconnect-prismic-images"
            rel="preconnect"
            href="https://images.prismic.io"
          />
          <link
            key="preconnect-gtm"
            rel="preconnect"
            href="https://www.googletagmanager.com"
            crossOrigin="anonymous"
          />
          <link
            key="dns-prefetch-ga"
            rel="dns-prefetch"
            href="https://www.google-analytics.com"
          />
          <link
            key="dns-prefetch-facebook-connect"
            rel="dns-prefetch"
            href="https://connect.facebook.net"
          />
          <link
            key="dns-prefetch-ga-doubleclick"
            rel="dns-prefetch"
            href="https://stats.g.doubleclick.net"
          />
          <link
            key="dns-prefetch-facebook-track"
            rel="dns-prefetch"
            href="https://www.facebook.com"
          />
          <link
            key="dns-prefetch-ga-audiences"
            rel="dns-prefetch"
            href="https://www.google.com"
          />
          <link
            key="dns-prefetch-commercetools-auth"
            rel="dns-prefetch"
            href="https://api.sphere.io"
          />
          <link
            key="dns-prefetch-prismic-content"
            rel="dns-prefetch"
            href="https://aiaiai.cdn.prismic.io"
          />
          <link
            key="dns-prefetch-commercetools-api"
            rel="dns-prefetch"
            href="https://api.commercetools.com"
          />
          <link
            key="stylesheet"
            rel="stylesheet"
            type="text/css"
            href="/static/styles/fontface-aiaiai.css"
          />
          <link
            rel="stylesheet"
            href="/static/flags/css/flag-icons.min.css"
            media="print"
            // @ts-ignore
            onLoad="this.media='all'"
          />
        </Head>
        <Layout>
          <Component {...pageProps} />
          {SHOW_DEBUG && <Debugger {...rest} />}
        </Layout>
      </UserProvider>
    </ThemeProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { Component, ctx } = appContext;
  const { ctpCtx, store } = ctx;
  const initialProps = App.getInitialProps(appContext);

  // fetch menus from prismic, if they are not in store already...
  if (!store.getState().navigation.isLoaded) {
    // console.log('FETCH MENU');
    const mainDesktop = await getNavigation(ctx.req, "main-nav-desktop");
    const mainMobile = await getNavigation(ctx.req, "main-nav-mobile");
    const footer = await getNavigation(ctx.req, "footer-nav");
    const legal = await getNavigation(ctx.req, "legal-footer-nav");
    store.dispatch(
      setNavigation({
        mainDesktop,
        mainMobile,
        footer,
        legal,
      })
    );
  } else {
    // console.log('MENU ALREADY THERE ');
  }

  // get country code
  const countryCode = resolveCountryCode(ctx);
  ctx.countryCode = countryCode;
  // get country data
  const countryData = await resolveCountryData(countryCode, ctx);
  store.dispatch(setCountryData(countryData)); // set countryData in store
  ctx.countryData = countryData; // set coutnrydata in ctx

  // get pageprops from the current page
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
    // console.log(pageProps);
  }

  const resolveFullCart = pageProps.resolveFullCart ?? false;

  /* if (Component.getServerSideProps) {
        const { props: serverSideProps } = await Component.getServerSideProps();
        console.log(serverSideProps);
        resolveFullCart = serverSideProps.resolveFullCart ?? resolveFullCart;
      } */

  // console.log('resolveFullCart', resolveFullCart)

  // get cart
  const cart = await resolveCart(ctx, resolveFullCart);

  // we have a cart - update store and cookies!
  if (cart) {
    // set cart in store
    store.dispatch(setCart(cart));
    // set view only to false
    store.dispatch(setViewOnlyFalse());

    // get shippingRates and add to store
    const shippingMethods = await getShippingMethodsByCart(ctpCtx, cart.id);
    store.dispatch(setShippingMethods(shippingMethods));

    // TODO: Add to client-side as well
    if (cart.customerId || cart.anonymousId) {
      const user = {
        id: cart.customerId || cart.anonymousId,
        anonymous: !cart.customerId,
        country_code: countryCode,
      };
      if (cart.shippingAddress && cart.shippingAddress.email) {
        user.email = cart.shippingAddress.email;
      }
      Sentry.setUser(user);
    }
  }

  const debugProps = {
    isServer: !!ctx.req,
    debugCartId: cart ? cart.id : "N/A",
    debugAccessToken: ctpCtx.tokenInfo.access_token,
    debugExpiresAt: new Date(ctpCtx.tokenInfo.expires_at).toString(),
  };

  return {
    ...initialProps,
    pageProps,
    ...debugProps,
  };
};

export default withCtpTokenInfo(withCtpApollo(wrapper.withRedux(MyApp)));
