import styled from "styled-components";

const Label = styled.label`
  position: relative;
  /* display: ${(p) => (p.inline ? "inline-block" : "block")}; */
  color: ${(p) => p.color || p.theme.colors.dark};
  font-size: 1rem;
  /* height: 16px; */
  /* position: relative; */
  /* z-index: 1; */
`;

export default Label;
