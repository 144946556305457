import fetch from "cross-fetch";
import { defaultCountry } from "~/data/shippingCountries";
import cache from "~/lib/cache";
import getDomain from "~/lib/getDomain";
import { addToast } from "~/store/toasts";

// ctx can be undefined if client side
async function resolveCountryData(countryCode, ctx) {
  const value = cache.get(`country:${countryCode}`);
  if (value) return value;

  let countryData = null;
  try {
    if (countryCode !== "ww") {
      const domain = getDomain(ctx);
      countryData = await fetch(`${domain}/api/country-data/${countryCode}`);
      if (!countryData) {
        countryData = {
          ...defaultCountry,
          name: countryData.name,
          code: countryData.code,
        };
      } else {
        countryData = await countryData.json();
      }
    } else {
      // WW
      countryData = {
        ...defaultCountry,
        originalName: countryData.name,
      };
    }
  } catch (err) {
    console.error(err);
    countryData = defaultCountry;
  }
  countryData.shipping = countryData.shipping || { enabled: false };
  countryData.shipping.fields = countryData.shipping.fields || {
    state: { required: true },
  };
  countryData.shipping.fields.state = countryData.shipping.fields.state || {
    required: true,
  };

  if (countryData) {
    countryData.language = "en-US"; // just hardcode the language  (since we have none)
  }

  // console.log(countryData);
  if (countryData.code === "WW") {
    const state = ctx.store.getState();
    const alreadyShown = state.toasts.find((t) => t.uid === "ww");
    // check if we already have toast?
    if (!alreadyShown && ctx.pathname !== "/change-country") {
      ctx.store.dispatch(
        addToast({
          title: `Could not find country`,
          uid: "ww",
          unMountMs: false,
          text: "To continue shopping, please select your country.",
          backgroundColor: "tomato",
          color: "white",
          primaryButton: {
            text: `Select`,
            href: `/change-country`,
          },
        })
      );
    }
  } else {
    cache.set(`country:${countryCode}`, countryData);
  }

  return countryData;
}

export default resolveCountryData;
