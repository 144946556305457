/**
 * Action types
 */

export const actionTypes = {
  SET_TOPBAR_COLOR: "SET_TOPBAR_COLOR",
  CLEAR_TOPBAR_COLOR: "CLEAR_TOPBAR_COLOR",
  SET_TOPBAR_HIDDEN: "SET_TOPBAR_HIDDEN",
  SET_MAINNAV_HIDDEN: "SET_MAINNAV_HIDDEN",
  SET_BUY_BUTTON_VISIBLE: "SET_BUY_BUTTON_VISIBLE",
  // SET_USE_SHIPPING_AS_BILLING: 'SET_USE_SHIPPING_AS_BILLING',
  SET_ACTIVE_ANCHOR_ID: "SET_ACTIVE_ANCHOR_ID",
  CLEAR_ACTIVE_ANCHOR_ID: "CLEAR_ACTIVE_ANCHOR_ID",
  SET_RIBBON_ACTIVE: "SET_RIBBON_ACTIVE",
};

/**
 * Reducer
 */

const reducer = (
  state = {
    topBarColor: null,
    topBarBackgroundColor: null,
    topBarHidden: false,
    mainNavHidden: false,
    buyButtonVisible: false,
    minimalMenu: false,
    // useShippingAsBilling: true,
    activeAnchorId: null, // should be cleared on route change
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SET_TOPBAR_COLOR:
      return {
        ...state,
        topBarColor: action.payload.color,
        topBarBackgroundColor: action.payload.backgroundColor,
      };
    case actionTypes.CLEAR_TOPBAR_COLOR:
      return {
        ...state,
        topBarColor: null,
        topBarBackgroundColor: null,
      };
    // use SET_MAINNAV_HIDDEN instead!
    case actionTypes.SET_TOPBAR_HIDDEN:
      return {
        ...state,
        topBarHidden: action.payload.hidden,
      };
    case actionTypes.SET_MAINNAV_HIDDEN:
      return {
        ...state,
        mainNavHidden: action.payload.hidden,
      };
    case actionTypes.SET_BUY_BUTTON_VISIBLE:
      return {
        ...state,
        buyButtonVisible: action.payload.visible,
      };
    // case actionTypes.SET_USE_SHIPPING_AS_BILLING:
    //   return {
    //     ...state,
    //     useShippingAsBilling: action.payload.useShippingAsBilling
    //   };
    // ANCHOR
    case actionTypes.SET_ACTIVE_ANCHOR_ID:
      return {
        ...state,
        activeAnchorId: action.payload.id,
      };
    case actionTypes.CLEAR_ACTIVE_ANCHOR_ID:
      return {
        ...state,
        activeAnchorId: null,
      };
    case actionTypes.SET_RIBBON_ACTIVE:
      return {
        ...state,
        ribbonActive: action.payload.ribbon,
      };
    default:
      return state;
  }
};

export default reducer;

/**
 * Action creators
 */

export function setTopBarColor(backgroundColor, color) {
  // console.log('👌 setTopBarColor', backgroundColor, color);
  return {
    type: actionTypes.SET_TOPBAR_COLOR,
    payload: { backgroundColor, color },
  };
}

export function clearTopBarColor() {
  return {
    type: actionTypes.CLEAR_TOPBAR_COLOR,
  };
}

// use setMainNavHidden instead
export function setTopBarHidden(hidden) {
  return {
    type: actionTypes.SET_TOPBAR_HIDDEN,
    payload: { hidden },
  };
}

export function setMainNavHidden(hidden) {
  return {
    type: actionTypes.SET_MAINNAV_HIDDEN,
    payload: { hidden },
  };
}

export function setBuyButtonVisible(visible) {
  return {
    type: actionTypes.SET_BUY_BUTTON_VISIBLE,
    payload: { visible },
  };
}

// export function setUseShippingAsBilling(useShippingAsBilling) {
//   return {
//     type: actionTypes.SET_USE_SHIPPING_AS_BILLING,
//     payload: { useShippingAsBilling }
//   };
// }

export function setActiveAnchorId(id) {
  return {
    type: actionTypes.SET_ACTIVE_ANCHOR_ID,
    payload: { id },
  };
}

export function clearActiveAnchorId() {
  return {
    type: actionTypes.CLEAR_ACTIVE_ANCHOR_ID,
  };
}

export function setRibbonActive(active) {
  return {
    type: actionTypes.SET_RIBBON_ACTIVE,
    payload: { ribbon: active },
  };
}
