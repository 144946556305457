/* eslint-disable jsx-a11y/anchor-is-valid */
import { motion } from "framer-motion";
import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";
import Link from "~/components/Link";
import { hSpacing, vSpacing } from "~/styles/mixins";
import { rem, smallerThan } from "~/styles/theme";

const MobileMenuClose = styled(motion.div)`
  z-index: ${(props) => props.theme.z.topBar - 2};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: blue;
  opacity: 0.3; */
`;

const MobileMenuWrapper = styled(motion.div)<{ mobileMenuOpen: boolean }>`
  z-index: ${(props) => props.theme.z.topBar - 1};
  /* background-color: tomato; */
  position: fixed;
  /* top: ${(props) => props.theme.sizes.topBarMobileHeight}rem; */
  top: 0;
  left: 0;
  right: 0;
  height: 0; /* initial */
  padding-top: ${(props) => props.theme.sizes.topBarMobileHeight}rem;
  /* padding-bottom: ${(props) => props.theme.sizes.topBarMobileHeight}rem; */
  /* overflow: hidden; */
  overflow: ${(props) => (props.mobileMenuOpen ? "auto" : "hidden")};
  background-color: ${(props) => props.theme.colors.light};
  ${hSpacing};
  ${MobileMenuClose} {
    display: ${(props) => (props.mobileMenuOpen ? "block" : "none")};
  }
`;

export const MobileSubMenu = styled.div`
  padding-top: ${(props) => props.theme.sizes.vSpacing * 3}rem;
  ${hSpacing};
  margin-bottom: ${(props) => props.theme.sizes.vSpacing * 2}rem;
`;

export const MobileSubMenuTitle = styled(motion.div)`
  position: relative;
  z-index: ${(props) => props.theme.z.topBar - 1};
  ${vSpacing};
  font-size: ${rem("14px")};
  font-weight: bold;
  margin-bottom: ${(props) => props.theme.sizes.vSpacing * 2}rem;
`;

export const MobileSubMenuLinks = styled(motion.div)<{ cols?: number }>`
  position: relative;
  z-index: ${(props) => props.theme.z.topBar - 1};
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-template-columns: repeat(${(props) => props.cols || 3}, 1fr);
  padding-bottom: ${(props) => props.theme.sizes.vSpacing}rem;
  grid-gap: ${(props) => props.theme.sizes.hSpacing * 2}rem;
  ${(props) => smallerThan(props.theme.breakpoints.small)} {
    padding-bottom: ${(props) => props.theme.sizes.vSpacingSmall}rem;
    grid-gap: ${(props) => props.theme.sizes.hSpacingSmall * 2}rem;
  }
`;

const MobileSubMenuLinkWrapper = styled(motion.div)`
  /* ${hSpacing}; */
  font-size: ${rem("14px")};
  margin-bottom: ${(props) => props.theme.sizes.vSpacing}rem;
  > a {
    color: ${(props) => props.theme.colors.dark};
    text-decoration: none;
  }
  img {
    width: 100%;
    display: block;
    background-color: #ececec;
    margin-bottom: ${(props) => props.theme.sizes.vSpacingSmall}rem;
  }
`;

type MobileSubMenuLinkProps = {
  label: string;
  href?: string;
  as?: string;
  img: string;
};

export function MobileSubMenuLink({ label, href, as, img }: MobileSubMenuLinkProps) {
  const hrefAs = as || href;

  return (
    <MobileSubMenuLinkWrapper>
      <Link href={href} as={hrefAs}>
        <a>
          {img && <img src={img} alt="" />}
          <span>{label}</span>
        </a>
      </Link>
    </MobileSubMenuLinkWrapper>
  );
}

const mobileMenuWrapperVariants = {
  closed: {
    height: 0,
    transition: {
      staggerChildren: 0.08,
      delayChildren: 0.1,
      ease: [0.21, 0, 0.21, 0.99],
    },
  },
  open: {
    // height: 'auto',
    height: "100%",
    transition: {
      staggerChildren: 0.08,
      delayChildren: 0.1,
      ease: [0.21, 0, 0.21, 0.99],
    },
  },
};

type Props = {
  children: ReactNode;
  mobileMenuOpen?: boolean;
  mobileMenuClose?: () => void;
  style: CSSProperties;
};

function MobileMenu({
  children,
  mobileMenuOpen = false,
  mobileMenuClose = () => {},
  style,
}: Props) {
  return (
    <MobileMenuWrapper
      variants={mobileMenuWrapperVariants}
      animate={mobileMenuOpen ? "open" : "closed"}
      mobileMenuOpen={mobileMenuOpen}
      style={style}
    >
      {/* <MobileMenuClose onTap={mobileMenuClose} /> */}
      {children}
    </MobileMenuWrapper>
  );
}

export default MobileMenu;
