import { useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import PriceLine from "~/components/PriceLine";
import { getExpectedDeliveries } from "~/external/commercetools";
import useBuyButton from "~/hooks/useBuyButton";
import { CtpCtx } from "~/lib/ctpApollo";
import { ThemeContext } from "~/lib/ThemeContext";
import NotifyMeButton from "../NotifyMeButton";

function BuyButton(props) {
  const currentTheme = useContext(ThemeContext);
  const {
    variant,
    extras = [],
    buttonComponent,
    buttonProps = {},
    customButtonText,
    customOutOfStockText,
    includePrice = true,
    disabled = false,
    id,
    highlightColor,
    skipCrossSells = false,
    allowNotifyMe = false,
  } = props;

  const ctpCtx = useContext(CtpCtx);

  const countryData = useSelector((s) => s.countryData);

  const Comp = buttonComponent || ((props) => <button>{props.children}</button>);

  const [currentVariant, setCurrentVariant] = useState(variant);
  const [buttonText, setButtonText] = useState(`Not available`);
  const [available, setAvailable] = useState(false);
  const [reseller, setReseller] = useState(false);
  const [notifyMe, setNotifyMe] = useState(false);

  // const variant = product ? product.variants[product.masterVariantIndex] : null;
  // buyButtonProps will be {} if variant is not truthy
  const buyButtonProps = useBuyButton(variant, extras, skipCrossSells);

  // TODO: Use lazy prop?
  const [visible, setVisible] = useState(false);
  const [loaded, setLoaded] = useState(false);

  // trigger 200px before enter screen...
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: "200px 0px",
  });

  const updatePurchaseInformation = async () => {
    if (variant && countryData.shipping.enabled) {
      // Disable HK warehouse shipping for parts and accessories
      if (
        countryData.channelKey === "hk" &&
        !variant.bundle &&
        variant.productTypeKey != "studio-monitor"
      ) {
        setButtonText(`Available at resellers`);
        setAvailable(true);
        setReseller(true);
        setLoaded(true);
        return;
      }

      let skus = variant.bundle
        ? variant.bundledProductsSkus
        : [
            ...(variant.productTypeKey === "parts-bundle"
              ? variant.parts.map((part) => part.variants[part.masterVariantIndex].sku)
              : [variant.sku]),
          ];

      // check availabilty - product cache might be stale...
      // this _should_ also update the availibity in cache ??

      skus = [
        ...skus,
        ...extras.map((extra) => extra.variants[extra.masterVariantIndex].sku),
      ];

      // TODO: Rename function - has more inventory details
      const inventory = await getExpectedDeliveries(
        ctpCtx,
        skus,
        countryData.channel, // channel is only available if shipping is enabled for the country
        "network-only"
      );

      const availability = Math.min(
        ...inventory.map((entry) => entry.availableQuantity)
      );

      // Check pre-order status
      let preorder = false;
      if (variant.bundle) {
        for (const bundlePart of variant.bundledProducts) {
          for (const bundlePartVariant of bundlePart.variants) {
            if (bundlePartVariant.attributes.preorder) {
              preorder = true;
            }
          }
        }
      } else if (variant.attributes.preorder) {
        preorder = true;
      }

      // Check notifyme status
      if (variant.bundle) {
        for (const bundlePart of variant.bundledProducts) {
          for (const bundlePartVariant of bundlePart.variants) {
            if (bundlePartVariant.attributes["notify-me"]) {
              setNotifyMe(true);
            }
          }
        }
      } else if (variant.attributes["notify-me"]) {
        setNotifyMe(true);
      }

      // check if available
      if (availability > 0) {
        // defeault text if none is provided...
        setButtonText(customButtonText || (preorder && "Pre-order") || `Add to cart`);
        setAvailable(true);
      } else {
        // still enforce custom text
        setButtonText(customButtonText || customOutOfStockText || `Out of stock`);
        setAvailable(false);
      }
      // if we want to include the price in the button too
      // if (available && includePrice) {
      //   buttonText += ` · ${variant.price.currencyCode} ${variant.price
      //     .centAmount / 100}`;
      // }
    } else if (
      !countryData.shipping.enabled &&
      countryData.resellers &&
      countryData.resellers.length > 0
    ) {
      // setButtonText(`Available at ${countryData.resellers[0].name}`);
      setButtonText(`Available at resellers`);
      setAvailable(true);
      setReseller(true);
    } else if (!countryData.shipping.enabled) {
      setButtonText(`Not available in ${countryData.name}`);
      setAvailable(false);
    }

    setLoaded(true);
  };

  // setup a listener
  // when the ref goes into view
  useEffect(() => {
    // TODO: if (!lazy) return;
    // load purchase information if it is in view and not loaded already...
    if (inView && !visible) {
      setVisible(true);
      updatePurchaseInformation();
    } else if (
      (variant.bundle ? variant.bundledProductsSkus : [variant.sku])
        .sort((a, b) => a.localeCompare(b))
        .join("+") !==
      (currentVariant.bundle
        ? currentVariant.bundledProductsSkus
        : [currentVariant.sku]
      )
        .sort((a, b) => a.localeCompare(b))
        .join("+")
    ) {
      setCurrentVariant(variant);
      updatePurchaseInformation();
    }
  }, [inView, visible, loaded, variant]);

  if (allowNotifyMe && notifyMe && !available && !reseller) {
    return <NotifyMeButton variant={variant} />;
  }

  return (
    <Comp
      ref={ref}
      {...buyButtonProps}
      onClick={
        reseller
          ? () => {
              window.location.href = `/resellers#${countryData.code}`;
            }
          : buyButtonProps.onClick
      }
      id={id}
      color={currentTheme.button.foreground}
      backgroundColor={currentTheme.button.background}
      {...buttonProps}
      title={reseller ? `Go to resellers` : buttonProps.title || ""}
      disabled={!loaded || disabled || !available}
    >
      {!loaded ? (
        <>Loading...</>
      ) : (
        <>
          {buttonText}
          {variant && includePrice && !reseller && (
            <>
              {" "}
              ·{" "}
              <PriceLine
                price={variant.price}
                extras={extras.map(
                  (extra) => extra.variants[extra.masterVariantIndex].price
                )}
                highlightColor={highlightColor}
              />
            </>
          )}
        </>
      )}
    </Comp>
  );
}

export default BuyButton;
