import { useState } from "react";

function useFormInput<T = any>(initialValue: T) {
  const [value, setValue] = useState<T>(initialValue);

  function onChange(e) {
    if (e.target.type === "checkbox") {
      setValue(e.target.checked);
    } else {
      setValue(e.target.value as T);
    }
  }

  return {
    value,
    onChange,
  };
}

export default useFormInput;
