import "normalize.css";
import { ReactNode } from "react";
import styled, { createGlobalStyle } from "styled-components";
import Footer from "~/components/Footer";
import MainNav from "~/components/MainNav";
import Toasts from "~/components/Toasts";
import theme, { smallerThan } from "~/styles/theme";

const GlobalStyle = createGlobalStyle`
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    /*text-rendering: optimizeSpeed;*/
    line-height: 1.6;
    margin: 0;
    font-family: 'AIAIAI';
    /* font-family: 'Relative'; */
    background-color: ${(props) => props.theme.colors.light};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body,
  html{
    font-size: 16px;
    /* ${(props) => smallerThan(props.theme.breakpoints.ipadPortrait)}{
      font-size: 14px;
    } */
  }

  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${(props) => props.theme.colors.primaryDark};
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
  }

  ::selection {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.dark};
  }

  body.fullscreen-toast {
    .hide-if-fullscreen {
      display: none;
    }
  }

  .flag-icons {
    height: 0.9em !important;
    line-height: 0.9em !important;
  }

`;

const Wrapper = styled.div`
  min-height: 80vh;
  /* transition: opacity 0.5s; */

  /*
  .route-change & {
    opacity: 0;
    transition-duration: 0s;
  }
  */
`;

type Props = {
  children: ReactNode;
};

export default function Layout({ children }: Props) {
  return (
    <>
      <Toasts />
      <GlobalStyle />
      <MainNav
        // backgroundColor={theme.colors.light}
        backgroundColor="transparent"
        color={theme.colors.dark}
      />
      <Wrapper className="layout">{children}</Wrapper>
      <Footer />
    </>
  );
}
