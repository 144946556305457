import gql from "graphql-tag";

/*
  Fragments
 */

export const fragments = {};

/**
 * IMPORTA REGARDING MYCART
 * MyCart is a Cart with less write priv's, which is more secure when we call directly
 */

/*
 // does not work...
fragments.MoneyFormat = gql`
  fragment MoneyFormat on Money {
    centAmount
    fractionDigits
    currencyCode
  }
`;
*/

// NOT USED
// Deprecated - uses customFields
fragments.LineItemInfo = gql`
  fragment LineItemInfo on LineItem {
    id
    name(locale: "en-us")
    productSlug(locale: "en-us")
    quantity
    price {
      value {
        centAmount
        fractionDigits
        currencyCode
      }
      discounted {
        value {
          centAmount
          fractionDigits
          currencyCode
        }
      }
    }
    totalPrice {
      centAmount
      fractionDigits
      currencyCode
    }
    variant {
      sku
    }
    customFields {
      ... on BundledLineItemType {
        BundleId {
          value
        }
      }
    }
  }
`;

// NOT USED
// throws error...
/*
fragments.CustomLineItemInfo = gql`
  fragment CustomLineItemInfo on CustomLineItem {
    id
    name(locale: "en-us")
    slug
    quantity
    totalPrice {
      centAmount
      fractionDigits
      currencyCode
    }
    customFields {
      ... on BundleLineItemType {
        BundleId {
          value
        }
        BundleType {
          value
        }
        BundleProduct {
          id
        }
      }
    }
  }
`;
*/

fragments.CartData = gql`
  fragment CartData on Cart {
    id
    customerId
    anonymousId
    createdAt
    version
    country
    lineItems {
      id
      name(locale: "en-us")
      productSlug(locale: "en-us")
      productId
      quantity
      price {
        value {
          centAmount
          fractionDigits
          currencyCode
        }
        discounted {
          value {
            centAmount
            fractionDigits
            currencyCode
          }
        }
      }
      totalPrice {
        centAmount
        fractionDigits
        currencyCode
      }
      taxedPrice {
        totalNet {
          centAmount
          fractionDigits
          currencyCode
        }
        totalGross {
          centAmount
          fractionDigits
          currencyCode
        }
      }
      variant {
        sku
        images {
          url
          # dimensions
          label
        }
      }
      custom {
        customFieldsRaw {
          name
          value
          #... on BundledLineItemType {
          #  BundleId {
          #    value
          #  }
          #}
        }
      }
      lineItemMode
      distributionChannel {
        id
      }
      supplyChannel {
        id
      }
    }
    ## don't need these right now since they get removed
    # customFields {
    #   ... on AddBundleType {
    #     BundleUri {
    #       value
    #     }
    #   }
    # }
    customLineItems {
      id
      name(locale: "en-us")
      slug
      quantity
      totalPrice {
        centAmount
        fractionDigits
        currencyCode
      }
      custom {
        customFieldsRaw {
          name
          value
          #... on BundleLineItemType {
          #  BundleId {
          #    value
          #  }
          #  BundleType {
          #    value
          #  }
          #  BundleProduct {
          #    id
          #  }
          #}
        }
      }
    }
    totalPrice {
      centAmount
      fractionDigits
      currencyCode
    }
    taxedPrice {
      totalNet {
        centAmount
        fractionDigits
        currencyCode
      }
      totalGross {
        centAmount
        fractionDigits
        currencyCode
      }
      taxPortions {
        rate
        name
        amount {
          centAmount
          fractionDigits
          currencyCode
        }
      }
    }
    shippingAddress {
      ...AddressFields
    }
    billingAddress {
      ...AddressFields
    }
    cartState
    discountCodes {
      state
      discountCodeRef {
        typeId
        id
      }
      discountCode {
        id
        code
        cartDiscounts {
          key
          value {
            ... on RelativeDiscountValue {
              type
              permyriad
            }
            ... on AbsoluteDiscountValue {
              type
              money {
                centAmount
                fractionDigits
                currencyCode
              }
            }
          }
        }
      }
    }
    paymentInfo {
      payments {
        id
      }
    }
    shippingInfo {
      shippingMethodName
      shippingMethod {
        key
      }
      taxedPrice {
        totalNet {
          centAmount
          fractionDigits
          currencyCode
        }
        totalGross {
          centAmount
          fractionDigits
          currencyCode
        }
      }
      discountedPrice {
        value {
          centAmount
          fractionDigits
          currencyCode
        }
      }
    }
  }
`;

fragments.AddressFields = gql`
  fragment AddressFields on Address {
    firstName
    lastName
    company
    streetName
    streetNumber
    additionalStreetInfo
    postalCode
    city
    region
    state
    country
    phone
    email
  }
`;

/*

You are using the simple (heuristic) fragment matcher, but your queries contain union or interface types. Apollo Client will not be able to accurately map fragments. To make this error go away, use the `IntrospectionFragmentMatcher` as described in the docs: https://www.apollographql.com/docs/react/advanced/fragments.html#fragment-matcher
WARNING: heuristic fragment matching going on!

*/

fragments.VariantInfo = gql`
  fragment VariantInfo on ProductVariant {
    sku
    key
    images {
      url
      dimensions {
        width
        height
      }
      label
    }
    attributesRaw {
      name
      value
    }
    # we cannot do this, as we get herousitc fragment matching....
    # attributes {
    #   ... on Speaker_unitsProductType {
    #     tagline {
    #       value(locale: $locale)
    #     }
    #   }
    #   ... on EarpadsProductType {
    #     tagline {
    #       value(locale: $locale)
    #     }
    #   }
    #   ... on HeadbandProductType {
    #     tagline {
    #       value(locale: $locale)
    #     }
    #   }
    #   ... on CableProductType {
    #     tagline {
    #       value(locale: $locale)
    #     }
    #   }
    # }
    price(currency: $currency) {
      value {
        centAmount
        fractionDigits
        currencyCode
      }
      discounted {
        value {
          centAmount
          currencyCode
        }
        discount {
          name(locale: $locale)
        }
      }
    }
    availability {
      channels(includeChannelIds: $channel) {
        results {
          channelRef {
            id
          }
          availability {
            restockableInDays
            availableQuantity
          }
        }
      }
    }
  }
`;

fragments.ProductInfo = gql`
  fragment ProductInfo on Product {
    id
    key
    taxCategory {
      name
    }
    productType {
      key
      name
      description
    }
    skus
    masterData {
      current {
        name(locale: $locale)
        description(locale: $locale)
        categories {
          id
          key
        }
        masterVariant {
          ...VariantInfo
        }
        variants {
          ...VariantInfo
        }
        allVariants {
          attributesRaw {
            name
            value
          }
        }
      }
    }
  }
  ${fragments.VariantInfo}
`;

fragments.OrderData = gql`
  fragment OrderData on Order {
    id
    version
    orderNumber
    orderState
    paymentState
    shipmentState
    custom {
      # will be null, if no custom fields...
      type {
        key
      }
    }
  }
`;

fragments.shippingMethodInfo = gql`
  fragment ShippingMethodInfo on ShippingMethod {
    id
    key
    name
    localizedDescription(locale: "en-us")
    isDefault
    zoneRates {
      zone {
        name
        locations {
          country
          state
        }
      }
      shippingRates {
        isMatching
        freeAbove {
          centAmount
          currencyCode
          fractionDigits
        }
        price {
          centAmount
          currencyCode
          fractionDigits
        }
      }
    }
  }
`;

/*
  Queries
 */

/*
${fragments.VariantInfo}
  ${fragments.ProductInfo}
*/

/*
// not used
export const GET_PRODUCT_INFO = gql`
  query FetchProduct($sku: String, $currency: Currency!, $channel: [String!]) {
    product(sku: $sku) {
      ...ProductInfo
    }
  }
`;
*/

// $locale: Locale = "en-us"

export const GET_PRODUCTS_INFO = gql`
  query FetchProducts(
    $where: String
    $skus: [String!]
    $currency: Currency!
    $channel: [String!]
    $locale: Locale = "en-us"
  ) {
    products(skus: $skus, where: $where, limit: 100) {
      results {
        ...ProductInfo
      }
    }
  }
  ${fragments.ProductInfo}
`;

export const GET_PRODUCTS_INFO_EXTENDED = gql`
  query FetchProducts(
    $whereProduct: String
    # $whereInventory: String!
    $skus: [String!]
    $currency: Currency!
    $channel: [String!]
    $locale: Locale = "en-us"
  ) {
    products(skus: $skus, where: $whereProduct, limit: 100) {
      results {
        ...ProductInfo
      }
    }
    #inventoryEntries(where: $whereInventory) {
    #  results {
    #    sku
    #    supplyChannel {
    #      id
    #      key
    #    }
    #    expectedDelivery
    #  }
    #}
  }
  ${fragments.ProductInfo}
`;

/*
export const GET_PRODUCT_AVAILABILITY = gql`
  query GetProducts($skus: [String!], $channels: [String!]) {
    products(skus: $skus) {
      results {
        masterData {
          current {
            masterVariant {
              availability {
                channels(includeChannelIds: $channels) {
                  results {
                    channel {
                      id
                      key
                    }
                    availability {
                      availableQuantity
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
*/

export const GET_PRODUCT_AVAILABILITY = gql`
  query GetProducts($skus: [String!], $channels: [String!]) {
    products(skus: $skus) {
      results {
        masterData {
          current {
            # allVariants makes it so we can query skus that might not exist on that product...
            allVariants(skus: $skus) {
              availability {
                channels(includeChannelIds: $channels) {
                  results {
                    availability {
                      availableQuantity
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// DEPRIACTED
/*
export const GET_CART = gql`
  query GetCart($id: String!) {
    cart(id: $id) {
      ...CartData
    }
  }
  ${fragments.AddressFields}
  ${fragments.CartData}
`;
*/

export const GET_MY_CART = gql`
  query GetMyCart($id: String!) {
    me {
      cart(id: $id) {
        ...CartData
      }
    }
  }
  ${fragments.AddressFields}
  ${fragments.CartData}
`;

export const GET_MY_ACTIVE_CART = gql`
  query {
    me {
      activeCart {
        ...CartData
      }
    }
  }
  ${fragments.AddressFields}
  ${fragments.CartData}
`;

export const GET_CATEGORY_PRODUCTS = gql`
  query GetCategoryProduct($where: String) {
    products(where: $where) {
      results {
        masterData {
          current {
            ...VariantInfo
          }
        }
      }
    }
  }
  ${fragments.VariantInfo}
`;

export const GET_PRODUCT_TYPE = gql`
  query GetProductTypeId($where: String) {
    productTypes(where: $where) {
      results {
        id
      }
    }
  }
`;

// Deprecated - using attributes
export const GET_PRESETS_INFO = gql`
  query GetPresetsInfo($where: String) {
    products(where: $where) {
      results {
        masterData {
          current {
            name(locale: "en-us")
            slug(locale: "en-us")
            masterVariant {
              sku
              attributes {
                ... on Tma2PresetProductType {
                  Parts {
                    id
                    # typeId
                  }
                  AdditionalParts {
                    id
                  }
                  TopBox {
                    id
                  }
                  BottomBox {
                    id
                  }
                  IsHeroPreset {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query GetCategory($where: String!, $locale: Locale = "en-us") {
    categories(where: $where) {
      results {
        id
        key
        name(locale: $locale)
        children {
          key
          name(locale: $locale)
        }
      }
    }
  }
`;

export const GET_INVENTORY_ENTRY = gql`
  query getExpectedDelivery($where: String) {
    inventoryEntries(where: $where) {
      results {
        expectedDelivery
      }
    }
  }
`;

export const GET_INVENTORY_ENTRIES = gql`
  query getExpectedDeliveries($where: String) {
    inventoryEntries(where: $where) {
      results {
        sku
        supplyChannel {
          id
          key
        }
        restockableInDays
        quantityOnStock
        availableQuantity
        expectedDelivery
      }
    }
  }
`;

/*
export const GET_PRESET_PRODUCTS = gql`
  query GetPresetProducts($where) {
    products(where: "productType(id=\"Tma2Preset\")") {
      results {
        masterData {
          current {
            name(locale: "en-us")
            masterVariant {
              sku
            }
          }
        }
      }
    }
  }
`;
*/

export const GET_SHIPPING_METHODS = gql`
  query GetShippingMethods {
    shippingMethods {
      results {
        ...ShippingMethodInfo
      }
    }
  }
  ${fragments.shippingMethodInfo}
`;

export const GET_SHIPPING_METHODS_BY_CART = gql`
  query GetShippingMethodsByCart($id: String!) {
    shippingMethodsByCart(id: $id) {
      ...ShippingMethodInfo
    }
  }
  ${fragments.shippingMethodInfo}
`;

export const GET_CHANNELS = gql`
  query GetChannels {
    channels {
      results {
        id
        key
      }
    }
  }
`;

// NEED scope get orders :/
export const GET_ZONES = gql`
  query GetZones {
    zones {
      results {
        id
        key
        locations {
          country
          state
        }
      }
    }
  }
`;

/*
  {
    "where": "masterData(current(categories(id=\"45b8df47-80bd-483a-9848-a733ae425216\")))"
  }
*/

/*
  Mutations
 */

export const CREATE_CART = gql`
  mutation CreateCart($draft: CartDraft!) {
    createCart(draft: $draft) {
      ...CartData
    }
  }
  ${fragments.AddressFields}
  ${fragments.CartData}
`;

export const CREATE_MY_CART = gql`
  mutation CreateMyCart($draft: MyCartDraft!, $storeKey: KeyReferenceInput) {
    createMyCart(draft: $draft, storeKey: $storeKey) {
      ...CartData
    }
  }
  ${fragments.AddressFields}
  ${fragments.CartData}
`;

// use updateMyCart
// for docs, search CartUpdateAction
export const UPDATE_CART = gql`
  mutation UpdateCart($actions: [CartUpdateAction!]!, $id: String!, $version: Long!) {
    updateCart(id: $id, version: $version, actions: $actions) {
      ...CartData
    }
  }
  ${fragments.AddressFields}
  ${fragments.CartData}
`;

// for docs, search CartUpdateAction
export const UPDATE_MY_CART = gql`
  mutation UpdateMyCart(
    $actions: [MyCartUpdateAction!]!
    $id: String!
    $version: Long!
  ) {
    updateMyCart(id: $id, version: $version, actions: $actions) {
      ...CartData
    }
  }
  ${fragments.AddressFields}
  ${fragments.CartData}
`;

export const CREATE_ORDER = gql`
  mutation CreateOrder($draft: OrderCartCommand!) {
    createOrderFromCart(draft: $draft) {
      ...OrderData
    }
  }
  ${fragments.OrderData}
`;

export const UPDATE_ORDER = gql`
  mutation UpdateOrder($actions: [OrderUpdateAction!]!, $id: String!, $version: Long!) {
    updateOrder(id: $id, version: $version, actions: $actions) {
      ...OrderData
    }
  }
  ${fragments.OrderData}
`;
