import { useState } from "react";

function useDropdownImage(clearState = true) {
  const [dropdownImage, setDropdownImage] = useState();

  function hoverEvents(url) {
    function onHoverStart() {
      setDropdownImage(url);
    }
    function onHoverEnd() {
      // set back to no image...
      if (clearState) setDropdownImage(null);
    }
    return { onHoverStart, onHoverEnd };
  }

  return { dropdownImage, hoverEvents };
}

export default useDropdownImage;
