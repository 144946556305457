import styled from "styled-components";

const Spacer = styled.div<any>`
  position: relative;
  width: 100%;
  height: ${(p) => p.theme.sizes.vSpacing * (p.size || 1)}rem;
  background-color: ${(p) => p.backgroundColor || p.theme.colors.light};
  z-index: ${(p) => p.theme.z.body};
`;

export default Spacer;
