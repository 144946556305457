import styled from "styled-components";

const Label = styled.label<any>`
  display: ${(p) => (p.inline ? "inline-block" : "block")};
  color: ${(p) => p.theme.colors.dark};
  font-size: 1rem;
  height: 16px;
  position: relative;
  z-index: 1;
`;

export default Label;
