import Router from "next/router";
import { useEffect } from "react";

function useRouterEvent(eventName, handleEvent) {
  useEffect(() => {
    Router.events.on(eventName, handleEvent);
    return () => {
      Router.events.off(eventName, handleEvent);
    };
  }, []);
}

export default useRouterEvent;
