import Router from "next/router";
import NProgress from "nprogress";

// disable scroll resrotaion
// this will cause a "jump" whenever the back button is pressed :/
if (typeof history !== "undefined") {
  history.scrollRestoration = "manual";
}

// Router.events.on('beforeHistoryChange', path => {
//   console.log('BEFORE HISTORY CHANGE', window.location.pathname, path);
// });

Router.events.on("routeChangeStart", (path) => {
  // console.log(`Loading: ${path}`);
  NProgress.start();
  document.body.classList.add("route-change");

  /*

  if (typeof window !== 'undefined') {
    // this is the page we are navigating _from_
    window.prevUrl = window.location.pathname;

    // make an object to store previous scroll positions indexed by page
    if (typeof window.lastScrollPositions === 'undefined') {
      window.lastScrollPositions = {};
    }
    // record scroll position for page we are navigtin from
    window.lastScrollPositions[window.prevUrl] =
      window.pageYOffset || document.documentElement.scrollTop;
      
  } */
});
Router.events.on("routeChangeComplete", (path) => {
  // on all roues, except tma-2 builder we want to attempt some scroll restoration
  if (!path.includes("tma-2-build-your-own")) {
    const scrollTo = 0;

    /*
    // when going back, attempt to recover scroll, if it's there??
    if (window.prevUrl === path) {
      // console.log('GOING BACK!!');
      if (window.lastScrollPositions?.[path] ?? false) {
        scrollTo = window.lastScrollPositions[path] || 0;
        delete window.lastScrollPositions[path];
        console.log('Restore last scroll', scrollTo);
      }
    }
    */

    window.scrollTo(0, scrollTo); // manual scroll restoration, aka. WDGAF
  }
  //
  NProgress.done();
  document.body.classList.remove("route-change");
});
Router.events.on("routeChangeError", () => NProgress.done());
