import { css, keyframes } from "styled-components";
import { rem, smallerThan } from "~/styles/theme";

export const paragraph = (p) => `
  line-height: 1.6;
  padding: 0;
  margin: 0;
  font-size: ${rem("16px")};
  ${smallerThan(p.theme.breakpoints.small)}{
    font-size: ${rem("14px")};
  }
`;

export const ul = (p) => `
  margin:0;
  padding:0;
  padding-left: 1.3rem;
  line-height: 1.6;
  font-size: ${rem("16px")};
  ${smallerThan(p.theme.breakpoints.small)}{
    font-size: ${rem("14px")};
  }
`;

/**
 * horizontal spacing, aka. left and right
 * @param {*} p
 */
export const hSpacing = (p) => `
  padding-left: ${p.noSpacing || p.noSpacingLeft ? 0 : p.theme.sizes.hSpacing}rem;
  padding-right: ${p.noSpacing || p.noSpacingRight ? 0 : p.theme.sizes.hSpacing}rem;
  ${smallerThan(p.theme.breakpoints.small)}{
    padding-left: ${
      p.noSpacing || p.noSpacingLeft ? 0 : p.theme.sizes.hSpacingSmall
    }rem;
    padding-right: ${
      p.noSpacing || p.noSpacingRight ? 0 : p.theme.sizes.hSpacingSmall
    }rem;
  }
`;

/**
 * vertical spacing, aka. top and bottom
 * @param {*} p
 */
export const vSpacing = (p) => `
  padding-top: ${p.noSpacing || p.noSpacingTop ? 0 : p.theme.sizes.vSpacing}rem;
  padding-bottom: ${p.noSpacing || p.noSpacingBottom ? 0 : p.theme.sizes.vSpacing}rem;
  ${smallerThan(p.theme.breakpoints.small)}{
    padding-top: ${p.noSpacing || p.noSpacingTop ? 0 : p.theme.sizes.vSpacingSmall}rem;
    padding-bottom: ${
      p.noSpacing || p.noSpacingBottom ? 0 : p.theme.sizes.vSpacingSmall
    }rem;
  }
`;

/**
 * spacing for blocks (top and bottom)
 * @param {*} p
 */
export const vBlockSpacing = (p) => `
  padding-top: ${p.spacingTop * p.theme.sizes.vSpacing}rem;
  padding-bottom: ${p.spacingBottom * p.theme.sizes.vSpacing}rem;
  ${smallerThan(p.theme.breakpoints.small)}{
    padding-top: ${(p.spacingTop / 2) * p.theme.sizes.vSpacingSmall}rem;
    padding-bottom: ${(p.spacingBottom / 2) * p.theme.sizes.vSpacingSmall}rem;
  }

`;

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const spinner = (p) => css`
  &:after {
    animation: ${rotate} 0.7s infinite linear;
    border: 0.1rem solid ${p.color};
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 1rem;
    left: 50%;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
    position: absolute;
    top: 50%;
    width: 1rem;
    z-index: 1;
  }
`;

export const vScroll = (p) => css`
  width: 100%;
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
`;

/*
    p.noSpacing || p.noSpacingTop ? 0 : p.theme.sizes.vBlockSpacing
  }rem;
  padding-bottom: ${
    p.noSpacing || p.noSpacingBottom ? 0 : p.theme.sizes.vBlockSpacing
  }rem;
  ${smallerThan(p.theme.breakpoints.small)}{
    padding-top: ${
      p.noSpacing || p.noSpacingTop ? 0 : p.theme.sizes.vBlockSpacingSmall
    }rem;
    padding-bottom: ${
      p.noSpacing || p.noSpacingBottom ? 0 : p.theme.sizes.vBlockSpacingSmall
    }rem;
  }
  */
