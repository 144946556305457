import { useEffect, useState } from "react";

function useFormInput(initialValue, regex) {
  const [value, setValue] = useState(initialValue);
  const [dirty, setDirty] = useState(false);
  const [valid, setValidity] = useState(false); // always start as invalid?

  function validate(regex, value) {
    const validity = regex.test(value);
    if (validity !== valid) setValidity(validity);
  }

  useEffect(() => {
    // run validate...
    if (regex) {
      validate(regex, value);
      // const validity = validate(value);
      // if (validity !== valid) setValidity(validity);
    }
  });

  function onChange(e) {
    // if (!dirty) setDirty(true);

    if (e.target.type === "checkbox") {
      setValue(e.target.checked);
    } else {
      if (regex) {
        // const validity = validate(e.target.value);
        // if (validity !== valid) setValidity(validity);
        validate(regex, e.target.value);
      }

      setValue(e.target.value);
    }
  }

  function onBlur(e) {
    if (!dirty) setDirty(true);
  }

  return {
    value,
    onChange,
    onBlur,
    valid,
    setValidity,
    dirty,
  };
}

export default useFormInput;
