// just make a variant (master or not) into a nice variant array based on the type..
function resolveVariant(pr, v, countryData) {
  try {
    // console.log('resolveVariant', v);

    const { availableQuantity, restockableInDays } =
      v.availability.channels.results.length > 0
        ? v.availability.channels.results[0].availability
        : { availableQuantity: 0, restockableInDays: 0 };

    // console.log(v.availability.channels.results[0]);

    // console.log(v.availability.channels.results[0].channelRef.id);

    /* const expectedDelivery = await getExpectedDelivery(
      ctpCtx,
      v.sku,
      v.availability.channels.results[0].channelRef.id
    ); */

    // console.log(expectedDelivery);

    /* results {
      channelRef {
        id */

    /* const availability = v.availability.channels.results.find(
      a => a.channelRef.id == countryData.channel
    );

    console.log('availability', availability); */

    // console.log('PR', pr);

    const attributes = {};
    v.attributesRaw.forEach(({ name, value }) => (attributes[name] = value));

    const language = countryData.language || "en-US";

    const variant = {
      sku: v.sku,
      // id: pr.id, // shouldnt it be v?
      id: v.id,
      key: v.key,
      productId: pr.id,
      productKey: pr.key,
      productTypeKey: pr.productType ? pr.productType.key : null,
      name: pr.masterData.current.name,
      description: pr.masterData.current.description,
      attributesRaw: v.attributesRaw,
      attributes,
      availability: {
        availableQuantity,
        restockableInDays,
        expectedDelivery: null, // Will be fetched lazy
      },
      // price: v.price.value,
      price: v.price, // <-- also inlcudes discounts!
      images: v.images,
      tagline: attributes?.tagline?.[language],
      color: attributes?.colorSlug
        ? {
            slug: attributes?.colorSlug,
            name: attributes?.colorName?.[language],
          }
        : undefined,
      size: attributes?.size,
      connector: attributes?.variant__connector,
      powerPlugType:
        v.attributesRaw.find(({ name }) => name === "core__power-plug-type")?.value ||
        null,
      parts: v.parts ?? [],
    };

    // add the "tagline" custom attribute directly to the variant
    // const taglineAttribute = v.attributesRaw.find(
    //   ({ name }) => name === 'tagline'
    // );
    // if (taglineAttribute) {
    // if (attributes.tagline) {
    //   variant.tagline = attributes.tagline[language]; // localized
    // }

    // if (attributes.colorSlug) {
    //   variant.colorSlug = attributes.colorSlug;
    // }

    // if (attributes.colorName) {
    //   variant.colorSlug = attributes.colorName[language]; // localized
    // }

    // Attempt to fetch a tagline (For parts)
    /*
  if (v.attributes) {
    if (v.attributes.tagline) {
      variant.tagline = v.attributes.tagline.value;
    }
  }
  */

    return variant;
  } catch (e) {
    console.log("ERR in resolveVariant:", e.toString());
    return null;
  }
}

export default resolveVariant;
