import styled from "styled-components";
import useTopBarColors from "~/hooks/useTopBarColors";
import { hSpacing, vBlockSpacing, vSpacing } from "~/styles/mixins";
import theme, { smallerThan } from "~/styles/theme";
import BlockSliderComp from "./BlockSlider";

// alias?
export const BlockSlider = BlockSliderComp;

export const BlockWrapper = styled.div<any>`
  position: relative;
  width: 100%;
  color: ${(p) => p.color};
  background-color: ${(p) => p.backgroundColor || "transparent"};
  z-index: ${(p) => p.zIndex || "auto"};
  /* z-index: ${(p) => p.zIndex || p.theme.z.body}; */

  > div {
    /* background-color: ${(p) => p.backgroundColor || "transparent"}; */
    ${hSpacing};
    ${vBlockSpacing};
    /* overflow: hidden; */
  }
  .block-img img {
    max-width: 100%;
  }
`;

export const BlockContent = styled.div<any>`
  position: relative;
  ${hSpacing};
`;

export const BlockGrid = styled.div<any>`
  ${vSpacing};
  display: grid;
  grid-template-columns: repeat(${(p) => p.cols || "4"}, 1fr);
  grid-gap: ${(p) => p.theme.sizes.hSpacing * 8}rem
    ${(p) => p.theme.sizes.hSpacing * 2}rem;

  /* grid-gap: ${(p) => p.theme.sizes.hSpacing * 8}rem; */

  ${(p) => smallerThan(p.theme.breakpoints.small)} {
    /* grid-gap: ${(p) => p.theme.sizes.hSpacingSmall * 8}rem; */
    grid-gap: ${(p) => p.theme.sizes.hSpacingSmall * 8}rem
      ${(p) => p.theme.sizes.hSpacingSmall * 2}rem;
  }

  ${(p) => smallerThan(p.theme.breakpoints.ipadLandscape)} {
    grid-template-columns: repeat(${(p) => (p.cols < 3 ? p.cols : "3")}, 1fr);
  }

  ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
    grid-template-columns: repeat(${(p) => (p.cols < 2 ? p.cols : "2")}, 1fr);
  }

  ${smallerThan(500)} {
    grid-template-columns: repeat(${(p) => p.columnsOnMobile || "1"}, 1fr);
  }

  ${(p) => smallerThan(p.oneColOn || 0)} {
    grid-template-columns: 1fr;
  }
`;

function Block(props) {
  const {
    backgroundColor = theme.colors.light,
    color = theme.colors.dark,
    zIndex, // = theme.z.body,
    noSpacingTop,
    noSpacingBottom,
    noSpacingLeft,
    noSpacingRight,
    noSpacing,
    // spacingTop, // overwrites noSpacing
    // spacingBottom
    // ...restOfProps
  } = props;

  let { spacingTop = 0, spacingBottom = 0 } = props;

  if (noSpacingTop || noSpacing) spacingTop = 0;
  if (noSpacingBottom || noSpacing) spacingBottom = 0;

  // console.log(spacingTop, spacingBottom);

  // console.log(color, backgroundColor);

  // we can se props.noSpacing if we want to have no spacing on  both1
  /*
  const noSpacingBottom = props.noSpacingBottom || props.noSpacing || false;
  const noSpacingTop = props.noSpacingTop || props.noSpacing || false;
  */

  const wrapperRef = useTopBarColors(backgroundColor, color);

  return (
    <BlockWrapper
      className="block"
      ref={wrapperRef}
      color={color}
      backgroundColor={backgroundColor}
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      zIndex={zIndex}
      noSpacingLeft={noSpacingLeft}
      noSpacingRight={noSpacingRight}
    >
      <div>{props.children}</div>
    </BlockWrapper>
  );
}

export default Block;

export const BlockTopSpacer = styled.div<any>`
  position: relative;
  z-index: ${(p) => p.zIndex || p.theme.z.body};
  background-color: ${(p) => p.backgroundColor || "transparent"};
  height: ${(p) =>
    p.spacing === "topBar"
      ? p.theme.sizes.topBarHeight
      : p.theme.sizes.vBlockSpacing}rem;
  ${(p) => smallerThan(p.theme.breakpoints.small)} {
    height: ${(p) =>
      p.spacing === "topBar"
        ? p.theme.sizes.topBarHeightSmall
        : p.theme.sizes.vBlockSpacingSmall}rem;
  }
`;

export function TopSpacerBlock(props) {
  const { spacing, backgroundColor, color } = props;
  return (
    <Block backgroundColor={backgroundColor} color={color} noSpacing>
      <BlockTopSpacer spacing={spacing} />
    </Block>
  );
}
