/* eslint-disable import/prefer-default-export */
import { createWrapper, HYDRATE } from "next-redux-wrapper";
import { combineReducers, createStore } from "redux";
import { CountryData } from "~/data/shippingCountries";
import cart from "~/store/cart";
import configurator from "~/store/configurator";
import countryData from "~/store/countryData";
import language from "~/store/language";
import navigation, { NavigationState } from "~/store/navigation";
import shippingMethods from "~/store/shippingMethods";
import toasts from "~/store/toasts";
import ui from "~/store/ui";
import viewOnly from "~/store/viewOnly";

export type State = {
  language: any;
  countryData: CountryData | undefined;
  cart: any;
  viewOnly: boolean;
  shippingMethods: never;
  ui: any;
  configurator: any;
  toasts: never;
  navigation: NavigationState;
};

const combinedReducer = combineReducers({
  language,
  countryData,
  cart,
  viewOnly,
  shippingMethods,
  ui,
  configurator,
  toasts,
  navigation,
});

type HydrateAction = { type: "__NEXT_REDUX_WRAPPER_HYDRATE__"; payload: any };
type AnyAction = { type: string; payload?: any };

type Action = HydrateAction | AnyAction;

const reducer = (state: Readonly<State> | undefined, action: Action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    };
    return nextState;
  }
  return combinedReducer(state, action);
};

const makeStore = () => createStore(reducer);

export const wrapper = createWrapper(makeStore);
