import SdkAuth from "@commercetools/sdk-auth";
import fetch from "isomorphic-unfetch";
import getConfig from "next/config";
import nookies from "nookies";

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

/**
 * attaches ctpTokenInfo to the ctx of the app
 * used to wrap around next _app eg. `withCtpTokenInfo(MyApp)`
 * should be wrapped before all components that needs ctpTokenInfo
 * @param {*} PageComponent
 * @param {*} param1
 */
export function withCtpTokenInfo(PageComponent, { ssr = true } = {}) {
  // tokenInfo will be passed as pageProps!!
  function WithCtpTokenInfo(props) {
    // console.log('rendering with props', props.ctpTokenInfo);
    return <PageComponent {...props} />;
  }

  if (process.env.NODE_ENV !== "production") {
    // Find correct display name
    const displayName = PageComponent.displayName || PageComponent.name || "Component";

    // Warn if old way of installing apollo is used
    if (displayName === "App") {
      console.warn("This WithCtpTokenInfo HOC only works with PageComponents.");
    }

    // Set correct display name for devtools
    WithCtpTokenInfo.displayName = `WithCtpTokenInfo(${displayName})`;
  }

  // only get initial props if on server or PageComponent has getInitialProps ??
  if (ssr || PageComponent.getInitialProps) {
    // why is this ctx wrapeed in something else?
    WithCtpTokenInfo.getInitialProps = async (appContext) => {
      const { ctx } = appContext;
      // const appCtx = ctx.ctx;

      // get tokenInfo
      const ctpTokenInfo = await getTokenInfo(ctx);

      // console.log('🚧 GOT TOKEN INFO TEST 🚧', ctpTokenInfo);

      // add tokenInfo to ctx so all other components underneath can use it
      ctx.ctpTokenInfo = ctpTokenInfo;

      // get props from rest of trees..
      const pageProps = PageComponent.getInitialProps
        ? await PageComponent.getInitialProps(appContext)
        : {};

      return {
        ...pageProps,
      };
    };
  }

  return WithCtpTokenInfo;
}

/**
 *  Commercetools auth
 */
const scopes = publicRuntimeConfig.COMMERCETOOLS_SCOPES
  ? publicRuntimeConfig.COMMERCETOOLS_SCOPES.split(" ")
  : [];
const authOptions = {
  host: publicRuntimeConfig.COMMERCETOOLS_AUTH_ENDPOINT,
  projectKey: publicRuntimeConfig.COMMERCETOOLS_PROJECT_ID,
  // disableRefreshToken: false,
  credentials: {
    clientId: publicRuntimeConfig.COMMERCETOOLS_CLIENT_ID,
    clientSecret: publicRuntimeConfig.COMMERCETOOLS_CLIENT_SECRET,
  },
  scopes,
  fetch,
};

// make the auth client from our options-...
const authClient = new SdkAuth(authOptions);

/**
 * Generates a tokenInfo
 * fetches current one from client, refreshes it if needed or creates a new one
 * @param {*} ctx
 */
export async function getTokenInfo(ctx) {
  const cookieName = "ctpTokenInfo";

  // get current tokenInfo from the user's cookies (if any)
  let tokenInfo = nookies.get(ctx)[cookieName]
    ? JSON.parse(nookies.get(ctx)[cookieName])
    : null;

  // console.log('FOUND TOKEN INFO?', tokenInfo);
  // console.log('TOKEN DEBUG:', typeof document !== 'undefined' ? document.cookie : 'no doc')

  // if we have tokenInfo
  if (tokenInfo) {
    if (tokenInfo.expires_at) {
      if (tokenInfo.refresh_token) {
        // and it is expired, refresh it (if possible)
        if (Date.now() >= tokenInfo.expires_at) {
          try {
            const refreshToken = tokenInfo.refresh_token;
            tokenInfo = await authClient.refreshTokenFlow(refreshToken);
            // We need to keep the refresh token ourselves
            tokenInfo.refresh_token = refreshToken;
            // update cookies
            nookies.set(ctx, cookieName, JSON.stringify(tokenInfo), {
              path: "/",
              maxAge: 60 * 60 * 24 * 365 * 10, // 10 years
            });
          } catch (e) {
            console.warn("refreshTokenFlow failed", e);
            // The refresh token is no longer valid, so we need to get a new one
            tokenInfo = null;
          }
        }
      } else {
        // we dont have refresh token... just make a new one?
        tokenInfo = null;
      }
    } else {
      // for some reason we are missing expires_at...
      tokenInfo = null;
    }
  }

  // if we have none, create one
  // also applies if refreshTokenFlow returned null for some reason
  if (tokenInfo === null) {
    tokenInfo = await authClient.anonymousFlow();
    // update cookies
    nookies.set(ctx, cookieName, JSON.stringify(tokenInfo), {
      path: "/",
      maxAge: 60 * 60 * 24 * 365 * 10, // 10 years
    });
  }

  // console.log('🤖', tokenInfo.access_token);

  return tokenInfo;
}
