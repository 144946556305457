import { motion } from "framer-motion";
import styled from "styled-components";
import Link from "~/components/Link";
import { rem, smallerThan } from "~/styles/theme";
import LogoFile from "../logo.svg";

const LogoA = styled.a`
  font-size: 0px;
  display: block;
  cursor: pointer;
`;

export const LogoWrapper = styled(LogoFile)`
  width: 2.7rem;
  height: 2.7rem;
  margin: ${(p) => p.theme.sizes.vSpacing}rem ${(p) => p.theme.sizes.hSpacing}rem;

  fill: ${(p) => p.color || "black"};
  transition: fill 0.2s;
  transition-delay: 0.2s; /* should match close animation */
  ${(p) => smallerThan(p.theme.breakpoints.ipadLandscape)} {
    width: ${rem("35px")};
    height: ${rem("35px")};
    margin: ${(p) => p.theme.sizes.vSpacingSmall}rem
      ${(p) => p.theme.sizes.hSpacingSmall}rem;
  }
`;

function LogoLink(props) {
  const { color, onTap = () => {} } = props;
  return (
    <motion.div onTap={onTap}>
      <Link href="/">
        <LogoA>
          <LogoWrapper name="home" color={color} />
        </LogoA>
      </Link>
    </motion.div>
  );
}

export default LogoLink;
