import nookies from "nookies";

// ctx should be provided from getInitialProps of the app
function resolveCountryCode(ctx = {}) {
  // attempt to fetch from cookies (SSR will set first time load)
  let { countryCode } = nookies.get(ctx);

  // if countryCode is not set, and we are server side, fetch the countryCode
  if (!countryCode && ctx.req) {
    if (ctx.req.headers["cf-ipcountry"] || ctx.req.headers["country-code"]) {
      // cf-ipcountry
      countryCode = ctx.req.headers["cf-ipcountry"] || ctx.req.headers["country-code"];
      // console.log('cf-ipcountry', countryCode);
      nookies.set(ctx, "countryCode", countryCode, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365 * 10, // 10 years
      });
    }

    if (!countryCode) {
      // countryCode = await getCountryCode(ctx.req);
      countryCode = "US";
    }

    if (countryCode) {
      // and save it to cookies
      nookies.set(ctx, "countryCode", countryCode, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365 * 10, // 10 years
      });
    }
  }
  if (!countryCode) {
    // No country code cookie when client-side
    console.log("resolveCountryCode - NO COOKIE CLIENT-SIDE");
  }

  return countryCode ?? "US";
}

export default resolveCountryCode;
