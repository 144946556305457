import { v4 as uuidv4 } from "uuid";

/**
 * {
 *    id: '...',
 *    message: '...',
 *    type: '...'
 *    unmountMs: 1234,
 * }
 */

/**
 * actionTypes
 */

export const actionTypes = {
  ADD_TOAST: "ADD_TOAST",
  REMOVE_TOAST: "REMOVE_TOAST",
  CLEAR_TOASTS: "CLEAR_TOASTS",
};

/**
 * Reducer
 */

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.ADD_TOAST:
      return [...state, action.payload.toast];
    case actionTypes.REMOVE_TOAST:
      return state.filter((t) => t.id !== action.payload.toast.id);
    case actionTypes.CLEAR_TOASTS:
      return [];
    default:
      return state;
  }
};

export default reducer;

/**
 * Action creators
 */

export function addToast(toast) {
  return {
    type: actionTypes.ADD_TOAST,
    payload: { toast: { id: uuidv4(), ...toast } },
    // payload: { toast }
  };
}

export function removeToast(toast) {
  return {
    type: actionTypes.REMOVE_TOAST,
    payload: { toast },
  };
}

export function clearToasts() {
  return {
    type: actionTypes.CLEAR_TOASTS,
  };
}
