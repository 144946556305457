import { useRef, useState } from "react";
import ButtonArrow from "~/components/ButtonArrow";
import FormInputText from "~/components/FormInputTextV2";
import useFormInput from "~/hooks/useFormInputV2";
import theme from "~/styles/theme";

const regex = {
  name: /.{2,}/,
  email: /(.+)@(.+){2,}\.(.+){2,}/,
  url: /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/,
};

function MailchimpSignup(props) {
  const {
    listIdentifier,
    placeholder = "Your email",
    buttonText = "Sign up",
    color,
    backgroundColor,
  } = props;

  const email = useFormInput("", regex.email);

  const form = useRef();

  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState("unset"); // "unset", "error" or "success"
  const [message, setMessage] = useState(null);

  async function handleFormSubmit(e) {
    e.preventDefault();
    if (!submitted) setSubmitted(true);

    setSubmitting(true);
    setMessage(null);

    if (email.valid) {
      const subscribeEmailRequest = await fetch(
        `${window.location.protocol}//${window.location.host}/api/mailchimp/subscribe`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            list: listIdentifier,
            email: email.value,
          }),
        }
      );

      const subscribeEmail = await subscribeEmailRequest.json();

      // then(res => res.json());

      if (subscribeEmailRequest.status !== 200) {
        setState("error");
        setMessage(subscribeEmail.message);
      } else {
        setState("success");
        setMessage(subscribeEmail.message);
      }
    } else {
      // console.log('NOT VALID');
      // find first invalid element
      const firstInvalidElement = document.querySelector(".invalid");
      if (firstInvalidElement) {
        firstInvalidElement.scrollIntoView({
          behavior: "smooth",
        });
      }
    }

    setSubmitting(false);
  }

  // function handleSubmit(e) {
  //   e.preventDefault();
  //   return false;
  // }

  return (
    <>
      <form ref={form} onSubmit={handleFormSubmit} noValidate>
        <FormInputText
          name="email"
          label={placeholder}
          {...email}
          backgroundColor={backgroundColor}
          color={color}
          submitted={submitted}
          disabled={submitting || state === "success"}
          fontSize="14px"
        />
        <br />

        <ButtonArrow
          backgroundColor={backgroundColor}
          color={state === "success" ? theme.colors.primary : color}
          href="/submit"
          onClick={handleFormSubmit}
          disabled={submitting}
          working={submitting}
          done={state === "success"}
        >
          {state === "success" ? "Thank you" : buttonText}
        </ButtonArrow>
      </form>
      {message && (
        <p
          style={{
            fontSize: "14px",
            color: state === "error" ? theme.colors.error : theme.colors.primary,
          }}
        >
          {message}
        </p>
      )}
    </>
  );
}

export default MailchimpSignup;
