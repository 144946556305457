import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addVariantsToCart,
  getProductsAvailabilityBySkus,
} from "~/external/commercetools";
import { useDataLayer } from "~/hooks/useDataLayer";
import { CtpCtx } from "~/lib/ctpApollo";
import { setCart } from "~/store/cart";
import { addToast } from "~/store/toasts";

/**
 * Hook
 * @param {*} variant
 * @param {*} forceDisabled
 * @param {*} cooldownMs
 */
function useBuyButton(variant, extras, skipCrossSells = false, forceDisabled = false) {
  // use dataLayer to trigger events in GTM
  const dataLayer = useDataLayer("dataLayer");

  const ctpCtx = useContext(CtpCtx);
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const countryData = useSelector((state) => state.countryData);
  // const viewOnly = useSelector(state => state.viewOnly);

  let outOfStock = variant.availability.availableQuantity <= 0;

  if (variant.productTypeKey === "parts-bundle") {
    outOfStock = false;
    for (let i = 0; i < variant.parts.length; i++) {
      const part = variant.parts[i];
      if (part.variants[part.masterVariantIndex].availability.availableQuantity <= 0) {
        outOfStock = true;
        break;
      }
    }
  }

  // console.log(
  //   'useBuyButton',
  //   JSON.stringify(
  //     {
  //       variant,
  //       outOfStock,
  //       forceDisabled,
  //       countryData,
  //       shippingDisabled: !countryData.shipping.enabled
  //     },
  //     undefined,
  //     2
  //   )
  // );

  // console.log(variant.sku, outOfStock);

  // disabled if no stock
  // const disabled =
  //   viewOnly || forceDisabled || variant.availability.availableQuantity === 0;
  const disabled = !countryData.shipping.enabled || forceDisabled || outOfStock;

  // console.log('useBuyButton', disabled);

  const [working, setWorking] = useState(false);

  // cooldown is used to display a "added to cart" eg. state on the button
  // const [inCooldown, setInCooldown] = useState(false);
  // make a small timer to shopw the success message
  const cooldownTimeout = useRef();
  // when we unmount, clear addedToCartTimeout
  useEffect(() => () => window.clearTimeout(cooldownTimeout.current), []);

  if (!variant) return {};

  async function onClick(e) {
    try {
      e.preventDefault();
      if (working) return;
      setWorking(true);

      console.log("variant", variant);

      const partsBundleContainerSkus = variant.bundle
        ? variant.bundledProducts
            .filter((mv) => mv.typeKey === "parts-bundle")
            .map((bp) => bp.variants[bp.masterVariantIndex])
            .map((mv) => mv.sku)
        : [];

      console.log("partsBundleContainerSkus", partsBundleContainerSkus);

      // check availabilty - product cache might be stale...
      // this _should_ also update the availibity in cache ??
      let skus = variant.bundle
        ? variant.bundledProductsSkus
        : [
            ...(variant.productTypeKey === "parts-bundle" // In case the part is not part of another bundle
              ? variant.parts.map((part) => part.variants[part.masterVariantIndex].sku)
              : [variant.sku]),
          ];

      if (variant.bundle) {
        skus = variant.bundledProductsSkus.filter(
          (sku) => !partsBundleContainerSkus.includes(sku)
        );
      }

      const partsBundles = variant.bundle
        ? [].concat.apply(
            [],
            variant.bundledProducts
              .map((bp) => bp.variants[bp.masterVariantIndex].parts)
              .filter((parts) => parts.length > 0)
          )
        : [];

      console.log("partsBundles", partsBundles);

      const partsBundleSkus = partsBundles.map(
        (part) => part.variants[part.masterVariantIndex].sku
      );

      console.log("partsBundleSkus", partsBundleSkus);

      skus = [
        ...skus,
        ...partsBundleSkus,
        ...extras.map((extra) => extra.variants[extra.masterVariantIndex].sku),
      ];

      console.log("getProductsAvailabilityBySkus", skus);

      const availability = await getProductsAvailabilityBySkus(
        ctpCtx,
        skus,
        countryData
      );

      console.log("check availabilty", skus, availability);

      // IF 0 DISABLE AND MAKE TOAST!!
      if (availability <= 0) {
        dispatch(
          addToast({
            title: `Error`,
            text: `Product out of stock.`,
            unMountMs: false,
            backgroundColor: "tomato",
            color: "white",
          })
        );
        setWorking(false);
        return;
      }

      const updatedCart = await addVariantsToCart(
        ctpCtx,
        cart,
        [variant, ...extras.map((extra) => extra.variants[extra.masterVariantIndex])],
        countryData,
        1
      );

      // console.log('updatedCart', updatedCart);

      // console.log('WILL DISPATCH CART:', updatedCart.id);
      // set the updated cart in the store
      dispatch(setCart(updatedCart));
      // console.log('DID DISPATCH CART:', updatedCart.id);

      // add event to GTM
      dataLayer.push({
        event: "addToCart",
        variant,
      });

      // TODO: Include extras in GTM event?

      // show the success
      setWorking(false);
      // setInCooldown(true);
      // cooldownTimeout.current = setTimeout(() => {
      //   setInCooldown(false);
      // }, cooldownMs);

      // add TOAST!

      const toast = {
        title: `Added to cart`,
        variant,
        extras,
        unMountMs: 5000,
        // unMountMs: false,
        secondaryButton: {
          text: `View cart`,
          href: `/cart`,
        },
        primaryButton: {
          text: `Checkout`,
          href: `/checkout/shipping-address`,
        },
      };

      if (!skipCrossSells) {
        if (typeof document !== "undefined") {
          toast.optimize = document.body.getAttribute("optimize-activated");
          if (toast.optimize) {
            toast.primaryButton.text = toast.secondaryButton.text;
            toast.primaryButton.href = toast.secondaryButton.href;
            toast.secondaryButton = {
              text: `Continue shopping`,
              href: ``,
            };
          }
        }
      }

      dispatch(addToast(toast));
    } catch (e) {
      console.log("DID FAIL:", cart.id);
      console.log(
        "TOKEN EXPIRED?",
        Date.now(),
        ctpCtx.tokenInfo.expires_in,
        ctpCtx.tokenInfo.expires_at
      );
      console.error(e);
      setWorking(false);
      // setInCooldown(false);
    }
  }

  return { onClick, disabled, working };
}

export default useBuyButton;
