import React from "react";
import theme from "~/styles/theme";

export const themes = {
  dark: {
    name: "dark",
    foreground: theme.colors.lightish,
    highlight: theme.colors.primary,
    background: theme.colors.dark,
    button: {
      background: theme.colors.primary,
      foreground: theme.colors.dark,
    },
  },
  light: {
    name: "light",
    foreground: theme.colors.dark,
    highlight: theme.colors.primary,
    background: theme.colors.light,
    button: {
      background: theme.colors.dark,
      foreground: theme.colors.light,
    },
  },
};

export const ThemeContext = React.createContext(themes.light);
