import { lighten, rgbaTupleToRgba, rgbStringToRgbTuple, toRgb } from "@wessberg/color";
import { DefaultTheme } from "styled-components";

const baseFontsize = 16;

// ------------------------------
//  Helpers
// ------------------------------

// px => rem
export const pxToRem = (pxVal: string): number =>
  (1 / baseFontsize) * parseInt(pxVal, 10);

export const rem = (pxVal: string): string => {
  const r = pxToRem(pxVal);
  return `${r}rem`;
};

// rem => px
export const px = (remVal: string, prependPx = true): string => {
  const p = baseFontsize * parseFloat(remVal);
  if (prependPx) return `${p}px`;
  return `${p}`;
};

export const rgbWithAlpha = (rgb: string, alpha = 1.0) => {
  try {
    // Strange workaround for gettint the tuple to work
    const [r, g, b] = Array(rgbStringToRgbTuple(rgb))[0];
    const rgba = rgbaTupleToRgba([r, g, b, alpha]);
    return rgba;
  } catch (e) {
    // console.error(e);
    return rgb;
  }
};

// turn hex into rgba eg. "#000000" => "rgba(0,0,0,1)"
// you can also control opacity
export const hexToRgba = (hex: string, opacity = 1.0) => {
  const rgb = toRgb(hex);
  try {
    const regexp = /rgb\(([0-9]{1,3}), ?([0-9]{1,3}), ?([0-9]{1,3})\)/g;
    const match = regexp.exec(rgb);
    if (match) {
      return `rgba(${match[1]}, ${match[2]}, ${match[3]}, ${opacity})`;
    }
  } catch (e) {
    // console.error(e);
  }
  return rgb;
};

export const rgba = hexToRgba;

// meadia quries...
export const biggerThan = (pxVal: string | number) =>
  `@media (min-width: ${parseInt(`${pxVal}`, 10)}px)`;
export const smallerThan = (pxVal: string | number) =>
  `@media (max-width: ${parseInt(`${pxVal}`, 10) - 1}px)`;
export const between = (px1: string, px2: string) =>
  `@media (min-width: ${parseInt(px1, 10)}px and max-width: ${parseInt(px2, 10)}px)`;

const theme: DefaultTheme = {
  // ------------------------------
  //  Colors
  // ------------------------------
  colors: {
    // primary color
    // theme.colors.primary = '#d7ff46';
    primary: "rgb(203, 255, 15)",
    primaryDark: lighten("#d7ff46", -20),

    discount: "tomato",
    error: "tomato",

    // dark / light
    dark: "#262626",
    light: "#fafafa",
    lightish: "#a8a8a8",

    // greys
    grey: "#d7d7d7",
    greyLight: "#d0d0d0",
    greyLighter: "#ececec",
  },
  // variantsColors
  variantColors: {
    grey: "#989898",
    black: "#262626",
    white: "#fafafa",
    olive: "#595d45",
  },
  // ------------------------------
  //  Sizes
  // ------------------------------
  sizes: {
    hSpacing: pxToRem("10px"),
    vSpacing: pxToRem("10px"),

    hSpacingSmall: pxToRem("7.5px"),
    vSpacingSmall: pxToRem("7.5px"),

    vBlockSpacing: pxToRem("130px"),
    vBlockSpacingSmall: pxToRem("110px"),
    topBarHeight: pxToRem("80px"),
    topBarHeightSmall: pxToRem("74px"), // 4.6,
    topBarMobileHeight: pxToRem("74px"),

    bottomBarHeight: pxToRem("80px"),
    bottomBarMobileHeight: pxToRem("74px"),
    tabsHeight: pxToRem("50px"),

    mobileBottomBarHeight: pxToRem("56px"),
  },
  // ------------------------------
  //  breakpoints (all in px)
  // ------------------------------
  breakpoints: {
    mobilePortrait: 411,
    ipadPortrait: 768,
    ipadLandscape: 1024,
    smallDesktop: 1200,

    small: 768, // use this for the small sizes from the design...
    configuratorSmall: 768, // use this for the small sizes from the design...
    configuratorMedium: 1024, // use this for the medium sizes from the design...
  },
  // ------------------------------
  //  Z
  // ------------------------------
  z: {
    topBar: 900,
    toasts: 800,
    configurator: 50,
    body: 100,
    footer: 200,
  },
};

const fontStyles: { [key: string]: string } = {
  Normal: "",
  Extended: " Extended",
  Condensed: " Condensed",
};

export const parseFontStyle = (input: string, fallback: string) =>
  fontStyles[input] ?? fontStyles[fallback] ?? fontStyles.Normal;

const fontWeights: { [key: string]: number } = {
  Light: 200,
  Normal: 400,
  Medium: 500,
  Bold: 700,
  Black: 800,
};

export const parseFontWeight = (input: string, fallback: string) =>
  fontWeights[input] ?? fontWeights[fallback] ?? fontWeights.Normal;

export default theme;
