import { useUser } from "@auth0/nextjs-auth0/client";
import fetch from "isomorphic-unfetch";
import { useContext, useState } from "react";
import styled from "styled-components";
import useCountry from "~/hooks/useCountry";
import useFormInput from "~/hooks/useFormInput";
import { ThemeContext } from "~/lib/ThemeContext";
import { biggerThan, smallerThan } from "~/styles/theme";
import ButtonSquare from "../ButtonSquare";
import FormInputText from "../FormInputText";

const Wrapper = styled.div`
  width: 100%;

  ${(props) => biggerThan(props.theme.breakpoints.ipadPortrait)} {
    max-width: 400px;
  }

  .hidden-on-small {
    ${(props) => smallerThan(props.theme.breakpoints.ipadPortrait)} {
      display: none;
    }
  }
  .hidden-on-big {
    ${(props) => biggerThan(props.theme.breakpoints.ipadPortrait)} {
      display: none;
    }
  }
`;

const FormRow = styled.div`
  > button {
    margin-top: 0.5rem;
  }

  ${(props) => smallerThan(props.theme.breakpoints.ipadPortrait)} {
    > div {
      width: 100%;
    }
    > button {
      width: 100%;
      margin-top: 0;
    }
  }
`;

const Header = styled.h4`
  margin: 0;
  ${(props) => biggerThan(props.theme.breakpoints.ipadPortrait)} {
    margin-top: 1rem;
  }
  ${(props) => smallerThan(props.theme.breakpoints.ipadPortrait)} {
    display: none;
  }
`;

type Props = {
  variant: any;
};

function NotifyMeButton({ variant }: Props) {
  const currentTheme = useContext(ThemeContext);

  const country = useCountry();
  // @ts-ignore
  const { user } = useUser();

  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const email = useFormInput(user?.email ?? "");

  const onClick = async () => {
    // Validate
    if (!/(.+)@(.+){2,}\.(.+){2,}/.test(email.value)) {
      setIsValid(false);
      return;
    }
    setIsValid(true);

    // Submit
    const payload = {
      email: email.value,
      countryCode: country?.code ?? null,
      channelKey: country?.channelKey ?? null,
      productName: variant.name,
      productSKU: variant.sku ?? null,
    };
    const response = await fetch("/api/klaviyo/notify-me", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
    setIsSubmitted(true);
  };

  if (!country || !variant.name || !variant.sku) {
    return null;
  }

  return (
    <Wrapper>
      <Header>Notify me when available</Header>
      {isSubmitted && <div>Thank you! We will email you when available.</div>}
      {!isSubmitted && (
        <FormRow>
          <FormInputText
            type="text"
            name="email"
            label="Email"
            pattern="(.+)@(.+){2,}\.(.+){2,}"
            isInvalid={isValid === false}
            required
            {...email}
          />
          <ButtonSquare
            onClick={onClick}
            color={currentTheme.button.foreground}
            backgroundColor={currentTheme.button.background}
          >
            <span className="hidden-on-small">Notify me</span>
            <span className="hidden-on-big">Notify me when available</span>
          </ButtonSquare>
        </FormRow>
      )}
    </Wrapper>
  );
}

export default NotifyMeButton;
