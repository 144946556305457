import styled from "styled-components";
import Link from "~/components/Link";
import PriceLine from "~/components/PriceLine";
import resolveVariantLink from "~/lib/resolveVariantLink";

const LinkButtonWrapper = styled.div`
  align-self: ${(p) =>
    !p.alignment
      ? "unsett"
      : p.alignment == "right"
      ? "flex-end"
      : p.alignment == "center"
      ? "center"
      : "flex-start"};
  padding-right: ${(p) => (p.alignment == "right" ? "1.8em" : "unset")};

  a {
    text-decoration: none;
  }
`;

function LinkButton(props) {
  let {
    children,
    buttonComponent,
    buttonText,
    disabled = false,
    buttonProps = {},
    href,
    as,
    callback = () => {},
    target = "_self",
    variant,
    includePrice,
    highlightColor,
    style,
    alignment,
  } = props;

  // console.log('LinkButton');

  let hrefAs = as || href;

  // if we have a variant, use that!
  if (variant) {
    // variant does not overwrite link
    const vLink = resolveVariantLink(variant);
    if (!href && vLink.href) href = vLink.href;
    if (!hrefAs && vLink.as) hrefAs = vLink.as;
  }

  const Comp = buttonComponent || ((props) => <button>{props.children}</button>);

  if (href === null || typeof href === "undefined") {
    return null;
  }

  return (
    <LinkButtonWrapper alignment={alignment} style={style} onClick={callback}>
      <Link href={href} as={hrefAs}>
        <a target={target} rel={target == "_blank" ? "noopener noreferrer" : null}>
          <Comp disabled={disabled} {...buttonProps}>
            {buttonText || children}
            {variant && includePrice && (
              <>
                {" "}
                · <PriceLine price={variant.price} highlightColor={highlightColor} />
              </>
            )}
          </Comp>
        </a>
      </Link>
    </LinkButtonWrapper>
  );
}

export default LinkButton;
