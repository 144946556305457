import shippingCountries from "@aiaiai/shipping-countries";
/*
  Managed in aiaiai-utils
*/

type ShippingCountry = typeof shippingCountries[number];

export type CountryData = ShippingCountry & {
  zone?: string;
  channel?: string | null;
  language?: string;
};

export const defaultCountry: CountryData = {
  name: "Rest of world",
  code: "WW",
  currency: "USD",
  zone: "United states",
  channelKey: "us",
  channel: null, // set auto
  shipping: {
    enabled: false,
  },
};

export default shippingCountries;
