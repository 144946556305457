import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Link from "~/components/Link";
import useChildrenWithProps from "~/hooks/useChildrenWithProps";
import { hSpacing } from "~/styles/mixins";
import theme from "~/styles/theme";

const DropdownWrapper = styled(motion.div)`
  position: fixed;
  z-index: ${(p) => p.theme.z.topBar + 1};
  top: ${(p) => p.theme.sizes.topBarHeight}rem;

  left: 0;
  right: 0;
  background-color: ${(p) => p.backgroundColor || p.theme.colors.light};
  z-index: ${(p) => (p.visible ? p.theme.z.topBar - 1 : p.theme.z.topBar - 2)};
  /* display: flex; */
  overflow: hidden;
  height: 0; /* initial height */
  display: flex;
  /*
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
  grid-gap: ${(p) => p.theme.sizes.hSpacing * 2}rem;
  */
  padding: 0 ${(p) => p.theme.sizes.hSpacing}rem;
`;

const DropdownCell = styled.div`
  position: relative;
  flex: 1;
  ${hSpacing};

  display: flex;
  /* add a vertical line through the 2 cells */
  &:nth-child(1) {
    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      top: ${(p) => p.theme.sizes.topBarHeight}rem;
      width: 1px;
      background-color: ${(p) => p.theme.colors.grey};
    }
  }
  img {
    /* width: 28vw;
    height: 28vw; */
    width: 360px;
    height: 360px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const DropdownImageWrapper = styled.div`
  /* background-color: tomato; */
  position: relative;
  transform: translateX(-10%);
  /*pointer-events: none;*/
  cursor: pointer;
  width: 400px;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: ${(p) => p.theme.sizes.topBarHeight}rem;
    background-image: url(${(p) => p.src ?? p.pinnedImageSrc});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
  }
`;

export function DropdownImage(props) {
  const { pinnedItem, src } = props;
  const hasPinnedItem = pinnedItem != null;
  return hasPinnedItem ? (
    <Link href={pinnedItem.href} as={pinnedItem.href}>
      <DropdownImageWrapper pinnedImageSrc={pinnedItem.image.url} src={src} />
    </Link>
  ) : (
    <DropdownImageWrapper src={src} />
  );
}

export const DropdownMenu = styled(motion.ul)`
  /* flex: 1; */
  width: 200px;
  list-style: none;
  padding: 0;
  margin: ${(p) => p.theme.sizes.topBarHeight}rem 0;
`;

// animate height and stagger children
const dropdownVariants = {
  closed: {
    height: 0,
    transition: {
      staggerChildren: 0.08,
      delayChildren: 0.1,
      ease: [0.21, 0, 0.21, 0.99],
    },
  },
  open: {
    height: "auto",
    transition: {
      staggerChildren: 0.08,
      delayChildren: 0.1,
      ease: [0.21, 0, 0.21, 0.99],
    },
  },
};

// add a reveal animation for all the menues...
const dropdownMenuVariants = {
  closed: {
    opacity: 0,
    y: "3rem",
  },
  open: {
    opacity: 1,
    y: 0,
  },
};

function Dropdown(props) {
  const { top = `${theme.sizes.topBarHeight}rem` } = props;

  const ui = useSelector((state) => state.ui);

  // add animation variant to each child (HAS to be <DropdownMenu>)
  const children = useChildrenWithProps(props.children, {
    dropdownMenuVariants,
  });

  return (
    <DropdownWrapper
      variants={dropdownVariants}
      hidden={ui.topBarHidden}
      style={{ top: top }}
    >
      {children}
    </DropdownWrapper>
  );
}

export default Dropdown;
