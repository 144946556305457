/**
 * Action types
 */

export const actionTypes = {
  SET_SHIPPING_METHODS: "SET_SHIPPING_METHODS",
};

/**
 * Reducer
 */

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_SHIPPING_METHODS:
      // return action.payload.shippingRates;
      const { shippingMethods } = action.payload;
      if (shippingMethods.length === 0) {
        return [];
      }

      const defaultShippingMethod = shippingMethods.find((s) => s.isDefault);

      return {
        // selected: shippingMethods.reduce((acc, s) => {
        //   if (s.isDefault) {
        //     return s.key;
        //   }
        //   return acc;
        // }, null),
        selected: defaultShippingMethod ? defaultShippingMethod.key : null,
        keys: shippingMethods.map((s) => s.key),
        byKey: shippingMethods.reduce((acc, s) => {
          acc[s.key] = s;
          return acc;
        }, {}),
      };
    default:
      return state;
  }
};

/*
{
  selected: string(uri)
  byUri: {}
  uris: []
}
*/

export default reducer;

/**
 * Actions
 */

export function setShippingMethods(shippingMethods) {
  return {
    type: actionTypes.SET_SHIPPING_METHODS,
    payload: { shippingMethods },
  };
}
