import styled from "styled-components";
import { hexToRgba } from "~/styles/theme";

// function InputComponent(props) {
//   const { backgroundColor, color, ...restOfProps } = props;
//   return <input {...restOfProps} />;
// }

export const Input = styled.input`
  position: relative;
  /* display: ${(p) => (p.inline ? "inline-block" : "block")}; */
  padding: 1em 0 0.1em 0;
  background-color: ${(p) => p.backgroundColor || p.theme.colors.light};
  color: ${(p) => p.color || p.theme.colors.dark};
  /*border-radius: 2px;*/
  border-radius: 0;
  border: 0;
  /*border: 1px solid ${(p) => p.theme.colors.dark};*/
  width: 100%;
  font-size: 16px;
  height: 3.125em; /* 50px; */
  border-bottom: 2px solid ${(p) => p.color || p.theme.colors.dark};
  outline: none;
  /*
  &:invalid {
    ${(p) =>
    p.hadFocus ? "border-bottom: 2px solid #f44336;" : "border-bottom: 2px solid #ccc;"}
  }
  &:valid {
    ${(p) =>
    p.hadFocus ? "border-bottom: 2px solid #ccc;" : "border-bottom: 2px solid #ccc;"}
  }
  &:focus {
    ${(p) =>
    p.readOnly
      ? "border-bottom: 2px solid #eee; outline: none;"
      : "border-bottom: 2px solid #2196f3; outline: none;"}
  }
  */

  /* &:invalid { */
  /* &.invalid.dirty { */
  &.invalid {
    border-color: ${(p) => p.theme.colors.error};
    color: ${(p) => p.theme.colors.error};
  }

  &:disabled {
    /* border-color: ${(p) => hexToRgba(p.color || p.theme.colors.dark, 0.5)}; */
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    /* -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s; */
    /* -webkit-box-shadow: 0 0 0 60px red inset !important;*/
    -webkit-text-fill-color: ${(p) => p.color || p.theme.colors.dark} !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:focus {
    border-color: ${(p) => p.highlightColor || p.theme.colors.primary};
    /* background-color:red; */
  }
`;

export default Input;
