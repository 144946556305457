import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import Link from "~/components/Link";
import VideoAuto from "~/components/VideoAuto";
import resolveVariantLink from "~/lib/resolveVariantLink";
import { vSpacing } from "~/styles/mixins";

const MediaWrapper = styled.div`
  ${vSpacing};
  overflow: hidden;
  > img {
    width: 100%;
    display: block;
    transition: transform 7s cubic-bezier(0.21, 0, 0.21, 0.99);
  }
  video {
    width: 100%;
    display: block;
  }
  &:hover {
    > img {
      transform: scale(${(p) => (p.hoverable ? "1.05" : "1")});
    }
  }
`;

const Placeholder = styled.div`
  background-color: ${(p) => p.backgroundColor || p.theme.colors.greyLighter};
  width: 100%;
  height: 0;
  padding-bottom: ${(p) => (100 / (p.width || 1)) * (p.height || 1)}%;
`;

const Img = styled.img`
  width: 100%;
  display: block;
  background-color: ${(p) => p.backgroundColor || "transparent"};
`;

const LoadTrigger = styled.div`
  display: block;
  position: absolute;
  top: -${(p) => p.theme.sizes.vBlockSpacing}rem;
  visibility: hidden;
`;

function getMedia(media, loaded, props = {}) {
  // if (media === null) return null;

  const [
    src,
    type,
    width,
    height = null,
    alt = null,
    placeholder = null,
    muted = true,
    loop,
  ] = media || [];

  if (!loaded) {
    return (
      <Placeholder
        width={width}
        height={height || width}
        backgroundColor={props.backgroundColor}
      />
    );
  }

  switch (type) {
    case "video":
      return (
        <VideoAuto
          noSpacing
          src={src}
          poster={placeholder}
          muted={muted}
          loop={loop}
          {...props}
        />
      );
    case "image":
      return <Img src={src} alt={alt} {...props} />;
    default:
      return null;
  }
}

function MediaFull(props) {
  let {
    hoverable = false,
    media = null,
    noPadding = false,
    lazy = true,
    autoLoad = true, // set to false if you want to control when it loads
    clickable = false,
    href,
    as,
    variant,
    target = "_self",
    // hasLoadTrigger = true,
    ...restOfProps
  } = props;

  // console.log(media, lazy)

  let hrefAs = as || href;

  if (variant && clickable) {
    // variant does not overwrite link
    const vLink = resolveVariantLink(variant);
    if (vLink) {
      href = vLink.href;
      hrefAs = vLink.as;
    }
  }

  if (!clickable) {
    href = undefined;
    hrefAs = undefined;
  }

  const [src, type, width, height = null, alt = null, placeholder = null] = media || [];

  // if NOT lazy, loaded is true to start with
  const [loaded, setLoaded] = useState(!lazy);

  // trigge 200px befpre enter screen...
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: "200px 0px",
  });

  // setup a listener, if media is lazy!
  // when the ref goes into view
  useEffect(() => {
    if (!media) return;
    if (!lazy) return;
    // load image if it is in view and not loaded already...
    if (inView === true && loaded === false) {
      if (type === "image") {
        // load image!
        const img = new Image();
        img.onload = () => {
          // console.log('load');
          setLoaded(true);
        };
        img.src = src;
      } else if (type === "video") {
        /// UNTESTED!!!
        const video = document.createElement("video");
        video.oncanplay = () => {
          // console.log('load');
          setLoaded(true);
        };
        video.src = src;
        video.load(); // dont need this?
      }
    }
  }, [inView]);

  return (
    <div ref={ref}>
      <Link href={href} as={hrefAs}>
        <a target={target} rel={target === "_blank" ? "nofollow noopener" : undefined}>
          {/* {hasLoadTrigger && <LoadTrigger />} */}
          <MediaWrapper noSpacing={noPadding} hoverable={hoverable && href}>
            {getMedia(media, loaded, restOfProps)}
          </MediaWrapper>
        </a>
      </Link>
    </div>
  );
}

export default MediaFull;
