import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { vSpacing } from "~/styles/mixins";

const VideoAutoWrapper = styled.video`
  width: 100%;
  ${vSpacing};
  display: block;
  cursor: ${(p) => (p.autoPlay ? "default" : "pointer")};
`;

function VideoAuto(props) {
  const { src, noSpacing, poster = null, muted = true, loop } = props;

  const videoRef = useRef();
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [inViewRef, inView] = useInView();

  // if !poster, only play when not in view...
  useEffect(() => {
    if (poster) return;
    try {
      if (inView) {
        const promise = videoRef.current.play();
        // Check if undefined - Promise not returned in all browsers
        if (promise !== undefined) {
          promise
            .then((_) => {
              // Media should play now
              setVideoPlaying(true);
            })
            .catch((error) => {
              // console.warn(error);
            });
        } else {
          setVideoPlaying(true);
        }
      } else {
        videoRef.current.pause();
        setVideoPlaying(false);
      }
    } catch (err) {
      console.warn(err);
    }
    return () => {
      //
    };
  }, [inView]);

  const handleVideoPlayerClick = (event) => {
    if (!poster || !videoRef.current) return;
    try {
      if (videoPlaying) {
        videoRef.current.pause();
        setVideoPlaying(false);
      } else {
        const promise = videoRef.current.play();
        // Check if undefined - Promise not returned in all browsers
        if (promise !== undefined) {
          promise
            .then((_) => {
              // Media should play now
              setVideoPlaying(true);
            })
            .catch((error) => {
              // console.warn(error);
            });
        } else {
          setVideoPlaying(true);
        }
      }
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <div ref={inViewRef}>
      <VideoAutoWrapper
        ref={videoRef}
        src={src}
        muted={muted}
        loop={loop}
        playsInline
        autoPlay={!poster}
        noSpacing={noSpacing}
        poster={poster}
        onClick={handleVideoPlayerClick}
      />
    </div>
  );
}

export default VideoAuto;
