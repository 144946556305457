import { cloneElement } from "react";

function useChildrenWithProps(children, props) {
  if (!children) return null;
  // console.log('useChildrenWithProps', children);

  try {
    // if more than 1 child
    return children
      .filter((child) => !!child)
      .map((child, i) => cloneElement(child, { key: child.key || i, ...props }));
  } catch (e) {
    // if only 1 child
    return cloneElement(children, { ...props });
  }
}

export default useChildrenWithProps;
