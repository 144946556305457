import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useRouterEvent from "~/hooks/useRouterEvent";
import { setTopBarColor } from "~/store/ui";
import theme, { px } from "~/styles/theme";

function useTopBarColors(
  backgroundColor = theme.colors.light,
  color = theme.colors.dark
) {
  const elem = useRef();

  const dispatch = useDispatch();
  const [lastCheck, setLastCheck] = useState(0);

  const ui = useSelector((state) => state.ui);

  // checks if this elem is under the topbar
  function checkIfUnderTopBar() {
    // console.log('UI', ui);

    // Throttling:
    const now = Date.now();
    if (now - lastCheck <= 500) return;

    const isSmallScreen = window.innerWidth <= theme.breakpoints.small;
    const topBarHeight = isSmallScreen
      ? theme.sizes.topBarHeightSmall - 1
      : theme.sizes.topBarHeight - 1;
    // MAKE -1 SO THAT ELEMENT THAT IS EXACTLY topbarheight will still trigger :)

    // TODO what about mobile size?
    const rect = elem.current?.getBoundingClientRect();
    // do some `*~ math-gic ~*´
    // if (
    //   rect.top < px(theme.sizes.topBarHeight, false) &&
    //   Math.abs(rect.top) < rect.height - px(theme.sizes.topBarHeight, false)
    // ) {
    if (
      rect &&
      rect.top < px(topBarHeight, false) &&
      Math.abs(rect.top) < rect.height - px(topBarHeight, false)
    ) {
      // console.log(backgroundColor, ui.topBarBackgroundColor);
      // console.log(color, ui.topBarColor);

      // only switch if we need to...
      if (backgroundColor !== ui.topBarBackgroundColor || color !== ui.topBarColor) {
        // console.log('SW');
        // console.log(`${elem.current.className} is under TopBar!`);
        dispatch(setTopBarColor(backgroundColor, color));
      }

      // console.log(rect.top, rect.height);
      // console.log(`${elem.current.className} is under TopBar!`);
    }

    setLastCheck(now);
  }

  // when ui changes, we want to check
  // and when we scroll, we want to check
  useEffect(() => {
    // UI is hardcoded here?
    checkIfUnderTopBar();
    document.addEventListener("scroll", checkIfUnderTopBar, { passive: true }); // <-- important for performance
    return () => {
      document.removeEventListener("scroll", checkIfUnderTopBar);
    };
  }, [ui]);

  // useRouterEvent('routeChangeStart', () =>
  //   dispatch(setTopBarColor('transparent', theme.colors.dark))
  // );
  useRouterEvent("routeChangeComplete", () => checkIfUnderTopBar());

  return elem;
}

export default useTopBarColors;
