import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { HttpLink } from "apollo-link-http";
import fetch from "cross-fetch";
import getConfig from "next/config";
import { createContext } from "react";
import introspectionQueryResultData from "~/gql/fragmentTypes.json";
import { getTokenInfo } from "~/lib/ctpTokenInfo";

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

// create a context the whole app can use to get the client...
export const CtpCtx = createContext(undefined);

/**
 * Creates and provides the apolloContext
 * to a next.js PageTree. Use it by wrapping
 * your PageComponent via HOC pattern.
 * @param {Function|Class} PageComponent
 * @param {Object} [config]
 * @param {Boolean} [config.ssr=true]
 */
export function withCtpApollo(PageComponent, { ssr = true } = {}) {
  /*
  const WithCtpApollo = ({ ctpClient, apolloState, ...pageProps }) => {
    const client =
      ctpClient ||
      initApolloClient(apolloState, { ctpTokenInfo: pageProps.ctpTokenInfo });

    const value = {
      client,
      tokenInfo: pageProps.ctpTokenInfo
    };

    return (
      <CtpCtx.Provider value={value}>
        <PageComponent ctpClient={client} {...pageProps} />
      </CtpCtx.Provider>
    );
  };

  */

  function WithCtpApollo(props) {
    const { apolloState, ctpTokenInfo, ...pageProps } = props;

    // make a new client for client side
    const ctpClient = initApolloClient(apolloState, { ctpTokenInfo });

    const value = {
      client: ctpClient,
      tokenInfo: ctpTokenInfo,
    };

    return (
      <CtpCtx.Provider value={value}>
        <PageComponent {...pageProps} />
      </CtpCtx.Provider>
    );
  }

  if (process.env.NODE_ENV !== "production") {
    // Find correct display name
    const displayName = PageComponent.displayName || PageComponent.name || "Component";

    // Warn if old way of installing apollo is used
    if (displayName === "App") {
      console.warn("This WithCtpApollo HOC only works with PageComponents.");
    }

    // Set correct display name for devtools
    WithCtpApollo.displayName = `WithCtpApollo(${displayName})`;
  }

  if (ssr || PageComponent.getInitialProps) {
    WithCtpApollo.getInitialProps = async (appContext) => {
      const { ctx } = appContext;
      const { ctpTokenInfo } = ctx;

      const ctpClient = initApolloClient({}, { ctpTokenInfo });
      // ctx.ctpClient = ctpClient;
      ctx.ctpCtx = {
        client: ctpClient,
        tokenInfo: ctpTokenInfo,
      };
      // Extract query data from the Apollo store
      const apolloState = ctpClient.cache.extract();

      // get rest of app
      const pageProps = PageComponent.getInitialProps
        ? await PageComponent.getInitialProps(appContext)
        : {};

      // console.log('pageProps', pageProps.ctpTokenInfo);

      // add ctpTokenInfo and apolloState to props for the render
      return {
        ...pageProps,
        apolloState,
        ctpTokenInfo,
      };
    };
  }

  return WithCtpApollo;
}

let apolloClient = null;

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 */
export function initApolloClient(...args) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === "undefined") {
    return createApolloClient(...args);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = createApolloClient(...args);
  }

  return apolloClient;
}

/**
 * Creates and configures the ApolloClient
 * @param  {Object} [initialState={}]
 * @param  {Object} config
 */
function createApolloClient(initialState = {}, { ctpTokenInfo }) {
  const fetchOptions = {
    // method: 'GET'
    // mode: 'no-cors'
  };

  // If you are using a https_proxy, add fetchOptions with 'https-proxy-agent' agent instance
  // 'https-proxy-agent' is required here because it's a sever-side only module
  if (typeof window === "undefined") {
    if (process.env.https_proxy) {
      fetchOptions.agent = new (require("https-proxy-agent"))(process.env.https_proxy);
    }
  }

  const httpLink = new HttpLink({
    uri: publicRuntimeConfig.COMMERCETOOLS_GRAPHQL_ENDPOINT,
    credentials: "same-origin",
    fetch,
    fetchOptions,
  });

  const authLink = setContext(async (request, { headers }) => {
    let token = ctpTokenInfo.access_token;
    if (typeof document !== "undefined") {
      // console.log('BROWSER');
      const tokenInfo = await getTokenInfo();
      token = tokenInfo.access_token;
    }
    // console.log('SETTING TOKEN: ', token);
    // console.log('SETTING TOKEN - request: ', request);
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  return new ApolloClient({
    ssrMode: typeof window === "undefined", // Disables forceFetch on the server (so queries are only run once)
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      fragmentMatcher,
      dataIdFromObject: (object) =>
        // https://www.npmjs.com/package/apollo-cache-inmemory#normalization
        object.id,
    }).restore(initialState) /* FUCKS UP??? */,
  });
}
