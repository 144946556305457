import { Navigation } from "~/external/prismic.types";

export type NavigationMap = Record<
  "mainDesktop" | "mainMobile" | "footer" | "legal",
  Navigation
>;

export type NavigationState = {
  isLoaded: boolean;
  navigation: NavigationMap | undefined;
};

const initialState: NavigationState = {
  isLoaded: false,
  navigation: undefined,
};

type AddNavigationAction = {
  type: "SET_NAVIGATION";
  payload: { navigation: NavigationMap };
};

type NavigationAction = AddNavigationAction;

export function setNavigation(navigation: NavigationMap): AddNavigationAction {
  return {
    type: "SET_NAVIGATION",
    payload: { navigation },
  };
}

export default function reducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: Readonly<NavigationState> | undefined = initialState,
  action: NavigationAction
) {
  switch (action.type) {
    case "SET_NAVIGATION":
      return {
        ...state,
        isLoaded: true,
        navigation: action.payload.navigation,
      };
    default:
      return state;
  }
}
