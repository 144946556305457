import styled from "styled-components";

interface PriceLineProps {
  price: {
    discounted?: {
      value: Money;
    };
    value: Money;
  };
  extras: [{ value: Money }];
  highlightColor?: string;
}

const OldPrice = styled.span`
  text-decoration: line-through;
  opacity: 0.6;
`;

const NewPrice = styled.span`
  color: ${(p) => p.color || p.theme.colors.discount};
`;

function PriceLine(props: PriceLineProps) {
  const { price, extras = [], highlightColor } = props;

  if (!price) return null;

  // show discounted price
  if (price.discounted) {
    const totalPrice =
      extras.length > 0
        ? extras
            .map((extra) => extra.value.centAmount)
            .reduce((price, extra) => {
              price += extra;
              return price;
            }, price.value.centAmount)
        : price.value.centAmount;
    const totalPriceDiscounted =
      extras.length > 0
        ? extras
            .map((extra) =>
              extra.discounted
                ? extra.discounted.value.centAmount
                : extra.value.centAmount
            )
            .reduce((price, extra) => {
              price += extra;
              return price;
            }, price.discounted.value.centAmount)
        : price.discounted.value.centAmount;
    return (
      <>
        <span>{price.discounted.value.currencyCode}</span>{" "}
        <span>
          <OldPrice>{price.value.centAmount / 100}</OldPrice>{" "}
          <NewPrice color={highlightColor}>{totalPriceDiscounted / 100}</NewPrice>
        </span>
      </>
    );
  }

  // no discount, show normal price! (if there)

  // TODO: Discounts + extras?

  const totalPrice =
    extras.length > 0
      ? extras
          .map((extra) =>
            extra.discounted
              ? extra.discounted.value.centAmount
              : extra.value.centAmount
          )
          .reduce((price, extra) => {
            price += extra;
            return price;
          }, price.value.centAmount)
      : price.value.centAmount;

  return (
    <>
      <span>{price.value.currencyCode}</span> <span>{totalPrice / 100}</span>
    </>
  );
}

export default PriceLine;
