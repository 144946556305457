import NodeCache from "node-cache";

const setup = (): NodeCache => {
  if ((global as any).cache) {
    return (global as any).cache;
  }

  console.info("CREATING CACHE");

  (global as any).cache = new NodeCache({
    stdTTL: 4 * 60 * 60, // 0
    useClones: false,
  });

  return (global as any).cache;
};

export default setup();
