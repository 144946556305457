import styled from "styled-components";

const TextArea = styled.textarea<any>`
  min-height: 30vh;
  resize: none;
  position: relative;
  display: ${(p) => (p.inline ? "inline-block" : "block")};
  margin: 0.6em ${(p) => (p.borderBottomOnly ? "0.3em" : "0")} 0.3em
    ${(p) => (p.borderBottomOnly ? "0.3em" : "0")};
  ${(p) => !p.borderBottomOnly && "padding: 1.8em 0.4em;"}
  background-color: ${(p) => p.theme.colors.light};
  color: ${(p) => p.theme.colors.dark};
  /*border-radius: 2px;*/
  border-radius: 0;
  border: 0;
  /*border: 1px solid ${(p) => p.theme.colors.dark};*/
  width: 100%;
  height: 50px;
  &:invalid {
    ${(p) =>
      p.hadFocus
        ? `${p.borderBottomOnly ? "border-bottom" : "border"}: 2px solid #f44336;`
        : `${p.borderBottomOnly ? "border-bottom" : "border"}: 2px solid #ccc;`}
  }
  &:valid {
    ${(p) =>
      p.hadFocus
        ? `${p.borderBottomOnly ? "border-bottom" : "border"}: 2px solid #ccc;`
        : `${p.borderBottomOnly ? "border-bottom" : "border"}: 2px solid #ccc;`}
  }
  &:focus {
    ${(p) =>
      p.readOnly
        ? `${
            p.borderBottomOnly ? "border-bottom" : "border"
          }: 2px solid #eee; outline: none;`
        : `${
            p.borderBottomOnly ? "border-bottom" : "border"
          }: 2px solid #2196f3; outline: none;`}
  }
  &:disabled {
    border: 1px solid #eee;
    outline: none;
    color: #ddd;
  }
  &.is-invalid {
    ${(p) => (p.borderBottomOnly ? "border-bottom" : "border")}: 2px solid #f44336;
  }
  &:after {
    content: "";
  }
`;

export default TextArea;
