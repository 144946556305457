import classnames from "classnames";
import { motion } from "framer-motion";
import styled from "styled-components";
import Link from "~/components/Link";
import { hSpacing } from "~/styles/mixins";

const DropdownMenuLinkWrapper = styled(motion.li)`
  ${hSpacing};
  color: ${(p) => p.theme.colors.dark};
  font-size: 14px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  position: relative;
  cursor: pointer;
  a {
    color: ${(p) => p.color || p.theme.colors.dark};
    text-decoration: none;
    display: inline-block;
    width: 100%;
  }
  &:before {
    content: "·";
    position: absolute;
    left: 0;
    top: 0.5em;
    /*display: none;*/
    opacity: 0;
    transform: translateX(-0.5em);
    transition: opacity 0.2s, transform 0.2s;
  }
  &:hover {
    font-weight: bold;
    &:before {
      /*display: block;*/
      opacity: 1;
      transform: translateX(0);
    }
  }
  &.pinned {
    font-weight: bold;
  }
`;

function DropdownMenuLink(props) {
  const { label, href, as, onHoverStart, onHoverEnd, pin = false, onClick } = props;

  const hrefAs = as || href;

  // let hrefAs = null;
  // if (href !== as && as) hrefAs = as;

  // console.log('href', href);
  // console.log('hrefAs', hrefAs);

  return (
    <DropdownMenuLinkWrapper
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      className={classnames({ pinned: pin })}
    >
      <Link href={href} as={hrefAs}>
        <a onClick={onClick ?? (() => {})}>{label}</a>
      </Link>
    </DropdownMenuLinkWrapper>
  );
}

export default DropdownMenuLink;
