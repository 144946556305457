import Link from "next/link";
import useChildrenWithProps from "~/hooks/useChildrenWithProps";

function LinkWrapper(props) {
  let { as: hrefAs, href, children, onClick } = props;

  // as = the human readable link eg. /headphones/tma-2
  // href = the route to the pages page eg. /headphones/[...slug]

  // if hrefAs is undefined, it should just be the same as href
  // (we assume that it is a "normal" link, eg. /headphones)
  if (hrefAs === undefined) {
    hrefAs = href;
  }

  // if we link to any headphones or accessories route, we want to fix the href!
  // (but only if not specified already)
  if (href) {
    // make sure we dont change the tma-2 builder url...
    if (href === hrefAs) {
      // special case for the tma-2-builder links
      if (/^\/headphones\/tma-2-build-your-own\/[a-zA-Z0-9\-]+$/.test(href)) {
        href = "/headphones/tma-2-build-your-own/[serial]";
      } else {
        const matches = href.match(
          /^(\/headphones|\/studio-monitors|\/accessories)(\/[a-zA-Z0-9\-]+)(\/[a-zA-Z0-9\-]+)?$/
        );
        // rewrite it, if it matches
        if (matches) href = `${matches[1]}/[...slug]`;
      }
    }
  }

  // console.log(href, hrefAs);

  // if the children has a click event, just render them as clickable
  const clickChildren = useChildrenWithProps(props.children, { onClick });
  // if children is just an a with a href... (Extenral link)
  const hrefChildren = useChildrenWithProps(props.children, { href });

  if (onClick) {
    return clickChildren;
  }

  // if link is empty, just display children...
  if (!href) {
    return <>{children}</>;
  }

  // if we have an external link
  if (href.includes("http")) {
    return hrefChildren;
  }

  // console.log(href, hrefAs);

  // set as = null to force it not to be there
  if (hrefAs === null) {
    return <Link href={href}>{children}</Link>;
  }

  return (
    <Link as={hrefAs} href={href} passHref>
      {children}
    </Link>
  );
  // return (
  //   <RouterLink route={as} as={as} href={href}>
  //     {children}
  //   </RouterLink>
  // );
}

export default LinkWrapper;

/**
 *
 * IMPORTANT: not used right now...
 *
 *
 */

/**
 * Custom link wrapper for "next/Link"
 *
 * !! language will be appended to all absolute links
 * aka. links that starts with a "/"
 *
 * !! IMPORTANT
 * when using dynamic urls, we NEED the "href" and "as" prop.
 * eg. as = "/en/categories/speaker-units"
 * eg. href = "/[language]/categories/[categorySlug]"
 * "as" is the "pretty" human-readable link
 * "href" is the link that imitates the folder structure
 *
 */

/*
import { useRouter } from 'next/router';
import Link from 'next/link';

type LinkWrapperProps = {
  href: string;
  as?: string;
  children: any;
};

function LinkWrapper(props: LinkWrapperProps) {
  const router = useRouter();

  // it is ok that the links just use the router.query.language
  // as it just uses the thing that is in the url...
  const { language } = router.query;

  let { href, as: hrefAs } = props;
  // if as is not defined, fall back to use href...
  if (!hrefAs) hrefAs = href;

  // absolute link - add language!
  if (href.charAt(0) === '/') {
    hrefAs = `/${language}${hrefAs}`;
    href = `/[language]${href}`;
  }

  return (
    <Link href={href} as={hrefAs}>
      <a>{props.children}</a>
    </Link>
  );
}

export default LinkWrapper;
*/
