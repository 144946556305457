// https://docs.logrocket.com/docs/using-logrocket-with-server-side-rendering
// you can import these packages anywhere

// const LogRocket = require('logrocket');

// only initialize when in the browser
if (process.browser && typeof LogRocket !== "undefined") {
  LogRocket.init("hv6xza/aiaiai");
  // plugins should also only be initialized when in the browser
  const setupLogRocketReact = require("logrocket-react");
  setupLogRocketReact(LogRocket);
  window._DEBUG_LogRocket = LogRocket;
  console.log("🚀");
}

// Custom client functoons
if (typeof window !== "undefined") {
  // loadPolyfills();

  // TODO USE LOAD POLYFILLS INSTEAD, BUT TEST
  // Polyfills
  require("intersection-observer");

  // console.log('REMOVE CONSOLE LOG');

  // overwrite log functions
  if (typeof LogRocket !== "undefined") {
    // save original functions
    window._console = {
      log: window.console.log,
      error: window.console.error,
      warn: window.console.warn,
    };

    // TODO MAKE A NICE SCRIPT
    window.console.error = function (message) {
      if (typeof LogRocket !== "undefined") {
        if (window.location.hostname === "aiaiai.audio") {
          LogRocket.captureException({ message });
        }
      }
      window._console.error.apply(message, arguments);
    };

    window.console.warn = function (message) {
      if (typeof LogRocket !== "undefined") {
        // we will also catch weird cookie bugs?
        // LogRocket.captureMessage(message, arguments);
      }
      window._console.warn.apply(message, arguments);
    };

    window.console.log = function (message) {
      // only allow logs in test, staging and localhost...
      if (window.location.hostname !== "aiaiai.audio") {
        window._console.log.apply(message, arguments);
      }
    };
  } else {
    // simply supress console log...
    if (window.location.hostname === "aiaiai.audio") {
      window.console.log = () => {};
    }
  }

  // for navigating to previous page...
  // only works without query params for now
  window.prevUrl = undefined;
}
