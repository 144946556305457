export const resolveLinkByProductTypeKey = {
  // tma-2 parts
  "speaker-units": (v) => ({
    href: "/headphones/[...slug]",
    as: `/headphones/tma-2-speaker-units/${v.productKey || v.key}-speaker-units`,
  }),
  "cable": (v) => ({
    href: "/headphones/[...slug]",
    as: `/headphones/tma-2-cables/${v.productKey || v.key}-cable`,
  }),
  "earpads": (v) => ({
    href: "/headphones/[...slug]",
    as: `/headphones/tma-2-earpads/${v.productKey || v.key}-earpads`,
  }),
  "headband": (v) => ({
    href: "/headphones/[...slug]",
    as: `/headphones/tma-2-headbands/${v.productKey || v.key}-headband`,
  }),
  // accessories & spare parts
  "accessory": (v) => ({
    href: "/accessories/[...slug]",
    as: `/accessories/${v.productKey || v.key}`,
  }),
  "t-shirt": (v) => ({
    href: "/accessories/[...slug]",
    as: `/accessories/${v.productKey || v.key}`,
  }),
  "spare-part": (v) => ({
    href: "/spare-parts/[...slug]",
    as: `/spare-parts/${v.productKey || v.key}`,
  }),
  // headhones
  "earphone": (v) => ({
    href: "/headphones/[...slug]",
    as:
      v.key && v.key !== v.productKey
        ? `/headphones/${v.productKey || v.key}/${v.key}`
        : `/headphones/${v.productKey || v.key}`,
  }),
  "headphone": (v) => ({
    href: "/headphones/[...slug]",
    as:
      v.key && v.key !== v.productKey
        ? `/headphones/${v.productKey || v.key}/${v.key}`
        : `/headphones/${v.productKey || v.key}`,
  }),
  "parts-bundle": (v) => ({
    href: `/${v.productKey || v.key}`,
  }),
  "tma-2-bundle": (v) => ({
    href: "/headphones/[...slug]",
    as: `/headphones/${v.productKey || v.key}`,
  }),
  "tma-2-configuration": (v) => ({
    href: "/headphones/tma-2-build-your-own/[serial]",
    as: `/headphones/tma-2-build-your-own/${v.serial}`,
  }),
  // studio monitors
  "studio-monitor": (v) => ({
    href: "/studio-monitors/[...slug]",
    as:
      v.key && v.key !== v.productKey
        ? `/studio-monitors/${v.productKey || v.key}/${v.key}`
        : `/studio-monitors/${v.productKey || v.key}`,
  }),
  // misc
  "packaging": () => {},
  "speaker": () => {},
};

// variant needs:
// - productTypeKey
// - productKey
// - key
// (- serial)
function resolveVariantLink(variant, target = "_self") {
  try {
    return {
      ...resolveLinkByProductTypeKey[
        variant.productTypeKey || variant.productType?.key
      ](variant),
      target,
    };
  } catch (e) {
    console.log("Could not resolveVariantLink", variant);
    return { href: undefined, as: undefined, target };
  }
}

export default resolveVariantLink;
