import classnames from "classnames";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import React, { useState } from "react";
import styled from "styled-components";
import Link from "~/components/Link";
import useRouterEvent from "~/hooks/useRouterEvent";
import { hSpacing } from "~/styles/mixins";
import { biggerThan, rem, smallerThan } from "~/styles/theme";

export const LabelWrapper = styled.span`
  display: inline-block;
  position: relative;
  color: ${(p) => p.color || p.theme.colors.dark};
  transition: color 0.2s;
  transition-delay: 0.2s;
  /* the line */
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: -0.25rem;
    /* bottom: 0.4rem; */
    /* bottom: 0; */
    left: 0;
    height: 1px;
    background-color: ${(p) => p.color || p.theme.colors.dark};
    /*opacity: 0.15; works bad for white..*/
    opacity: 0.3;
    display: none;
    transition: background-color 0.2s;
    transition-delay: 0.2s; /* should match close animation */
  }
`;

export const BadgeWrapper = styled.span`
  transform: translateY(-0.1em);
  display: block;
  position: relative;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  display: block;
  font-size: 0.7rem;
  font-weight: bold;
  color: ${(p) => p.color || p.theme.colors.light};
  background-color: ${(p) => p.backgroundColor || p.theme.colors.dark};
  margin-left: ${(p) => p.theme.sizes.hSpacing}rem;
  transition: color 0.2s, background-color 0.2s;
  transition-delay: 0.2s; /* should match close animation */
  /* the number */
  &:after {
    content: "${(p) => p.text || "0"}";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1;
    padding-top: 0.05rem;
  }
  /* ${(p) => smallerThan(p.theme.breakpoints.ipadLandscape)} {
    display: none;
  } */
`;
export const AWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: ${rem("10px")} 0;
  /* color: ${(p) => p.color || p.theme.colors.dark}; */

  text-decoration: none;
  position: relative;
  z-index: ${(p) => p.theme.z.topBar + 2};

  cursor: ${(p) => (p.noPointer ? "default" : "pointer")};

  &.no-line {
    &:after {
      content: none;
    }
  }
`;

export const MenuLinkWrapper = styled(motion.li)`
  /* Ensure className takes priority */
  &.hidden {
    display: none !important;
  }

  position: relative;
  height: 100%;

  justify-content: center;
  display: ${(p) => (p.inline ? "inline-flex" : "flex")};
  ${(p) => p.hidden && `display:none;`}
  align-items: center;
  /* the  line */

  font-size: ${rem("14px")};
  /* padding: 0 ${(p) => p.theme.sizes.hSpacing}rem; */
  ${hSpacing};

  ${(p) => smallerThan(p.theme.breakpoints.small)} {
  }

  &:hover,
  &.active {
    ${AWrapper} {
      ${LabelWrapper} {
        &:after {
          display: block; /* show the line... */
        }
      }
    }
  }

  /* hiddenOnSmall (max)*/
  ${(p) => smallerThan(p.theme.breakpoints.ipadLandscape)} {
    display: ${(p) => (p.hiddenOnSmall ? "none" : p.inline ? "inline-flex" : "flex")};
  }

  /* hiddenOnBig (min) */
  ${(p) => biggerThan(p.theme.breakpoints.ipadLandscape)} {
    display: ${(p) => (p.hiddenOnBig ? "none" : p.inline ? "inline-flex" : "flex")};
  }
`;

export const IconWrapper = styled.div``;

function MenuLink(props) {
  const {
    label,
    href,
    as,
    color,
    backgroundColor,
    badge = null,
    icon = null,
    onClick,
    hiddenOnSmall = false,
    hiddenOnBig = false,
    children,
    inline,
    active, // force active
    pin = false,
    nolink = false,
  } = props;

  const { asPath } = useRouter();
  const hrefAs = as || href;

  // controls the animations of the dropdown
  const [routeChanging, setRouteChanging] = useState(false);

  // close dropdown on route change
  useRouterEvent("routeChangeStart", () => setRouteChanging(true));
  useRouterEvent("routeChangeComplete", () => setRouteChanging(false));

  // if we have a dropdown, add the animation props we need to make it work...

  const animationProps = children
    ? {
        whileHover: "open",
        whileTap: routeChanging ? "closed" : "open",
        initial: "closed",
        animate: "closed",
      }
    : {};

  // overwrite link with a function...
  function handleOnClick(e) {
    if (!onClick) return;
    e.preventDefault();
    onClick();
  }

  const isActive = asPath.includes(hrefAs) || active;

  function LinkWrapper(props) {
    if (nolink) {
      return <AWrapper noPointer>{props.children}</AWrapper>;
    }
    return (
      <Link href={href || "/"} as={hrefAs || href || "/"} onClick={onClick}>
        <AWrapper>{props.children}</AWrapper>
      </Link>
    );
  }

  return (
    <MenuLinkWrapper
      className={classnames(isActive ? "active" : "", props.className)}
      color={color}
      {...animationProps}
      onClick={handleOnClick}
      hiddenOnSmall={hiddenOnSmall}
      hiddenOnBig={hiddenOnBig}
      inline={inline}
    >
      <LinkWrapper>
        <LabelWrapper color={color} backgroundColor={backgroundColor}>
          {label}
        </LabelWrapper>

        {icon !== null && (
          <IconWrapper>
            {icon && React.cloneElement(icon, { size: 20, color: color })}
          </IconWrapper>
        )}

        {badge !== null && (
          <BadgeWrapper
            backgroundColor={color}
            color={backgroundColor}
            text={badge.toString()}
          />
        )}
      </LinkWrapper>
      {children}
    </MenuLinkWrapper>
  );
}

export default MenuLink;
