import { lighten } from "@wessberg/color";
import classnames from "classnames";
import { forwardRef } from "react";
import styled from "styled-components";
import { spinner } from "~/styles/mixins";
import { hexToRgba, rem } from "~/styles/theme";

const Button = styled.button`
  font-family: 'AIAIAI Extended';
  position: relative;
  display: inline-block;
  /* font-size: ${(p) => p.fontSize || rem("13px")}; */
  font-size: ${(p) => p.fontSize || rem("12px")};
  font-weight: bold;
  border: 0;
  appearance: none;
  outline: none;
  border-radius: 0;
  background-color: ${(p) =>
    p.filled === "true" ? p.backgroundColor || p.theme.colors.light : "transparent"};
  color: ${(p) =>
    p.filled === "true"
      ? p.color || p.theme.colors.light
      : p.color || p.theme.colors.dark};
  /* padding: ${(p) => (p.low === "true" ? 1 : 1.6)}em 0.6em; */
  text-align: center;
  width: 100%;
  /* height: ${(p) => (p.low === "true" ? 2.8 : 3.8)}rem; */
  height: ${(p) => (p.low === "true" ? 2.8 : 3.5)}rem;
  cursor: pointer;
  /*
  border: 2px solid
    ${(p) =>
      p.filled === "true"
        ? "transparent"
        : hexToRgba(p.color || p.theme.colors.dark, 0.4)};
        */

  border: 2px solid ${(p) =>
    p.filled === "true" ? "transparent" : lighten(p.color || p.theme.colors.dark, 50)};
  /* &:hover {
    border: 2px solid
      ${(p) => (p.filled ? "transparent" : p.color || p.theme.colors.dark)};
  } */
  pointer-events: ${(p) => (p.disabled === "yes" ? "none" : "all")};
  opacity: ${(p) => (p.disabled === "yes" ? "0.6" : "1")};

  > span{
    display: block;
    transform: translateY(0.1em);
  }

  &:hover{

    background-color: ${(p) =>
      p.filled === "true"
        ? lighten(p.backgroundColor || p.theme.colors.light, -10)
        : lighten(p.color || p.theme.colors.dark, 50)}};
  }

  &.working {
    > span {
      display: none;
    }
    ${spinner};
  }

`;

function ButtonOutline(props, ref) {
  const {
    children,
    fontSize,
    id,
    disabled,
    working,
    onClick = () => {},
    color,
    backgroundColor,
    low = false,
    filled = false,
  } = props;

  return (
    <Button
      ref={ref}
      id={id}
      fontSize={fontSize}
      disabled={disabled}
      onClick={onClick}
      color={color}
      backgroundColor={backgroundColor}
      low={low.toString()}
      filled={filled.toString()}
      className={classnames({ working })}
    >
      {!working && <span>{children}</span>}
    </Button>
  );
}

export default forwardRef(ButtonOutline);
