const actionTypes = {
  SET_VIEWONLY_TRUE: "SET_VIEWONLY_TRUE",
  SET_VIEWONLY_FALSE: "SET_VIEWONLY_FALSE",
};

const reducer = (state = true, action) => {
  switch (action.type) {
    case actionTypes.SET_VIEWONLY_TRUE:
      return true;
    case actionTypes.SET_VIEWONLY_FALSE:
      return false;

    default:
      return state;
  }
};

export function setViewOnlyTrue() {
  return {
    type: actionTypes.SET_VIEWONLY_TRUE,
  };
}

export function setViewOnlyFalse() {
  return {
    type: actionTypes.SET_VIEWONLY_FALSE,
  };
}

export default reducer;
